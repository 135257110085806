import React from "react";
import "./UnlockAddonsModal.scss";

import { BillingPeriod, Checkout } from "@stigg/react-sdk";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, {
  ModalScroller,
  ModalTitle,
} from "src/app/components/Modal/Modal";
import { showErrorToast } from "src/utils/methods";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { identify } from "src/redux/main/mainActions";
import { getSubscriptionPlanData } from "../../subscriptionFunctions";
import SubscriptionStylingProvider from "../../components/SubscriptionStylingProvider/SubscriptionStylingProvider";

interface Props {
  onClose: () => void;
  openSuccessModal: () => void;
}

function UnlockAddonsModal(props: Props) {
  const { onClose, openSuccessModal } = props;

  const { stiggContextValue, subscriptionPlan } = useSelector(
    (state: RootState) => state.subscriptionReducer,
  );
  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const seatsQuantity =
    subscriptionPlan?.features["feature-seats"]?.unitQuantity;

  const showCheckout =
    subscriptionPlan?.id && subscriptionPlan.billingPeriod && seatsQuantity;

  const { stigg } = stiggContextValue || {};

  const dispatch = useDispatch();

  return (
    <Modal
      className="unlock-addons-modal"
      onClose={onClose}
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      displayCancelButton
      closeButtonVariant="white-with-black-border"
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_unlock_addons"
          defaultMessage="Unlock addons"
        />
      </ModalTitle>

      {showCheckout && (
        <ModalScroller maxHeight={647}>
          <SubscriptionStylingProvider
            sectionStylingModes={{ checkout: "indahash" }}
          >
            <Checkout
              planId={subscriptionPlan?.id}
              // @ts-ignore
              onCheckoutCompleted={({ success, error }) => {
                if (success && stigg) {
                  onClose();
                  dispatch(identify(activeWorkspaceUuid));
                } else if (error) {
                  showErrorToast();
                }
              }}
              skipCheckoutSteps={["PLAN"]}
              preferredBillingPeriod={subscriptionPlan.billingPeriod}
              billableFeatures={[
                {
                  featureId: "feature-seats",
                  quantity: seatsQuantity,
                },
              ]}
            />
          </SubscriptionStylingProvider>
        </ModalScroller>
      )}
    </Modal>
  );
}

export default UnlockAddonsModal;
