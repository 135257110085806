import React, {
  Dispatch,
  SetStateAction,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as TickIconBlue } from "src/images/tick-blue.svg";
import { ReactComponent as CrossIcon } from "src/images/cross-grey.svg";
import { translateMessage } from "src/app/methods/translateMessage";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { ReactComponent as EditIcon } from "src/images/edit-gray.svg";
import { RootState } from "src/redux/reducers";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Template } from "../types";
import { handleUpdateProjectTemplate } from "../utils";

interface EditedTemplateTitleProps {
  editedTemplate: Template;
  setEditedTemplate: Dispatch<SetStateAction<Template | null>>;
  syncLocalTemplateWithServer: (uuid: string) => Promise<void>;
}

export default function EditedTemplateTitle({
  editedTemplate,
  setEditedTemplate,
  syncLocalTemplateWithServer,
}: EditedTemplateTitleProps) {
  const [isEditingName, setIsEditingName] = useState(false);
  const [templateName, setTemplateName] = useState(editedTemplate.name);

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleUpdateTemplateName = () => {
    const templateNameTrimmed = templateName.trim();
    setIsEditingName(false);

    if (
      templateNameTrimmed.length > 0 &&
      templateNameTrimmed !== editedTemplate.name
    ) {
      setEditedTemplate((prevTemplate) => {
        if (!prevTemplate) return null;

        handleUpdateProjectTemplate({
          data: {
            ...editedTemplate,
            wsWorkspaceUuid: activeWorkspaceUuid,
            name: templateNameTrimmed,
          },
          catchCallback: () => syncLocalTemplateWithServer(editedTemplate.uuid),
          dispatch,
        });

        return { ...prevTemplate, name: templateNameTrimmed };
      });
    } else {
      setTemplateName(editedTemplate.name);
    }
  };

  const handleEnableTemplate = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setEditedTemplate({ ...editedTemplate, enabled: checked });
    handleUpdateProjectTemplate({
      data: {
        ...editedTemplate,
        wsWorkspaceUuid: activeWorkspaceUuid,
        enabled: checked,
      },
      catchCallback: () => syncLocalTemplateWithServer(editedTemplate.uuid),
      dispatch,
    });
  };

  useEffect(() => {
    setTemplateName(editedTemplate.name);
  }, [editedTemplate.name]);

  return (
    <div className="settings-modal__view-header">
      <div className="settings-modal__view-heading">
        {isEditingName ? (
          <div className="template-manager__template-name">
            <input
              type="text"
              onBlur={() => handleUpdateTemplateName()}
              placeholder={translateMessage({
                intl,
                id: "ws_enter_template_name",
                defaultMessage: "Enter template name",
              })}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
            <div className="template-manager__template-name-controls">
              <button
                className="button-style-reset"
                type="button"
                aria-label="Save"
                onClick={() => handleUpdateTemplateName()}
              >
                <TickIconBlue />
              </button>
              <button
                className="button-style-reset"
                type="button"
                aria-label="Cancel"
                onClick={() => {
                  setIsEditingName(false);
                  setTemplateName(editedTemplate.name);
                }}
              >
                <CrossIcon />
              </button>
            </div>
          </div>
        ) : (
          <>
            <span>{templateName}</span>
            <button
              onClick={() => setIsEditingName(true)}
              className="button-style-reset"
              type="button"
              aria-label="Edit template name"
            >
              <EditIcon />
            </button>
          </>
        )}
      </div>
      <CustomSwitch
        className="template-manager__switch"
        checked={editedTemplate.enabled}
        label={
          editedTemplate.enabled ? (
            <IDHFormattedMessage id="ws_active" defaultMessage="Active" />
          ) : (
            <IDHFormattedMessage id="ws_disabled" defaultMessage="Disabled" />
          )
        }
        onChange={handleEnableTemplate}
      />
    </div>
  );
}
