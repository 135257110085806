import React from "react";
import "./ChangePlanModal.scss";

import { Checkout, SubscribeIntentionType } from "@stigg/react-sdk";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, {
  ModalScroller,
  ModalTitle,
} from "src/app/components/Modal/Modal";
import { showErrorToast } from "src/utils/methods";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { setManageSubscriptionModalVisibility } from "src/redux";
import { identify } from "src/redux/main/mainActions";
import { SubscriptionPlanChangeData } from "../ManageSubscriptionModal/ManageSubscriptionModal";
import SubscriptionStylingProvider from "../../components/SubscriptionStylingProvider/SubscriptionStylingProvider";

interface Props {
  onClose: () => void;
  planChangeData: SubscriptionPlanChangeData;
  openSuccessModal: () => void;
}

function ChangePlanModal(props: Props) {
  const { onClose, planChangeData, openSuccessModal } = props;

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );
  const { stiggContextValue } = useSelector(
    (state: RootState) => state.subscriptionReducer,
  );

  const { stigg } = stiggContextValue || {};

  const dispatch = useDispatch();

  const { plan, intentionType } = planChangeData;

  const renderTitle = () => {
    switch (intentionType) {
      case SubscribeIntentionType.UPGRADE_PLAN:
        return (
          <IDHFormattedMessage
            id="ws_upgrading_the_plan"
            defaultMessage="Upgrading the plan"
          />
        );

      case SubscribeIntentionType.DOWNGRADE_PLAN:
        return (
          <IDHFormattedMessage
            id="ws_downgrading_the_plan"
            defaultMessage="Downgrading the plan"
          />
        );

      default:
        return (
          <IDHFormattedMessage
            id="ws_changing_the_plan"
            defaultMessage="Changing the plan"
          />
        );
    }
  };

  return (
    <Modal
      className="change-plan-modal"
      onClose={onClose}
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      displayCancelButton
      closeButtonVariant="white-with-black-border"
    >
      <ModalTitle>{renderTitle()}</ModalTitle>

      <ModalScroller maxHeight={647}>
        <SubscriptionStylingProvider
          sectionStylingModes={{ checkout: "indahash" }}
        >
          <Checkout
            planId={plan.id}
            // @ts-ignore
            onCheckoutCompleted={({ success, error }) => {
              if (success && stigg) {
                onClose();
                dispatch(setManageSubscriptionModalVisibility(false));
                dispatch(identify(activeWorkspaceUuid));
              } else if (error) {
                showErrorToast();
              }
            }}
          />
        </SubscriptionStylingProvider>
      </ModalScroller>
    </Modal>
  );
}

export default ChangePlanModal;
