import React, { useState } from "react";
import { SectionHeader } from "../components/SectionHeader";
import IDHFormattedMessage from "../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import PhoneIcon from "../../../../../images/phone.svg";
import EmailIcon from "../../../../../images/mail.svg";
import InfoIcon from "../../../../../images/info-grey.svg";
import { ReactComponent as LoaderDots } from "../../../../../images/loader-dots.svg";
import "./ContactData.scss";
import { Button } from "../../../../components/Button/Button";
import axios from "axios";
import { showToast } from "../../../../methods/showToast";
import classNames from "classnames";
import { PopupModal } from "react-calendly";
import { API_URLS } from "../../../../../utils/API_URLS";
import { useDispatch, useSelector } from "react-redux";
import { useCheckFeatureAccess } from "src/app/subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { setPlanUpgradeNeededModalContext } from "src/redux";

export function ContactData(props) {
  const {
    profileData: {
      creator_contact_data,
      user_data: { username, socialProvider },
    },
  } = props;

  const {
    mainReducer: { activeWorkspaceUuid, identity },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [showContactAccessDenied, setShowContactAccessDenied] = useState(false);
  const [showContactDataError, setShowContactDataError] = useState(false);
  const [blurData, setBlurData] = useState(true);
  const [displayEmail, setDisplayEmail] = useState(
    creator_contact_data?.email ?? null,
  );
  const [displayPhone, setDisplayPhone] = useState(
    creator_contact_data?.phone ?? null,
  );
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  const dispatch = useDispatch();

  const hasFeatureAccess = useCheckFeatureAccess(
    SubscriptionEntitlements.ContactDataVisibility,
  );

  const handleCalendlyOpenModal = () => {
    if (hasFeatureAccess) {
      setIsCalendlyModalOpen(true);
    } else {
      dispatch(
        setPlanUpgradeNeededModalContext(
          SubscriptionEntitlements.ContactDataVisibility,
        ),
      );
    }
  };

  const handleCalendlyCloseModal = () => {
    setIsCalendlyModalOpen(false);
  };

  const getContactData = () => {
    setLoading(true);
    const url = API_URLS.getContactData
      .replace(":workspaceUuid:", activeWorkspaceUuid)
      .replace(":socialProvider:", socialProvider)
      .replace(":socialUsername:", username)
      .replace(":wsMemberUuid:", identity?.id);

    axios
      .get(url)
      .then((response) => {
        if (response?.status === 200 && response?.data?.content) {
          if (
            response?.data?.content?.email ||
            response?.data?.content?.phone
          ) {
            setDisplayPhone(response?.data?.content?.phone ?? null);
            setDisplayEmail(response?.data?.content?.email ?? null);
            setBlurData(false);
          } else {
            setShowContactDataError(true);
          }
        } else {
          setShowContactDataError(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 402) {
          setShowContactAccessDenied(true);
        } else {
          showToast(
            "warning",
            <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />,
            <IDHFormattedMessage
              id="ws_contact_data_error"
              defaultMessage="Problem occure while get contact data"
            />,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div id="contact-element">
      <PopupModal
        url="https://calendly.com/tomasz-zembrzycki?hide_gdpr_banner=1"
        open={isCalendlyModalOpen}
        onModalClose={handleCalendlyCloseModal}
        rootElement={document.getElementById("contact-element")}
      />
      <SectionHeader
        text={
          <IDHFormattedMessage id="ws_contact_title" defaultMessage="Contact" />
        }
      />
      <div className="profile-box profile-box--block">
        <div
          className={classNames(
            "contact-data-div",
            showContactAccessDenied && "contact-data-div__height",
          )}
        >
          {displayEmail && (
            <div className="contact-data-div__row">
              <span className="contact-data-div__row__icon">
                <img src={EmailIcon} />
              </span>
              <div
                className={classNames(
                  "contact-data-div__row-text",
                  (blurData || showContactAccessDenied) &&
                    "contact-data-div__row-text__blur",
                )}
              >
                {displayEmail}
              </div>
            </div>
          )}
          {displayPhone && (
            <div className="contact-data-div__row">
              <span className="contact-data-div__row__icon">
                <img src={PhoneIcon} />
              </span>
              <div
                className={classNames(
                  "contact-data-div__row-text",
                  (blurData || showContactAccessDenied) &&
                    "contact-data-div__row-text__blur",
                )}
              >
                {displayPhone}
              </div>
            </div>
          )}
        </div>
        {showContactAccessDenied || showContactDataError ? (
          <div className="contact-data-div__access-denied-div">
            <div className="contact-data-div__access-denied-div__content">
              <img src={InfoIcon} />
              {showContactAccessDenied ? (
                <IDHFormattedMessage
                  id="ws_contact_data_access_denied"
                  defaultMessage="Your plan does not includer access to the contacts database. Upgrade your plan."
                />
              ) : (
                <IDHFormattedMessage
                  id="ws_contact_data_error"
                  defaultMessage="Problem occure with contact data"
                />
              )}
              <Button
                variant="white-with-black-border"
                size="medium"
                onClick={handleCalendlyOpenModal}
              >
                <IDHFormattedMessage
                  id="ws_contact_sales"
                  defaultMessage="Contact sales"
                />
              </Button>
            </div>
          </div>
        ) : (
          <>
            {blurData ? (
              <>
                {loading ? (
                  <div className="contact-data-div__loader">
                    <LoaderDots width={40} height={40} />
                  </div>
                ) : (
                  <Button
                    variant="white-with-black-border"
                    size="medium"
                    className="contact-data__show-contact-btn"
                    onClick={() => {
                      getContactData();
                    }}
                  >
                    <IDHFormattedMessage
                      id="ws_show_contact"
                      defaultMessage="Show contact"
                    />
                  </Button>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}
