import React, { ReactNode, useEffect, useState } from "react";
import "./Subscription.scss";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { identify } from "src/redux/main/mainActions";
import { RootState } from "src/redux/reducers";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";
import Plan from "./tabs/Plan/Plan";
import Billing from "./tabs/Billing/Billing";

enum SubscriptionTabs {
  Plan = "plan",
  Billing = "billing",
}

interface Props {
  onClose: () => void;
}

function Subscription(props: Props) {
  const { onClose } = props;

  const [activeTab, setActiveTab] = useState<SubscriptionTabs>(
    SubscriptionTabs.Plan,
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case SubscriptionTabs.Billing:
        return <Billing />;

      case SubscriptionTabs.Plan:
        return <Plan />;
    }
  };

  return (
    <>
      <AnimatedDiv className={classNames("subscription")}>
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_subscription"
            defaultMessage="Subscription"
          />
        </ModalTitle>

        <Tabs>
          <Tab
            active={activeTab === SubscriptionTabs.Plan}
            tabText={<IDHFormattedMessage id="ws_plan" defaultMessage="Plan" />}
            onClick={() => setActiveTab(SubscriptionTabs.Plan)}
          />
          <Tab
            active={activeTab === SubscriptionTabs.Billing}
            tabText={
              <IDHFormattedMessage id="ws_billing" defaultMessage="Billing" />
            }
            onClick={() => setActiveTab(SubscriptionTabs.Billing)}
          />
        </Tabs>

        {renderTabContent()}
      </AnimatedDiv>

      <SettingsButtons onClose={onClose} />
    </>
  );
}

export default Subscription;
