import React from "react";
import "./GetFreeSubscriptionModal.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle, ModalText } from "../../components/Modal/Modal";

import Gift from "../../../images/gift.png";
import { getBdWsMemberEmail } from "../../subscription/modals/SubscriptionUsageModal/utils";

function GetFreeSubscriptionModal(props) {
  const { onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const {
    mainReducer: { identity },
  } = useSelector((state) => state);

  return (
    <Modal
      className="get-free-subscription-modal"
      onClose={handleClose}
      displayCancelButton
      onCancelClick={handleClose}
      onConfirmClick={() =>
        window.open(
          "https://docs.google.com/forms/d/e/1FAIpQLSeqNuFL-cUBii19NnUP9MvMBv7aINulTtxSdKkLmHvMsNAfRQ/viewform?usp=sharing",
          "_blank",
          "noopener noreferrer",
        )
      }
      confirmButtonText={
        <IDHFormattedMessage
          id="ws_complete_survey"
          defaultMessage="Complete survey"
        />
      }
      closeIcon
      imgSrc={Gift}
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_get_1_extra_month_of_subscription_for_free"
          defaultMessage="Get 1 extra month of subscription for free!"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_get_free_subscription_modal_text"
          defaultMessage="Complete the survey and help us improve our product. Then, if you buy a
          annually access to our product within 7 days from the end of the trial
          period, you will receive 1 additional month of subscription from us for
          free!"
        />

        <div className="get-free-subscription-modal__contact">
          <IDHFormattedMessage
            id="ws_contact_our_sales_team_to_upgrade_your_account"
            defaultMessage="Contact our sales team to upgrade your account. Reach out to us at"
          />
          :
          <span className="contact-support-modal__email">
            &nbsp;
            <a href={`mailto:${getBdWsMemberEmail(identity)}`}>
              {getBdWsMemberEmail(identity)}
            </a>
          </span>
        </div>
      </ModalText>
    </Modal>
  );
}

export { GetFreeSubscriptionModal };
