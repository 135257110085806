import React from "react";
import "./Plan.scss";
import { CustomerPortal } from "@stigg/react-sdk";
import { useDispatch } from "react-redux";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ModalScroller } from "src/app/components/Modal/Modal";
import SubscriptionStylingProvider from "src/app/subscription/components/SubscriptionStylingProvider/SubscriptionStylingProvider";
import { setManageSubscriptionModalVisibility } from "src/redux/subscription/subscriptionActions";

function Plan() {
  const dispatch = useDispatch();

  return (
    <AnimatedDiv className="plan">
      {/* <ModalRow>
        <div>
          <ModalHeading>
            <IDHFormattedMessage id="ws_plan" defaultMessage="Plan" />

          <ModalHeading variant="big">
            <IDHFormattedMessage id="ws_launch" defaultMessage="Launch" />
          </ModalHeading>
        </div>

        <Button variant="white-with-border" size="large">
          <EditIcon />
          <IDHFormattedMessage id="ws_manage" defaultMessage="Manage" />
        </Button>
      </ModalRow> */}

      <ModalScroller maxHeight="100%">
        <SubscriptionStylingProvider
          sectionStylingModes={{
            subscriptionsOverview: "indahash",
            usage: "vertical-list",
          }}
        >
          <CustomerPortal
            onManageSubscription={() => {
              dispatch(setManageSubscriptionModalVisibility(true));
            }}
            hiddenSections={["billingInformation", "paymentDetails"]}
          />
        </SubscriptionStylingProvider>
      </ModalScroller>
    </AnimatedDiv>
  );
}

export default Plan;
