import React, { ReactNode } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import { ReactComponent as SuccessIcon } from "src/images/tick-empty-green-circle.svg";

interface Props {
  onClose: () => void;
  children: ReactNode;
}

function SuccessModal(props: Props) {
  const { onClose, children } = props;

  return (
    <Modal
      className="success-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonVariant="white-with-grey-border"
      closeButtonText={
        <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
      }
    >
      <ModalTitle>
        <SuccessIcon />
        <IDHFormattedMessage id="ws_done" defaultMessage="Done!" />
      </ModalTitle>
      <ModalText>{children}</ModalText>
    </Modal>
  );
}

export default SuccessModal;
