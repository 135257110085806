import React, { ReactNode } from "react";
import "./PlanUpgradeNeededBox.scss";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { ReactComponent as PadlockIcon } from "src/images/padlock.svg";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { SubscriptionEntitlements } from "../../subscriptionUtils";
import { getSubscriptionEntitlementSettings } from "./getSubscriptionEntitlementSettings";
import PleaseContactAdminPrompt from "../PleaseContactAdminPrompt/PleaseContactAdminPrompt";
import SubscriptionManagementButton from "../SubscriptionManagementButton/SubscriptionManagementButton";

interface Props {
  feature: SubscriptionEntitlements | null;
  closeModalButton?: ReactNode;
}

function PlanUpgradeNeededBox(props: Props) {
  const { feature, closeModalButton } = props;

  const { subscriptionPlan } = useSelector(
    (state: RootState) => state.subscriptionReducer,
  );

  if (!feature) return null;

  const data = getSubscriptionEntitlementSettings(feature);

  return (
    <AnimatedDiv className="plan-upgrade-needed-box">
      <img
        className="plan-upgrade-needed-box__image"
        src={data.image}
        alt={`${feature}-image`}
      />
      <div className="plan-upgrade-needed-box__yellow-bar">
        <PadlockIcon />
        <IDHFormattedMessage
          id="ws_upgrade_your_plan_to_unlock_access_to_this_feature"
          defaultMessage="Upgrade your plan to unlock access to this feature."
        />
      </div>
      <div className="plan-upgrade-needed-box__heading">
        <img
          className="plan-upgrade-needed-box__heading-icon"
          src={data.icon}
          alt="section-icon"
        />
        <span className="plan-upgrade-needed-box__heading-name">
          <IDHFormattedMessage
            id={data.translation?.id}
            defaultMessage={data.translation?.defaultMessage}
          />
          &nbsp;
          <IDHFormattedMessage
            id="ws_not_available"
            defaultMessage="not available"
          />
        </span>
      </div>
      <p className="plan-upgrade-needed-box__text">
        <IDHFormattedMessage
          id={data.translation?.id}
          defaultMessage={data.translation?.defaultMessage}
        />{" "}
        <IDHFormattedMessage
          id="ws_is_not_included_in_your_current"
          defaultMessage="is not included in your current"
        />{" "}
        {subscriptionPlan?.displayName}{" "}
        <IDHFormattedMessage
          id="plan_upgrade_to_access_this_feature"
          defaultMessage="plan. Upgrade to access this feature"
        />
        .{" "}
        {data.learnMoreUrl ? (
          <a
            href={data.learnMoreUrl}
            className="plan-upgrade-needed-box__text-link"
            target="_blank"
            rel="noreferrer"
          >
            <IDHFormattedMessage
              id="ws_learn_more"
              defaultMessage="Learn more"
            />
          </a>
        ) : (
          <IDHFormattedMessage id="ws_learn_more" defaultMessage="Learn more" />
        )}{" "}
        <IDHFormattedMessage id="ws_about" defaultMessage="about" />{" "}
        <IDHFormattedMessage
          id={data.translation?.id}
          defaultMessage={data.translation?.defaultMessage}
        />
        .
      </p>

      <PleaseContactAdminPrompt />

      <div className="plan-upgrade-needed-box__buttons">
        {closeModalButton}
        <SubscriptionManagementButton feature={feature} />
      </div>
    </AnimatedDiv>
  );
}

export default PlanUpgradeNeededBox;
