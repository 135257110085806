import React from "react";
import "./SubscriptionUsageModal.scss";

import { CustomerPortalProvider, CustomerUsageData } from "@stigg/react-sdk";

import Modal, {
  ModalScroller,
  ModalText,
  ModalTitle,
} from "src/app/components/Modal/Modal";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as WarningIcon } from "src/images/warning-orange.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import classNames from "classnames";
import { setSubscriptionEntitlementErrors } from "src/redux/main/mainActions";
import { Button } from "src/app/components/Button/Button";
import PleaseContactAdminPrompt from "../../components/PleaseContactAdminPrompt/PleaseContactAdminPrompt";
import SubscriptionManagementButton from "../../components/SubscriptionManagementButton/SubscriptionManagementButton";

export enum SubscriptionUsageModalMode {
  Warning = "warning",
  Info = "info",
}

interface Props {
  onClose: () => void;
  mode: SubscriptionUsageModalMode;
}

function SubscriptionUsageModal(props: Props) {
  const { onClose, mode } = props;

  const subscriptionEntitlementErrors = useSelector(
    (state: RootState) => state.mainReducer.subscriptionEntitlementErrors,
  );

  const feature = subscriptionEntitlementErrors[0]?.field;

  const dispatch = useDispatch();

  const renderModalInfo = () => {
    switch (mode) {
      case SubscriptionUsageModalMode.Info:
        return (
          <ModalTitle>
            <IDHFormattedMessage id="ws_usage" defaultMessage="Usage" />
          </ModalTitle>
        );

      case SubscriptionUsageModalMode.Warning:
        return (
          <>
            <ModalTitle>
              <WarningIcon />
              <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />
            </ModalTitle>

            <ModalText>
              {subscriptionEntitlementErrors.length ? (
                <>
                  <IDHFormattedMessage
                    id="ws_you_cannot_perform_this_action_you_do_not_have_enough_limits_in"
                    defaultMessage="You cannot perform this action. You do not have enough limits in:"
                  />
                  <ul className="subscription-usage-modal__highlighted-text">
                    {subscriptionEntitlementErrors.map((error) => (
                      <li>
                        <IDHFormattedMessage
                          id={`ws_${error.field}`}
                          defaultMessage={error.field.replaceAll("-", " ")}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <IDHFormattedMessage
                    id="ws_you_have_used_your_trial"
                    defaultMessage="You've already used up your trial period."
                  />
                  &nbsp;
                </>
              )}
              <PleaseContactAdminPrompt />
            </ModalText>
          </>
        );
    }
  };

  const handleClose = () => {
    dispatch(setSubscriptionEntitlementErrors([]));
    onClose();
  };

  return (
    <Modal
      className={classNames(
        "subscription-usage-modal",
        `subscription-usage-modal--${mode}`,
      )}
      onClose={handleClose}
    >
      {renderModalInfo()}

      <ModalScroller maxHeight={260}>
        <CustomerPortalProvider>
          <CustomerUsageData />
        </CustomerPortalProvider>
      </ModalScroller>

      <div className="subscription-usage-modal__buttons">
        <Button
          size="large"
          variant="white-with-grey-border"
          onClick={onClose}
          data-qa="close-button"
        >
          <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
        </Button>

        <SubscriptionManagementButton
          feature={feature}
          upgrade={{
            text: (
              <IDHFormattedMessage
                id="ws_upgrade_now"
                defaultMessage="Upgrade now"
              />
            ),
          }}
        />
      </div>
    </Modal>
  );
}

export default SubscriptionUsageModal;
