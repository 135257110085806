import React from "react";
import "./SubscriptionContactSupportModal.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import { RootState } from "src/redux/reducers";
import { ReactComponent as InfoIcon } from "src/images/info-circle-blue-empty.svg";

interface Props {
  onClose: () => void;
}

export function SubscriptionContactSupportModal(props: Props) {
  const { onClose } = props;

  const { identity } = useSelector((state: RootState) => state.mainReducer);

  const contactEmail = identity?.subscriptionData?.bdWsMemberEmail;

  return (
    <Modal
      className="subscription-contact-support-modal"
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      displayCancelButton
      closeButtonVariant="white-with-light-grey-border"
      onClose={onClose}
    >
      <ModalTitle>
        <InfoIcon />
        <IDHFormattedMessage
          id="ws_contact_support"
          defaultMessage="Contact support"
        />
      </ModalTitle>

      <ModalText>
        <IDHFormattedMessage
          id="ws_to_contact_our_support_team_please_write_to"
          defaultMessage="To contact our support team, please write to"
        />
        :
        <div className="subscription-contact-support-modal__email">
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </div>
      </ModalText>
    </Modal>
  );
}
