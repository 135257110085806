import React from "react";

import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export default function AudiencesResultsCheckboxWrapper({
  isPreselected,
  children,
}) {
  return (
    <div className="checkbox-wrapper">
      {isPreselected ? (
        <MaterialTooltip
          right
          small
          noBreak
          content={
            <IDHFormattedMessage
              id="front_already_selected"
              defaultMessage="Already selected"
            />
          }
        >
          {children}
        </MaterialTooltip>
      ) : (
        children
      )}
    </div>
  );
}
