import { SubscriptionPlanChangeData } from "src/app/subscription/modals/ManageSubscriptionModal/ManageSubscriptionModal";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { SubscriptionUsageModalMode } from "src/app/subscription/modals/SubscriptionUsageModal/SubscriptionUsageModal";
import { StiggContextValue } from "@stigg/react-sdk";
import { subscriptionTypes } from "./subscriptionTypes";
import { SubscriptionPlan } from "./subscriptionReducer";

export function setStiggContextValue(
  stiggContextValue: StiggContextValue | null,
) {
  return {
    type: subscriptionTypes.SET_STIGG_CONTEXT_VALUE,
    payload: { stiggContextValue },
  };
}

export function setSubscriptionUsageModalMode(
  subscriptionUsageModalMode: SubscriptionUsageModalMode | null,
) {
  return {
    type: subscriptionTypes.SET_SUBSCRIPTION_MODAL_MODE,
    payload: { subscriptionUsageModalMode },
  };
}

export function setManageSubscriptionModalVisibility(
  manageSubscriptionModalVisible: boolean,
) {
  return {
    type: subscriptionTypes.SET_MANAGE_SUBSCRIPTION_MODAL_VISIBILITY,
    payload: { manageSubscriptionModalVisible },
  };
}

export function setSelectedSubscriptionPlanData(
  selectedSubscriptionPlanData: SubscriptionPlanChangeData | null,
) {
  return {
    type: subscriptionTypes.SET_SELECTED_SUBSCRIPTION_PLAN_DATA,
    payload: { selectedSubscriptionPlanData },
  };
}

export function setPlanUpgradeNeededModalContext(
  planUpgradeNeededModalContext: SubscriptionEntitlements | null,
) {
  return {
    type: subscriptionTypes.SET_PLAN_UPGRADE_NEEDED_MODAL_CONTEXT,
    payload: { planUpgradeNeededModalContext },
  };
}

export function setSubscriptionPlan(subscriptionPlan: SubscriptionPlan | null) {
  return {
    type: subscriptionTypes.SET_SUBSCRIPTION_PLAN,
    payload: { subscriptionPlan },
  };
}

export function setSubscriptionContactSupportModalVisibility(
  subscriptionContactSupportModalVisibility: boolean,
) {
  return {
    type: subscriptionTypes.SET_SUBSCRIPTION_CONTACT_SUPPORT_MODAL_VISIBILITY,
    payload: { subscriptionContactSupportModalVisibility },
  };
}

export function setUnlockAddonsModalVisibility(
  unlockAddonsModalVisibility: boolean,
) {
  return {
    type: subscriptionTypes.SET_UNLOCK_ADDONS_MODAL_VISIBILITY,
    payload: { unlockAddonsModalVisibility },
  };
}
