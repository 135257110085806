import React, { useEffect, useRef, useState } from "react";
import "./TasksTableField.scss";

import classNames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { AccessLinkMetaField } from "src/app/metafields/AccessLinkMetaField/AccessLinkMetaField";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { Coupon } from "src/redux/coupon/couponReducer";
import { getCouponsList } from "src/redux/coupon/couponActions";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { DictionaryElementSelector } from "src/app/dropdowns/DictionaryElementSelector/DictionaryElementSelector";
import AggregatedSelect from "src/app/components/AggregatedSelect/AggregatedSelect";
import { RootState } from "src/redux/reducers";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import { ReactComponent as RemoveIcon } from "../../../images/close-alt.svg";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";

import CustomDatePicker, {
  DatePickerWrapper,
} from "../../components/CustomDatePicker/CustomDatePicker";
import { IMember } from "../ProjectTypes";
import ChoosePersonDropdown from "../../dropdowns/ChoosePersonDropdown/ChoosePersonDropdown";

import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import {
  changeTaskMetaFieldValue,
  updateProjectMetaValue,
  setLocalSyncToggle,
  setTableInputFocused,
  changeWorkspaceTaskMetaFieldValue,
} from "../../../redux";
import Select from "../../components/Select/Select";
import useOnClickOutside from "../../methods/useOnClickOutside";
import { FieldSynchronizationWarningModal } from "../../modals/FieldSynchronizationWarningModal/FieldSynchronizationWarningModal";
import { tableDataType } from "../../components/Table/Table";
import TaskTableField from "./components/TaskTableField/TaskTableField";
import { ProjectTableField } from "./components/ProjectTableField/ProjectTableField";
import { ProjectFieldPreviewSwitch } from "./components/ProjectFieldPreviewSwitch";
import { ProjectFieldEditSwitch } from "./components/ProjectFieldEditSwitch";
import { ProgressField } from "./meta-types/ProgressField/ProgressField";
import { Metadata } from "../../Task/Subtask/Subtask";
import { FileField } from "../../metafields/FileField/FileField";
import { TextBoxWrapper } from "../../components/TextBoxWrapper/TextBoxWrapper";
import SelectDropdown from "../../dropdowns/SelectDropdown/SelectDropdown";
import { TableContext } from "./types";
import ProjectSelector from "../../components/ProjectSelector/ProjectSelector";

interface TasksTableFieldInterface {
  activeWorkspaceUuid: string;
  columnKey: string;
  columnName: string;
  couponsList: Coupon[];
  dataType: tableDataType;
  fieldData: Metadata;
  fieldValue: string;
  membersList: IMember[];
  objectId: string;
  rowId: string;
  selectedTasks: any;
  title: string;
  overrideValue?: boolean;
  blockManualEdit?: boolean;
  tableContext: TableContext | undefined;
  projectId: string;
}

export const ACTION_TYPES = [MetaFieldType.Creator, MetaFieldType.Content];

export default function TasksTableField({
  activeWorkspaceUuid,
  columnKey,
  columnName,
  couponsList,
  dataType,
  fieldData,
  fieldValue,
  membersList,
  objectId,
  rowId,
  selectedTasks,
  title,
  overrideValue,
  blockManualEdit,
  tableContext,
  projectId,
}: TasksTableFieldInterface) {
  const {
    data,
    manuallyEdited,
    name,
    shouldTriggerEditWarning,
    type,
    value: fieldVal,
    valueSource,
  } = fieldData;

  const uuid =
    tableContext === "workspaceTasksList"
      ? fieldData.wsGlobalTaskMetaFieldUuid!
      : fieldData.uuid;

  const [value, setValue] = useState<any>(fieldValue);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [member, setMember] = useState<any>({});
  const [showEditMark, setShowEditMark] = useState(manuallyEdited);
  const [disableFieldClick, setDisableFieldClick] = useState(
    shouldTriggerEditWarning,
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLDivElement | null>(null);
  const fieldContentRef = useRef<any>();

  const dispatch = useDispatch();

  const wsProjectAutocompleteList = useSelector(
    (state: RootState) => state.projectReducer.wsProjectAutocompleteList,
  );

  const isReadOnly =
    overrideValue ||
    blockManualEdit ||
    (dataType === tableDataType.Project && valueSource && true);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const addEditMark = () => {
    if (manuallyEdited) {
      setShowEditMark(true);
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus();
      setShowEditMark(false);
    } else {
      addEditMark();
    }
  }, [editMode]);

  const updateTaskMetaValue = (
    uuids: string | string[],
    uuid: string,
    newValue: any,
  ) => {
    if (tableContext === "workspaceTasksList") {
      dispatch(changeWorkspaceTaskMetaFieldValue(uuids, uuid, newValue));
    } else {
      dispatch(changeTaskMetaFieldValue(uuids, uuid, newValue));
    }
  };

  const onTableValueChange = (newValue: any) => {
    if (newValue || newValue === false || newValue === null) {
      let uuids = [objectId];
      if (selectedTasks.find((item: { id: string }) => item?.id === rowId)) {
        uuids = selectedTasks.map((task: { taskId: string }) => {
          return task.taskId;
        });
      }
      updateTaskMetaValue(uuids, uuid, newValue);
      dispatch(setLocalSyncToggle(true));
    }
  };

  const onValueChange = (newValue: any) => {
    if (newValue === undefined) return;
    return onTableValueChange ? onTableValueChange(newValue) : null;
  };

  const updateMember = (newValue: { id: string }) => {
    if (newValue) {
      setMember(newValue);
      onValueChange(newValue.id);
    }
  };

  useEffect(() => {
    if (showEditMark !== manuallyEdited) {
      setShowEditMark(manuallyEdited);
    }
  }, [manuallyEdited]);

  useEffect(() => {
    if (disableFieldClick !== shouldTriggerEditWarning) {
      setDisableFieldClick(shouldTriggerEditWarning);
    }
  }, [shouldTriggerEditWarning]);

  useEffect(() => {
    if (type === MetaFieldType.Member) {
      const memberCopy = membersList?.find(
        (member: IMember) => member.id === fieldValue,
      );

      setMember(memberCopy);
    }
  }, [fieldValue, membersList]);

  const addEditMarkAfterUpdate = () => {
    if (
      (manuallyEdited && !shouldTriggerEditWarning) ||
      (!manuallyEdited && shouldTriggerEditWarning)
    ) {
      setShowEditMark(true);
      setEditMode(false);
    }
  };

  const emptyFieldClick = () => {
    setValue("");
    setEditMode(true);
    inputRef.current?.focus();
  };

  const clearTaskFieldValue = async () => {
    if (
      selectedTasks.length > 1 &&
      selectedTasks.find((item: { id: string }) => item.id === objectId)
    ) {
      const uuids = selectedTasks.map((task: { taskId: string }) => {
        return task.taskId;
      });

      try {
        updateTaskMetaValue(uuids, uuid, null);
      } catch {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      }
    } else {
      updateTaskMetaValue(objectId, uuid, null);
    }
  };

  const clearFieldValue = () => {
    if (objectId && uuid) {
      switch (dataType) {
        case tableDataType.Task:
          clearTaskFieldValue();
          break;
        case tableDataType.Project:
          dispatch(updateProjectMetaValue(objectId, uuid, null));
          break;
        default:
          break;
      }
    }
  };

  const clearField = async () => {
    dispatch(setLocalSyncToggle(true));
    await setValue(undefined);
    setValue(null);
    setEditMode(false);
    clearFieldValue();
  };

  const clearMember = () => {
    setMember(null);
    clearFieldValue();
  };

  const textboxOnBlur = (newValue: string) => {
    dispatch(setTableInputFocused(false));
    if (!newValue?.length) {
      clearField();
    } else {
      onValueChange(newValue);
      addEditMarkAfterUpdate();
    }
  };

  const inputOnBlur = () => {
    if (!value?.length) {
      clearField();
    } else {
      onValueChange(value);
      addEditMarkAfterUpdate();
    }
    setEditMode(false);
  };

  const onDateChange = (newDate: string | boolean | number | null) => {
    onValueChange(newDate);
  };

  const onFieldClick = () => {
    setIsFocused(true);

    if (disableFieldClick && !showWarningModal) {
      setShowWarningModal(true);
    }
  };

  const confirmWarning = () => {
    setDisableFieldClick(false);
    setShowWarningModal(false);

    fieldContentRef.current.click();
  };

  const renderBasicTypes = () => {
    switch (type) {
      case MetaFieldType.Member:
        return member ? (
          <>
            <ChoosePersonDropdown
              uuid={uuid || ""}
              placeholder={member?.name}
              choosePerson={updateMember}
              dataType={dataType}
              wsTeamUuids={data?.wsTeamUuids}
            >
              <div className="assignee-field">
                <img src={member.avatarUrl || AvatarPlaceholder} alt="avatar" />
                <span>
                  <TooltipedEllipsis
                    className="assignee-field__username"
                    maxWidth={160}
                    content={member.name}
                  />
                </span>
              </div>
            </ChoosePersonDropdown>
            {!disableFieldClick && (
              <div onClick={clearMember} className="tasks-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        ) : (
          <ChoosePersonDropdown
            uuid={uuid || ""}
            placeholder={member?.name}
            choosePerson={updateMember}
            dataType={dataType}
            wsTeamUuids={data?.wsTeamUuids}
          >
            <div className="tasks-table__empty-field tasks-table__empty-field--circle">
              <PlusIcon />
            </div>
          </ChoosePersonDropdown>
        );

      case MetaFieldType.SingleSelect:
        return (
          <SelectDropdown
            uuid={uuid || ""}
            dataType={dataType}
            fieldData={fieldData}
            fieldValue={fieldValue}
            onValueChange={onValueChange}
            selectOptionType="single"
            wsSelectDataSetUuid={data?.wsSelectDataSetUuid}
            emptyFieldPlaceholder={
              <div className="ws-table__empty-field">
                <PlusIcon />
              </div>
            }
            removeValueButton={
              fieldValue &&
              !disableFieldClick && (
                <div onClick={clearField} className="tasks-table__field-close">
                  <RemoveIcon />
                </div>
              )
            }
            qaContext={dataType}
          />
        );

      case MetaFieldType.MultiSelect:
        return (
          <SelectDropdown
            uuid={uuid || ""}
            dataType={dataType}
            fieldData={fieldData}
            fieldValue={fieldValue}
            onValueChange={onValueChange}
            selectOptionType="multi"
            wsSelectDataSetUuid={data?.wsSelectDataSetUuid}
            stackLabels
            emptyFieldPlaceholder={
              <div className="ws-table__empty-field">
                <PlusIcon />
              </div>
            }
            removeValueButton={
              fieldValue &&
              !disableFieldClick && (
                <div onClick={clearField} className="tasks-table__field-close">
                  <RemoveIcon />
                </div>
              )
            }
          />
        );

      case MetaFieldType.AggregatedSelect:
        return (
          <>
            <AggregatedSelect
              uuid={uuid || ""}
              dataType={dataType}
              optionsData={fieldData?.data?.singleSelectOptions}
              fieldValue={fieldValue}
              updateValue={onValueChange}
              emptyFieldPlaceholder={
                <div className="ws-table__empty-field">
                  <PlusIcon />
                </div>
              }
            />
            {value && !disableFieldClick && (
              <div onClick={clearField} className="ws-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        );

      case MetaFieldType.Coupon:
        return (
          <>
            <Select
              mode="single"
              optionsData={couponsList
                .filter((coupon) =>
                  coupon.isAssigned
                    ? coupon?.wsTask?.taskId === objectId
                    : true,
                )
                ?.map((coupon) => ({
                  name: coupon.code,
                  value: coupon.uuid,
                  rank: "a",
                  color: "45485b",
                  backgroundColor: "f6f6f7",
                }))}
              uuid={uuid || ""}
              fieldValue={fieldValue}
              fieldName={name}
              variant="disable_all"
              updateValue={(newValue: any) => {
                onValueChange(newValue);
                dispatch(getCouponsList(activeWorkspaceUuid));
              }}
              emptyFieldPlaceholder={
                <div className="tasks-table__empty-field">
                  <PlusIcon />
                </div>
              }
              dataType={dataType}
            />
            {fieldValue && !disableFieldClick && (
              <div onClick={clearField} className="tasks-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        );

      case MetaFieldType.ProgressBar:
        return <ProgressField value={value || ""} options={data} />;

      case MetaFieldType.Date:
        return (
          <>
            <DatePickerWrapper value={value}>
              <CustomDatePicker
                initialDate={value}
                dateChangeHandler={onDateChange}
              />
            </DatePickerWrapper>
            {fieldValue && (
              <div onClick={clearField} className="tasks-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        );

      case MetaFieldType.File:
        return (
          <FileField
            files={value}
            uuid={uuid}
            objectId={objectId}
            dataType={dataType}
            projectId={projectId}
          />
        );

      case MetaFieldType.Text:
        return value || value === "" ? (
          <>
            <TextBoxWrapper
              ref={inputRef}
              value={value || ""}
              onChange={setValue}
              onFocus={() => dispatch(setTableInputFocused(true))}
              onBlur={textboxOnBlur}
            />

            <div
              onClick={clearField}
              className="tasks-table__field-close tasks-table__field-close--static"
            >
              <RemoveIcon />
            </div>
          </>
        ) : (
          <div className="tasks-table__empty-field" onClick={emptyFieldClick}>
            <PlusIcon />
          </div>
        );
      case MetaFieldType.AccessLink:
        return (
          <AccessLinkMetaField
            accessLinkValue={value}
            uuid={uuid}
            objectId={objectId}
            dataType={dataType}
          />
        );
      case MetaFieldType.BoolVal:
        return (
          <CustomCheckbox
            id={`${uuid}-${objectId}`}
            name={uuid}
            checked={value}
            onChange={(e) => {
              onValueChange(e.target.checked);
            }}
            blue
          />
        );
      case MetaFieldType.DictionaryElement:
        return (
          <>
            <DictionaryElementSelector
              value={fieldVal}
              wsDictionaryUuid={data?.wsDictionaryUuid}
              wsDictionarySubFieldUuid={data?.wsDictionarySubFieldUuid}
              wsDictionaryElementsFilter={data?.wsDictionaryElementsFilter}
              onValueChange={onValueChange}
              emptyFieldClick={emptyFieldClick}
              rowId={rowId}
            />
            {fieldValue && (
              <div onClick={clearField} className="tasks-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        );

      case MetaFieldType.Project:
        return (
          <ProjectSelector
            value={value}
            wsProjectAutocompleteList={wsProjectAutocompleteList ?? []}
            wsWorkspaceUuid={activeWorkspaceUuid}
          />
        );

      default:
        return value || value === "" ? (
          <>
            {editMode ? (
              <ProjectFieldEditSwitch
                fieldType={type}
                initialDate={value}
                dateChangeHandler={onDateChange}
                value={value}
                setValue={setValue}
                onBlur={inputOnBlur}
                inputRef={inputRef}
                prefix={fieldData?.data?.currencyCode || ""}
              />
            ) : (
              <ProjectFieldPreviewSwitch
                data={fieldData}
                fieldType={type}
                setEditMode={setEditMode}
                value={value}
                prefix={fieldData?.data?.currencyCode || ""}
                dataType={tableDataType.Task}
              />
            )}

            <div
              onClick={clearField}
              className={classNames("tasks-table__field-close", {
                "tasks-table__field-close--hidden":
                  disableFieldClick || editMode,
              })}
            >
              <RemoveIcon />
            </div>
          </>
        ) : (
          <div className="tasks-table__empty-field" onClick={emptyFieldClick}>
            <PlusIcon />
          </div>
        );
    }
  };

  const renderComponentBasedOnDataType = () => {
    switch (dataType) {
      case tableDataType.Task:
        return (
          <TaskTableField
            fieldValue={fieldValue}
            fieldType={type}
            renderBasicTypes={renderBasicTypes}
            onValueChange={onValueChange}
            shouldTriggerEditWarning={disableFieldClick}
            clearFieldValue={clearFieldValue}
            tableContext={tableContext}
            projectId={projectId}
          />
        );

      case tableDataType.Project:
        return (
          <ProjectTableField
            fieldType={type}
            renderBasicTypes={renderBasicTypes}
          />
        );
      default:
        return null;
    }
  };

  useOnClickOutside(fieldRef, () => {
    setIsFocused(false);
  });

  return (
    <div
      ref={fieldRef}
      className={classNames(
        "tasks-table__field",
        `tasks-table__field--${type}`,
        {
          "tasks-table__field--hovered": isFocused,
          "tasks-table__field--read-only": isReadOnly,
          "tasks-table__field--not-empty": value,
        },
      )}
      onClick={onFieldClick}
      data-task-title={title}
      data-task-column-key={columnKey}
      data-task-column-name={columnName}
    >
      <span
        key={type !== MetaFieldType.MultiSelect ? fieldValue?.toString() : uuid}
        ref={fieldContentRef}
        className={classNames("tasks-table__field-content", {
          "tasks-table__field-content--disabled": disableFieldClick,
        })}
      >
        {renderComponentBasedOnDataType()}
        {showEditMark && !overrideValue && fieldValue && (
          <div className="tasks-table__field-content-edit-icon">
            <EditIcon />
            <IDHFormattedMessage id="ws_edited" defaultMessage="Edited" />
          </div>
        )}
      </span>

      {showWarningModal && (
        <FieldSynchronizationWarningModal
          onClose={() => setShowWarningModal(false)}
          onAccept={confirmWarning}
        />
      )}
    </div>
  );
}
