import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { uuidv7 as uuid } from "uuidv7";

import {
  deleteSelectedTasks,
  hideShareMultipleColumnsModal,
  hideStopSharingWithClientModal,
  openGroupShareMultipleColumnsModal,
  openStopSharingGroupWithClientModal,
  setCreateSingleSplitModalOpen,
  setCreatorMessagePaneOpen,
  setPlanUpgradeNeededModalContext,
  setSelectedTasks,
} from "src/redux";
import { SelectedTasksBar } from "src/app/components/SelectedTasksBar/SelectedTasksBar";
import AddCreatorsCDTWorkspaceProjectModal, {
  SelectedCreator,
} from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { wsAxiosPost } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";
import { generateRankString } from "src/utils/rankStrings";
import { ifRowMatchesFilters } from "src/app/project/SingleProject/TasksTable/tasksTableSorting/tableSortingFunctions/filterTableData";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as CreatePaymentsIcon } from "src/images/payment.svg";
import { ReactComponent as ShowcaseIcon } from "src/images/showcase.svg";
import { ReactComponent as RubberIcon } from "src/images/rubber.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";
import { ReactComponent as ClientOrangeIcon } from "src/images/client-orange.svg";
import { ReactComponent as ClientStopShareIcon } from "src/images/client-light-grey.svg";
import { ReactComponent as DownloadIcon } from "src/images/download-rounded.svg";
import { ExtensionTypeEnum, TaskType } from "src/types";
import Loader from "src/app/components/Loader/Loader";
import { taskAvatarShape } from "src/app/components/Table/Table";
import StopSharingGroupWithClientModal from "src/app/modals/StopSharingGroupWithClientModal/StopSharingGroupWithClientModal";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { AddToDropdownSelectedTasksBar } from "src/app/components/SelectedTasksBar/components/AddToDropdownSelectedTasksBar";
import { navigateToCreateNewProject } from "src/app/SettingsModal/methods";
import { setCreateNewProjectData } from "src/redux/main/mainActions";
import { ShareMultipleColumnsModal } from "src/app/modals/ShareMultipleColumnsModal/ShareMultipleColumnsModal";
import { RootState } from "src/redux/reducers";
import {
  CreatorShowcaseContext,
  openCreatorShowcase,
  MAX_CREATORS_FOR_GENERATE_SHOWCASE,
} from "src/app/CreatorShowcase/utils";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "src/utils/PermissionsEnums";
import { ReactComponent as SendIcon } from "src/images/send.svg";
import { useCheckFeatureAccess } from "src/app/subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { showToast } from "../../../methods/showToast";
import ContentProposalElementsSummaryDownloadDropdown from "../../../dropdowns/ContentProposalElementsSummaryDownloadDropdown/ContentProposalElementsSummaryDownloadDropdown";
import { WsContentProposalDownloadModal } from "../../../modals/WsContentProposalDownloadModal/WsContentProposalDownloadModal";
import { TableContext } from "../../TableField/types";

const MAX_CREATORS_FOR_SENDING_MESSAGE = 100;

export const selectedCreatorsPayload = (
  selectedCreators: SelectedCreator[],
) => {
  let previousRank = "a";

  return selectedCreators.map((creator: SelectedCreator) => {
    const newRank = generateRankString(previousRank, "");
    previousRank = newRank;

    return {
      uuid: uuid(),
      name: creator.name,
      socialProfiles: creator.socialProfiles,
      rank: newRank,
    };
  });
};

export function SingleProjectSelectedTasksBar({
  tableContext,
}: {
  tableContext?: TableContext;
}) {
  const {
    taskReducer: {
      tasksLoading,
      selectedTasks,
      showShareGroupMultipleColumnsModal,
      showStopSharingGroupWithClientModal,
    },
    mainReducer: { activeWorkspaceUuid, identity },
    projectReducer: { taskType, projectBasicData },
    taskFiltersReducer: { selectedFilters },
  } = useSelector((state: RootState) => state);

  const [isTooltipOpen, setIsTooltipOpen] = useState(
    window.localStorage.getItem("selectedTasksBarTooltipHidden") === null
      ? true
      : !JSON.parse(
          window.localStorage.getItem(
            "selectedTasksBarTooltipHidden",
          ) as string,
        ),
  );
  const [
    showModalAddCreatorsToWorkspaceProject,
    setShowModalAddCreatorsToWorkspaceProject,
  ] = useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [selectedCreators, setSelectedCreators] = useState<SelectedCreator[]>(
    [],
  );
  const [showContentDownloadModal, setShowContentDownloadModal] =
    useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const hasFeatureAccess = useCheckFeatureAccess(
    SubscriptionEntitlements.WsMessengerExtension,
  );

  const hasCreateSingleSplitPaymentsExtension = projectBasicData
    ? projectBasicData.wsExtensions.find(
        (extenson) =>
          extenson.type === ExtensionTypeEnum.CreateSingleAndSplitPayments,
      ) !== undefined
    : false;

  const clearSelected = () => {
    dispatch(setSelectedTasks([]));
  };

  const deleteSelected = async () => {
    if (!projectBasicData) return;

    dispatch(deleteSelectedTasks(selectedTasks));
  };

  const openCreateSingleSplitPaymentsModal = () => {
    dispatch(setCreateSingleSplitModalOpen(true));
  };

  const createPaymentsForItems = () => {
    if (!projectBasicData) return;
    const uuids = selectedTasks.map((selectedTask) => selectedTask.taskId);
    wsAxiosPost(
      API_URLS.createPaymentsToSelectedTasks,
      {
        wsTaskUuids: uuids,
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsProjectUuid: projectBasicData.projectId,
        wsTaskType: taskType,
      },
      undefined,
      () => {},
      () => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_create_payment_for_selected_task"
            defaultMessage="Payment for selected item(s) will be add soon"
          />,
        );
        clearSelected();
      },
      () => {},
    );
  };

  const renderCreatePaymentsButton = () => {
    if (taskType === TaskType.Creator || taskType === TaskType.Publication) {
      if (
        projectBasicData?.permissions?.project.includes(
          ProjectPermissionsEnum.TASK_MANAGEMENT,
        ) &&
        !hasCreateSingleSplitPaymentsExtension
      ) {
        return (
          <Button
            size="large"
            variant="white"
            onClick={createPaymentsForItems}
            data-action="createPaymentsForItems"
            disabled={!selectedTasks.length || tasksLoading}
          >
            <CreatePaymentsIcon />
            <IDHFormattedMessage
              id="ws_create_payments"
              defaultMessage="Create payments"
            />
          </Button>
        );
      }
      if (
        hasCreateSingleSplitPaymentsExtension &&
        projectBasicData?.permissions?.project.includes(
          ProjectPermissionsEnum.TASK_MANAGEMENT,
        )
      ) {
        return (
          <Button
            size="large"
            variant="white"
            onClick={openCreateSingleSplitPaymentsModal}
            data-action="createFromSingleAndSplitPaymentsExtension"
            disabled={!selectedTasks.length || tasksLoading}
          >
            <CreatePaymentsIcon />
            <IDHFormattedMessage
              id="ws_create_payments"
              defaultMessage="Create payments"
            />
          </Button>
        );
      }
    }
    return null;
  };

  const hasAccessToCreatorMessage =
    taskType === TaskType.Creator &&
    identity?.permissions?.workspace?.includes(
      WorkspacePermissionsEnum.SEND_WS_MESSENGER_MESSAGE,
    ) &&
    identity?.hasAccessToSendMessage;

  const openShowcase = () => {
    if (taskType !== TaskType.Creator) {
      return;
    }

    const ids = selectedTasks.map((el: any) => el.taskId)?.reverse();
    openCreatorShowcase({
      history,
      context: CreatorShowcaseContext.CreatorList,
      creatorId: ids[0],
      ids,
      workspaceId: activeWorkspaceUuid,
    });
  };

  const countTaskByShareValue = (shareValue: boolean) => {
    let counter = 0;

    selectedTasks?.forEach((task) => {
      if (task?.shared === shareValue) {
        counter += 1;
      }
    });

    return counter;
  };

  const hideTooltip = () => {
    localStorage.setItem("selectedTasksBarTooltipHidden", JSON.stringify(true));
    setIsTooltipOpen(false);
  };

  const dispatchDataAndNavigateToCreateNewProject = () => {
    dispatch(
      setCreateNewProjectData({
        selectedCreatorsPayload: selectedCreatorsPayload(selectedCreators),
      }),
    );
    navigateToCreateNewProject(history, activeWorkspaceUuid);
  };

  const openCreatorMessagePane = () => {
    if (hasFeatureAccess) {
      dispatch(setCreatorMessagePaneOpen(true));
    } else {
      dispatch(
        setPlanUpgradeNeededModalContext(
          SubscriptionEntitlements.WsMessengerExtension,
        ),
      );
    }
  };

  const renderSelectedTasksBarContent = () => {
    if (tableContext === "workspaceTasksList") {
      return (
        <Button
          size="large"
          variant="white"
          onClick={clearSelected}
          disabled={!selectedTasks.length}
          data-action="clearSelected"
        >
          <RubberIcon />
          <IDHFormattedMessage id="ws_clear_selected" defaultMessage="Clear" />
        </Button>
      );
    }

    return (
      <>
        {/* Share Project Button */}
        {projectBasicData?.permissions?.project.includes(
          ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
        ) &&
          projectBasicData?.clientMode &&
          countTaskByShareValue(false) > 0 && (
            <Button
              size="large"
              variant="white"
              onClick={() =>
                dispatch(openGroupShareMultipleColumnsModal(selectedTasks))
              }
              data-action="share"
            >
              <ClientOrangeIcon />
              <IDHFormattedMessage id="ws_share" defaultMessage="Share" />
            </Button>
          )}
        {/* Stop Share Project Button */}
        {projectBasicData?.permissions?.project.includes(
          ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
        ) &&
          projectBasicData?.clientMode &&
          countTaskByShareValue(true) > 0 && (
            <Button
              size="large"
              variant="white"
              onClick={() =>
                dispatch(openStopSharingGroupWithClientModal(selectedTasks))
              }
              data-action="stopShare"
            >
              <ClientStopShareIcon />
              <IDHFormattedMessage
                id="ws_stop_share"
                defaultMessage="Stop share"
              />
            </Button>
          )}
        {/* Generate Showcase Button */}
        {projectBasicData?.permissions?.project.includes(
          ProjectPermissionsEnum.DOWNLOAD_REPORT,
        ) &&
          taskType === TaskType.Creator && (
            <TooltipPortal
              content={
                <IDHFormattedMessage
                  id="ws_cannot_generate_showcase"
                  defaultMessage="Cannot generate showcase for more than 100 creators"
                />
              }
              contentHidden={
                selectedTasks.length <= MAX_CREATORS_FOR_GENERATE_SHOWCASE
              }
              up
            >
              <Button
                size="large"
                variant="white"
                onClick={openShowcase}
                data-action="generateShowcase"
                disabled={
                  selectedTasks.length > MAX_CREATORS_FOR_GENERATE_SHOWCASE
                }
              >
                <ShowcaseIcon />
                <IDHFormattedMessage
                  id="ws_generate_showcase"
                  defaultMessage="Generate showcase"
                />
              </Button>
            </TooltipPortal>
          )}

        <TooltipPortal
          content={
            <IDHFormattedMessage
              id="ws_cannot_send_message"
              defaultMessage="Cannot send message for more than 100 creators"
            />
          }
          contentHidden={
            selectedTasks.length <= MAX_CREATORS_FOR_SENDING_MESSAGE
          }
          up
        >
          <Button
            size="large"
            variant="white"
            onClick={openCreatorMessagePane}
            data-action="openCreatorMessagePane"
            disabled={selectedTasks.length > MAX_CREATORS_FOR_SENDING_MESSAGE}
          >
            <SendIcon />
            <IDHFormattedMessage
              id="ws_send_message"
              defaultMessage="Send Message"
            />
          </Button>
        </TooltipPortal>
        {renderCreatePaymentsButton()}
        {/* Add to Dropdown Component */}
        {taskType === TaskType.Creator &&
          projectBasicData?.permissions?.project?.includes(
            ProjectPermissionsEnum.TASK_MANAGEMENT ||
              identity?.permissions?.workspace?.includes(
                WorkspacePermissionsEnum.CREATOR_DATABASE,
              ),
          ) && (
            <AddToDropdownSelectedTasksBar
              permissions={projectBasicData?.permissions}
              workspacePermissions={identity?.permissions}
              setSelectedCreators={setSelectedCreators}
              setShowModalAddCreatorsToWorkspaceProject={
                setShowModalAddCreatorsToWorkspaceProject
              }
            />
          )}
        {taskType === TaskType.Content && (
          <ContentProposalElementsSummaryDownloadDropdown
            setShowContentDownloadModal={setShowContentDownloadModal}
            wsTaskUuids={selectedTasks?.map(
              (selectedTask) => selectedTask.taskId,
            )}
            wsProjectUuid={projectBasicData?.projectId ?? null}
          >
            <Button variant="white" size="small" textWithIcon>
              <DownloadIcon />
              <span>
                <IDHFormattedMessage
                  id="ws_download"
                  defaultMessage="Download"
                />
              </span>
            </Button>
          </ContentProposalElementsSummaryDownloadDropdown>
        )}
        {/* Delete Selected Button */}
        {tasksLoading ? (
          <Loader />
        ) : (
          projectBasicData?.permissions?.project.includes(
            ProjectPermissionsEnum.TASK_MANAGEMENT,
          ) && (
            <Button
              size="large"
              variant="white"
              onClick={() => setShowDeleteTaskModal(true)}
              disabled={!selectedTasks.length}
              data-action="deleteSelected"
            >
              <DeleteIcon />
              <IDHFormattedMessage
                id="ws_delete_selected"
                defaultMessage="Delete"
              />
            </Button>
          )
        )}
        {/* Clear Selected Button */}
        <Button
          size="large"
          variant="white"
          onClick={clearSelected}
          disabled={!selectedTasks.length}
          data-action="clearSelected"
        >
          <RubberIcon />
          <IDHFormattedMessage id="ws_clear_selected" defaultMessage="Clear" />
        </Button>
        {/* Confirmation and Sharing Modals */}
        {showDeleteTaskModal && (
          <RemoveModal
            onClose={() => setShowDeleteTaskModal(false)}
            objectNames={selectedTasks?.map((obj: any) => obj?.title) ?? []}
            removeFunction={() => {
              deleteSelected();
              setShowDeleteTaskModal(false);
            }}
          />
        )}
        {showShareGroupMultipleColumnsModal && (
          <ShareMultipleColumnsModal
            onClose={() => dispatch(hideShareMultipleColumnsModal())}
            taskAvatarShape={taskAvatarShape.Square}
            tasks={selectedTasks}
            taskType={taskType}
            shouldSelectTasksAfterSharing
          />
        )}
        {showStopSharingGroupWithClientModal && (
          <StopSharingGroupWithClientModal
            onClose={() => dispatch(hideStopSharingWithClientModal())}
            taskAvatarShape={taskAvatarShape.Square}
            tasks={selectedTasks}
            taskType={taskType}
          />
        )}
        {/* Add Creators Modal */}
        {showModalAddCreatorsToWorkspaceProject && (
          <AddCreatorsCDTWorkspaceProjectModal
            onClose={() => setShowModalAddCreatorsToWorkspaceProject(false)}
            activeProjectUuid={projectBasicData?.projectId}
            selectedCreators={selectedCreators}
            openCreateNewProjectModal={
              dispatchDataAndNavigateToCreateNewProject
            }
          />
        )}
        {showContentDownloadModal && (
          <WsContentProposalDownloadModal
            onClose={() => {
              setShowContentDownloadModal(false);
            }}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (selectedFilters?.selections?.length) {
      const filteredTasks = selectedTasks.filter((task) =>
        ifRowMatchesFilters(task, selectedFilters, identity.id),
      );
      dispatch(setSelectedTasks(filteredTasks));
    }
  }, [selectedFilters]);

  return (
    <SelectedTasksBar
      selectedTasksLength={selectedTasks.length}
      isTooltipOpen={isTooltipOpen}
      hideTooltip={hideTooltip}
    >
      {renderSelectedTasksBarContent()}
    </SelectedTasksBar>
  );
}
