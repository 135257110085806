import React, { useEffect, useRef, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import axios from "axios";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { RootState } from "src/redux/reducers";
import { handle402Errors } from "src/redux/main/functions";
import Modal, {
  hideModal,
  ModalColumn,
  ModalRow,
  ModalTitle,
} from "../components/Modal/Modal";
import { generateRankString } from "../../utils/rankStrings";
import { getTaskDetails, getTasksCounters, getTasksList } from "../../redux";
import CustomTextarea from "../components/CustomTextarea/CustomTextarea";
import { API_URLS } from "../../utils/API_URLS";
import { showToast } from "../methods/showToast";
import { TaskType } from "../../types";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "../methods/translateMessage";

interface Props extends WrappedComponentProps<"intl"> {
  onClose: any;
  projectId: string;
  lastTaskRank: string;
  getTasksList: any;
  getTaskDetails: any;
  taskType: string;
  defaultMetaFieldsValues?: {
    key: string;
    taskType: string;
    value: string;
  };
  intl: IntlShape;
}

const AddPublicationModal: React.FC<Props> = (props) => {
  const {
    onClose,
    projectId,
    lastTaskRank,
    taskType,
    defaultMetaFieldsValues,
    getTasksList,
    getTaskDetails,
    intl,
  } = props;

  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const dispatch = useDispatch();

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  useEffect(() => {
    if (textareaRef.current) {
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 150);
    }
  }, [textareaRef.current]);

  const createTask = (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    const publicationUrls = value.split(/[\n]+/);
    let previousRank = lastTaskRank;

    const tasksData = publicationUrls
      .filter((name: string) => name.length)
      .map((name: string) => {
        const newRank = generateRankString(previousRank, "");
        previousRank = newRank;

        return {
          uuid: uuid(),
          name,
          rank: newRank,
        };
      });

    const defaultPublicationData = defaultMetaFieldsValues
      ? [
          {
            key: defaultMetaFieldsValues?.key,
            taskType: defaultMetaFieldsValues?.taskType,
            value: defaultMetaFieldsValues?.value.trim(),
          },
        ]
      : [];

    axios
      .post(API_URLS.createMultipleTasks, {
        wsTaskWorkspaceUuid: activeWorkspaceUuid,
        projectId,
        taskType: TaskType.Publication,
        tasksData,
        defaultMetaFieldsValues: defaultPublicationData,
      })
      .then(() => {
        if (defaultMetaFieldsValues) {
          // Refresh content task publications tab
          getTaskDetails(defaultMetaFieldsValues.value);
        } else if (projectId) {
          dispatch(getTasksCounters(projectId));
          getTasksList(projectId, taskType);
        }

        hideModal(onClose);
      })
      .catch((err) => {
        if (err.response?.data?.errors?.length > 0) {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id={err?.response?.data?.errors[0]?.message}
            />,
          );
        } else if (err?.response.data.statusCode == 402) {
          hideModal(onClose);
          handle402Errors(dispatch, err?.response?.data?.errors);
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_error_on_creating_publication"
              defaultMessage="Error on creating publication"
            />,
          );
        }

        setLoading(false);
      });
  };

  return (
    <Modal
      className="add-task-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={createTask}
      confirmButtonDisabled={!value.trim()}
      confirmButtonText={
        <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
      }
      confirmButtonLoading={loading}
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_add_publication"
          defaultMessage="Add Publication"
        />
      </ModalTitle>
      <form onSubmit={createTask}>
        <ModalRow>
          <ModalColumn>
            <CustomTextarea
              ref={textareaRef}
              id="fieldTitle"
              value={value}
              onChange={(e: any) => setValue(e.target.value.trimStart())}
              placeholder={translateMessage({
                intl,
                id: "ws_provide_publication_names_or_links",
                defaultMessage:
                  "Provide publication name(s) or paste the publication link(s)",
              })}
              autoGrow
            />
          </ModalColumn>
        </ModalRow>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  taskType: state.projectReducer.taskType,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTasksList: (projectId: string, taskType: string) =>
    dispatch(getTasksList(projectId, taskType)),
  getTaskDetails: (taskUuid: string) => dispatch(getTaskDetails(taskUuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPublicationModal));
