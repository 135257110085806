import React, { useEffect, useMemo, useRef } from "react";
import "./SettingsSidebar.scss";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import CustomImage from "src/app/components/CustomImage/CustomImage";
import Skeleton, { SkeletonText } from "src/app/components/Skeleton/Skeleton";
import { Tooltip } from "react-tooltip";
import { RootState } from "src/redux/reducers";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as StackIcon } from "src/images/stack.svg";
import { useCheckFeatureAccess } from "src/app/subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { setPlanUpgradeNeededModalContext } from "src/redux";
import { TabButton, getTabButtons } from "./getTabButtons";
import { UniversalSettingsTab } from "../../SettingsModal";

import ProjectPlaceholder from "../../../../images/project-placeholder.svg";
import TaskPlaceholder from "../../../../images/placeholder-image.svg";
import { SettingsTab } from "../../enums";

const skeletons = Array.from({ length: 2 }).map((_el, i) => (
  <Skeleton key={i} width={217} height={34} borderRadius={6} />
));

interface SettingsSidebarProps {
  data: any;
  tab: UniversalSettingsTab;
  setTab: (tab: UniversalSettingsTab) => void;
  loading: boolean;
}

export default function SettingsSidebar({
  data,
  tab,
  setTab,
  loading,
}: SettingsSidebarProps) {
  const titleRef = useRef<HTMLDivElement>(null);

  const {
    mainReducer: { identity },
  } = useSelector((state: RootState) => state);

  const filteredButtons = useMemo(() => {
    const tabButtons = getTabButtons(identity);

    let buttons: TabButton[] = [];

    if (Array.isArray(data?.tabs)) {
      buttons = data.tabs
        .map((t: { name: SettingsTab; type: string }) => {
          const foundTabButton = tabButtons.find((el) => el.value === t.name);
          return foundTabButton;
        })
        .filter(Boolean);
    }

    return buttons;
  }, [data, identity]);

  useEffect(() => {
    if (
      filteredButtons.length &&
      !filteredButtons.find((item: any) => item?.value === tab)
    ) {
      setTab(filteredButtons[0].value);
    }
  }, [filteredButtons]);

  const getPlaceholderImage = () => {
    switch (data?.context?.type) {
      case "wsProject":
        return ProjectPlaceholder;

      case "wsTask":
        return TaskPlaceholder;

      default:
        return "";
    }
  };

  const buttons = filteredButtons.map((button) => (
    <SidebarTabButton button={button} tab={tab} setTab={setTab} />
  ));

  return (
    <div className="settings-sidebar">
      <div className="settings-sidebar__context">
        {loading ? (
          <>
            <Skeleton width={24} height={24} marginRight={8} borderRadius={2} />
            <SkeletonText width={150} height={16} />
          </>
        ) : (
          <>
            {data?.context?.type !== "wsWorkspace" && (
              <CustomImage
                className={classNames("settings-sidebar__context-image", {
                  "settings-sidebar__context-image--round":
                    data?.context?.type === "wsMember",
                })}
                src={data?.context?.image || getPlaceholderImage}
                alt="image"
              />
            )}

            <div
              className="settings-sidebar__context-title"
              data-tooltip-id="settings-sidebar__context-title-tooltip"
              data-tooltip-place="bottom"
              ref={titleRef}
            >
              {data?.context?.name}
            </div>

            {titleRef.current && titleRef.current.clientWidth >= 169 && (
              <Tooltip
                id="settings-sidebar__context-title-tooltip"
                className="react-tooltip__dark-mode"
              >
                {data?.context?.name}
              </Tooltip>
            )}
          </>
        )}
      </div>

      {loading ? skeletons : buttons}
    </div>
  );
}

interface TabButtonProps {
  button: TabButton;
  tab: UniversalSettingsTab;
  setTab: (tab: UniversalSettingsTab) => void;
}

function SidebarTabButton(props: TabButtonProps) {
  const { button, tab, setTab } = props;

  const dispatch = useDispatch();

  const hasFeatureAccess = useCheckFeatureAccess(button.feature);

  const handleTabChange = () => {
    if (hasFeatureAccess) {
      setTab(button.value);
    }
    if (button.feature) {
      dispatch(setPlanUpgradeNeededModalContext(button.feature));
    }
  };

  return (
    <div
      key={button.value}
      className={classNames("settings-sidebar__item button-style-reset", {
        "settings-sidebar__item--active": button.value === tab,
      })}
      onClick={handleTabChange}
    >
      {button.label}
    </div>
  );
}
