import { StiggClient } from "@stigg/js-client-sdk";
import { Dispatch } from "redux";
import { setSubscriptionPlan } from "src/redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { SubscriptionEntitlements } from "./subscriptionUtils";

export const isEntitlementGranted = (
  stigg: StiggClient,
  featureId: SubscriptionEntitlements,
): boolean => {
  if (!stigg) return false;

  return stigg.getBooleanEntitlement({
    featureId,
  }).hasAccess;
};

export const useCheckFeatureAccess = (
  feature: SubscriptionEntitlements | undefined,
) => {
  const [accessGranted, setAccessGranted] = useState(false);

  const { stiggContextValue } = useSelector(
    (state: RootState) => state.subscriptionReducer,
  );
  const { identity } = useSelector((state: RootState) => state.mainReducer);

  const { stigg, updatedAt } = stiggContextValue || {};
  const { subscriptionData } = identity || {};

  useEffect(() => {
    if (!feature || !subscriptionData) {
      setAccessGranted(true);
      return;
    }

    if ((feature && !updatedAt) || !stigg) {
      setAccessGranted(false);
      return;
    }

    const hasAccess = isEntitlementGranted(stigg, feature);
    setAccessGranted(hasAccess);
  }, [stigg, updatedAt, feature, subscriptionData]);

  return accessGranted;
};

export const getSubscriptionPlanData = async (
  dispatch: Dispatch,
  stigg: StiggClient,
) => {
  if (!stigg) return null;

  try {
    const customer = await stigg.getCustomer();

    const { plan, prices } = customer.subscriptions[0];

    const filteredPrices = prices.filter(
      (item) => item.pricingModel === "PER_UNIT",
    );
    const billingPeriod = filteredPrices[0]?.billingPeriod;
    const features = filteredPrices.reduce(
      (obj, item) =>
        // @ts-ignore
        Object.assign(obj, { [item.feature?.featureId]: item.feature }),
      {},
    );

    if (plan) {
      dispatch(
        setSubscriptionPlan({
          id: plan.id,
          displayName: plan.displayName,
          billingPeriod,
          features,
        }),
      );
    }
  } catch (error) {
    console.error("Error fetching customer:", error);

    return null;
  }
};
