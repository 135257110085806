import React from "react";

import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { ModalColumn } from "src/app/components/Modal/Modal";
import { ExtensionTypeEnum } from "src/types";
import { useDispatch } from "react-redux";
import { Template } from "../types";
import { handleUpdateProjectTemplate } from "../utils";

interface CreateSingleAndSplitPaymentsSettingsProps {
  editedTemplate: Template;
  setEditedTemplate: React.Dispatch<React.SetStateAction<Template | null>>;
  activeWorkspaceUuid: string;
}

function CreateSingleAndSplitPaymentsSettings({
  editedTemplate,
  setEditedTemplate,
  activeWorkspaceUuid,
}: CreateSingleAndSplitPaymentsSettingsProps) {
  const foundData = editedTemplate.extensions.find(
    (extension) =>
      extension.name === ExtensionTypeEnum.CreateSingleAndSplitPayments,
  )?.data;

  const dispatch = useDispatch();

  if (!foundData) return null;

  const handleBlur = () => {
    handleUpdateProjectTemplate({
      data: {
        ...editedTemplate,
        wsWorkspaceUuid: activeWorkspaceUuid,
      },
      dispatch,
    });
  };

  const parseInputValue = (value: string): string | string[] => {
    if (value === "" || value === "[]") {
      return [];
    }

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditedTemplate((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        extensions: prev.extensions.map((extension) => {
          if (
            extension.name === ExtensionTypeEnum.CreateSingleAndSplitPayments
          ) {
            return {
              ...extension,
              data: {
                ...extension.data,
                [name]: parseInputValue(value),
              },
            };
          }
          return extension;
        }),
      };
    });
  };

  return (
    <div className="creator-messenger__general-wrapper">
      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="paymentCreatorAmountGlobalTaskMetaFieldUuid">
            payment Creator Amount Global Task Meta Field Uuid
          </CustomInputLabel>
          <CustomInput
            id="paymentCreatorAmountGlobalTaskMetaFieldUuid"
            name="paymentCreatorAmountGlobalTaskMetaFieldUuid"
            type="text"
            value={foundData.paymentCreatorAmountGlobalTaskMetaFieldUuid}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="paymentAmountPercentGlobalTaskMetaFieldUuid">
            payment Amount Percent Global Task Meta Field Uuid
          </CustomInputLabel>
          <CustomInput
            id="paymentAmountPercentGlobalTaskMetaFieldUuid"
            name="paymentAmountPercentGlobalTaskMetaFieldUuid"
            type="text"
            value={foundData.paymentAmountPercentGlobalTaskMetaFieldUuid}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ModalColumn>
      </div>
      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="paymentAmountGlobalTaskMetaFieldUuid">
            payment Amount Global Task Meta Field Uuid
          </CustomInputLabel>
          <CustomInput
            id="paymentAmountGlobalTaskMetaFieldUuid"
            name="paymentAmountGlobalTaskMetaFieldUuid"
            type="text"
            value={foundData.paymentAmountGlobalTaskMetaFieldUuid}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="paymentDueDateGlobalTaskMetaFieldUuid">
            payment Due Date Global Task Meta Field Uuid
          </CustomInputLabel>
          <CustomInput
            id="paymentDueDateGlobalTaskMetaFieldUuid"
            name="paymentDueDateGlobalTaskMetaFieldUuid"
            type="text"
            value={foundData.paymentDueDateGlobalTaskMetaFieldUuid}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ModalColumn>
      </div>
      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="paymentMetaValues">
            default payment meta value json
          </CustomInputLabel>
          <CustomInput
            id="paymentMetaValues"
            name="paymentMetaValues"
            type="text"
            value={JSON.stringify(foundData.paymentMetaValues)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="creatorRequiredGlobalTaskMetaFieldsUuids">
            creator required global meta fields uuids
          </CustomInputLabel>
          <CustomInput
            id="creatorRequiredGlobalTaskMetaFieldsUuids"
            name="creatorRequiredGlobalTaskMetaFieldsUuids"
            type="text"
            value={JSON.stringify(
              foundData.creatorRequiredGlobalTaskMetaFieldsUuids,
            )}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ModalColumn>
      </div>
      <div className="creator-messenger__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="allowedMetaFieldsValues">
            creator required meta fields values
          </CustomInputLabel>
          <CustomInput
            id="allowedMetaFieldsValues"
            name="allowedMetaFieldsValues"
            type="text"
            value={JSON.stringify(foundData.allowedMetaFieldsValues)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ModalColumn>
      </div>
    </div>
  );
}

export default CreateSingleAndSplitPaymentsSettings;
