import React, { useEffect, useState } from "react";
import axios from "axios";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { openTrialExceededModal } from "src/redux";
import { translateMessage } from "src/app/methods/translateMessage";
import responseCodes from "src/utils/responseCodes";
import { setSubscriptionEntitlementErrors } from "src/redux/main/mainActions";
import { handle402Errors } from "src/redux/main/functions";
import { Button } from "../../components/Button/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import Modal, { ModalTitle } from "../../components/Modal/Modal";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { showToast } from "../../methods/showToast";
import "./DuplicateProjectModal.scss";
import { API_URLS } from "../../../utils/API_URLS";

interface Props extends WrappedComponentProps<"intl"> {
  onClose: () => void;
  wsProjectUuid: string;
  projectName: string;
}

const MIN_LENGTH_PROJECT_NAME = 3;
const DUPLICATE_PROJECT_PREFIX_NAME = " Copy";

const DuplicateProjectModal: React.FC<Props> = (props) => {
  const { onClose, wsProjectUuid, projectName, intl } = props;

  const [newProjectName, setNewProjectName] = useState<string>(
    projectName + DUPLICATE_PROJECT_PREFIX_NAME,
  );
  const [duplicateCreatorTasks, setDuplicateCreatorTasks] =
    useState<boolean>(false);
  const [duplicateContentTasks, setDuplicateContentTasks] =
    useState<boolean>(false);
  const [duplicatePublicationTasks, setDuplicatePublicationTasks] =
    useState<boolean>(false);
  const [duplicateActionTasks, setDuplicateActionTasks] =
    useState<boolean>(false);
  const [duplicatePaymentTasks, setDuplicatePaymentTasks] =
    useState<boolean>(false);
  const [duplicateActionPointTasks, setDuplicateActionPointTasks] =
    useState<boolean>(false);
  const [projectFieldsValues, setProjectFieldsValues] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const updateData = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        API_URLS.duplicateProject.replace(":wsProjectUuid:", wsProjectUuid),
        {
          newProjectName,
          duplicateCreatorTasks,
          duplicateContentTasks,
          duplicatePublicationTasks,
          duplicateActionTasks,
          duplicatePaymentTasks,
          duplicateActionPointTasks,
          projectFieldsValues,
        },
      );
      onClose();
      showToast(
        "success",
        translateMessage({
          intl,
          id: "ws_success",
          defaultMessage: "Success",
        }),
        translateMessage({
          intl,
          id: "ws_duplication_campaign_has_been_queued",
          defaultMessage: "Duplication campaign has been queued.",
        }),
      );
    } catch (err: any) {
      if (
        err?.response.data.statusCode == responseCodes["402_PAYMENT_REQUIRED"]
      ) {
        onClose();
        handle402Errors(dispatch, err?.response?.data?.errors);
      } else {
        showToast(
          "error",
          translateMessage({
            intl,
            id: "ws_error",
            defaultMessage: "Error",
          }),
          translateMessage({
            intl,
            id: "ws_error_on_duplicating_campaign",
            defaultMessage: "Error on duplicating campaign.",
          }),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!duplicateActionTasks) {
      setDuplicateActionPointTasks(false);
    }
  }, [duplicateActionTasks]);

  return (
    <Modal
      className="duplicate-project-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={updateData}
      confirmButtonLoading={loading}
      confirmButtonDisabled={newProjectName.length < MIN_LENGTH_PROJECT_NAME}
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_duplicate_campaign"
          defaultMessage="Duplicate campaign"
        />
      </ModalTitle>

      <form onSubmit={updateData}>
        <div className="duplicate-project-modal__options">
          <div className="duplicate-project-modal__header">
            <IDHFormattedMessage
              id="ws_campaign_name"
              defaultMessage="Campaign name"
            />
          </div>
          <CustomInput
            id="fieldTitle"
            type="text"
            value={newProjectName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewProjectName(e.target.value)
            }
            placeholder={translateMessage({
              intl,
              id: "ws_campaign_name",
              defaultMessage: "Campaign name",
            })}
            fullWidth
          />
        </div>

        <div className="duplicate-project-modal__options">
          <div className="duplicate-project-modal__header">
            <IDHFormattedMessage id="ws_include" defaultMessage="Include" />
          </div>
          <div className="duplicate-project-modal__rows">
            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="projectStructure"
                name="projectStructure"
                label={translateMessage({
                  intl,
                  id: "ws_campaign_structure",
                  defaultMessage: "Campaign structure",
                })}
                disabled
                checked
              />
            </div>
            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="Creators"
                name="creators"
                label={translateMessage({
                  intl,
                  id: "ws_creators",
                  defaultMessage: "Creators",
                })}
                onChange={() => setDuplicateCreatorTasks((prev) => !prev)}
                checked={duplicateCreatorTasks}
              />
            </div>
            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="contents"
                name="contents"
                label={translateMessage({
                  intl,
                  id: "ws_contents",
                  defaultMessage: "Contents",
                })}
                onChange={() => setDuplicateContentTasks((prev) => !prev)}
                checked={duplicateContentTasks}
              />
            </div>
            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="publications"
                name="publications"
                label={translateMessage({
                  intl,
                  id: "ws_publications",
                  defaultMessage: "Publications",
                })}
                onChange={() => setDuplicatePublicationTasks((prev) => !prev)}
                checked={duplicatePublicationTasks}
              />
            </div>
            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="payments"
                name="payments"
                label={translateMessage({
                  intl,
                  id: "ws_payments",
                  defaultMessage: "Payments",
                })}
                onChange={() => setDuplicatePaymentTasks((prev) => !prev)}
                checked={duplicatePaymentTasks}
              />
            </div>
            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="actions"
                name="actions"
                label={translateMessage({
                  intl,
                  id: "ws_actions",
                  defaultMessage: "Actions",
                })}
                onChange={() => setDuplicateActionTasks((prev) => !prev)}
                checked={duplicateActionTasks}
              />
            </div>

            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="actionPoints"
                name="actionPoints"
                label={translateMessage({
                  intl,
                  id: "ws_action_points",
                  defaultMessage: "Action points",
                })}
                onChange={() => setDuplicateActionPointTasks((prev) => !prev)}
                checked={duplicateActionPointTasks}
                disabled={!duplicateActionTasks}
              />
            </div>

            <div className="duplicate-project-modal__row">
              <CustomCheckbox
                id="projectFieldsValues"
                name="projectFieldsValues"
                label={translateMessage({
                  intl,
                  id: "ws_campaign_fields_values",
                  defaultMessage: "Campaign fields values",
                })}
                onChange={() => setProjectFieldsValues((prev) => !prev)}
                checked={projectFieldsValues}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default injectIntl(DuplicateProjectModal);
