import React, { useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import OuterLink from "../components/OuterLink/OuterLink";
import { setPlanUpgradeNeededModalContext, setShowTask } from "../../redux";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";

import "./SidebarItem.scss";
import { MetaFieldType } from "../methods/getMetaFieldTypeOptions";
import {
  isEntitlementGranted,
  useCheckFeatureAccess,
} from "../subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "../subscription/subscriptionUtils";

export interface DictionarySidebarElement {
  type: string;
  wsDictionaryElementName: MetaFieldType;
  wsDictionaryIcon: string;
  wsDictionaryName: string;
  wsDictionaryUrl: string;
  wsDictionaryUuid: string;
}

interface Props {
  to?: string;
  messageId?: string;
  defaultMessage: string;
  overrideHtml?: React.ReactNode;
  outerLink?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  subscriptionEntitlement?: SubscriptionEntitlements;
}

const SidebarItem: React.FC<Props> = ({
  to,
  messageId,
  defaultMessage,
  outerLink,
  children,
  onClick,
  overrideHtml,
  subscriptionEntitlement,
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const entitlementGranted = useCheckFeatureAccess(subscriptionEntitlement);

  if (overrideHtml) {
    return <>{overrideHtml}</>;
  }

  const renderContent = () => {
    if (!entitlementGranted && subscriptionEntitlement) {
      return (
        <span
          className="sidebar__item__span"
          onClick={() =>
            dispatch(setPlanUpgradeNeededModalContext(subscriptionEntitlement))
          }
        >
          {children}
          <span className="sidebar__item-text">
            {messageId ? (
              <IDHFormattedMessage
                id={messageId}
                defaultMessage={defaultMessage}
              />
            ) : (
              defaultMessage
            )}
          </span>
        </span>
      );
    }

    if (outerLink && to) {
      return (
        <OuterLink href={to}>
          {children}
          <span className="sidebar__item-text">
            {messageId ? (
              <IDHFormattedMessage
                id={messageId}
                defaultMessage={defaultMessage}
              />
            ) : (
              defaultMessage
            )}
          </span>
        </OuterLink>
      );
    }

    if (to) {
      return (
        <Link to={to}>
          {children}
          <span className="sidebar__item-text">
            {messageId ? (
              <IDHFormattedMessage
                id={messageId}
                defaultMessage={defaultMessage}
              />
            ) : (
              defaultMessage
            )}
          </span>
        </Link>
      );
    }

    return (
      <span className="sidebar__item__span" onClick={onClick}>
        {children}
        <span className="sidebar__item-text">
          {messageId ? (
            <IDHFormattedMessage
              id={messageId}
              defaultMessage={defaultMessage}
            />
          ) : (
            defaultMessage
          )}
        </span>
      </span>
    );
  };

  return (
    <li
      className={classNames("sidebar__item", {
        "sidebar__item--active": to && pathname.includes(to),
      })}
      onClick={() => dispatch(setShowTask(false))}
    >
      {renderContent()}
    </li>
  );
};

export default SidebarItem;
