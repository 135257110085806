import React, { ReactNode } from "react";

import SubscriptionProvider from "./subscription/SubscriptionProvider";
import TranslationsProvider from "./translations/TranslationsProvider";
import IDHIntercomProvider from "./idh-intercom/IDHIntercomProvider";

interface Props {
  children: ReactNode;
}

function Providers(props: Props) {
  const { children } = props;

  return (
    <IDHIntercomProvider>
      <TranslationsProvider>
        <SubscriptionProvider>{children}</SubscriptionProvider>
      </TranslationsProvider>
    </IDHIntercomProvider>
  );
}

export default Providers;
