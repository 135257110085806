import React, { createRef, useEffect, useState } from "react";
import "./AudiencesResults.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import InfluencerIcon from "src/images/social/universal-user.svg";
import ReachIcon from "src/images/clients-full-grey.svg";
import LazyLoad from "react-lazy-load";
import ImgChecker from "src/app/components/ImgChecker/ImgChecker";
import { showToast } from "src/app/methods/showToast";
import Flag from "src/app/components/Flag/Flag";
import InfiniteScroll from "react-infinite-scroller";
import { cdtScrollRef } from "src/app/pages/CreatorDiscoveryTool/CreatorDiscoveryTool";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import { ReactComponent as SearchIcon } from "src/images/magnifier-white.svg";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import { Button } from "src/app/components/Button/Button";
import EmptyResultIcon from "src/images/empty-audience-result.png";
import errorImg from "src/images/something_went_wrong.svg";
import {
  CDTable,
  CDTableBody,
  CDTableColumn,
  CDTableHead,
  CDTableRow,
} from "../../components/CDTable/CDTable";
import {
  clearSelectedUsers,
  getVelocitySummaryData,
  searchAudience,
  selectInfluencer,
  selectMultipleInfluencer,
} from "../audiencesManagerActions";
import METHODS from "../../helpers/methods";
import VelocitySummaryModal from "../VelocitySummaryModal/VelocitySummaryModal";
import UserInterestList from "../UserInterestList/UserInterestList";
import VelocityValue from "../VelocityValue/VelocityValue";
import InfluencerInfo from "../../components/InfluencerInfo/InfluencerInfo";
import PostPreview from "../../campaign-details/PostPreview/PostPreview";
import PostImagesChecker from "../../components/PostImagesChecker/PostImagesChecker";
import Loader from "../../components/Loader/Loader";
import { generateRelevanceTooltip } from "../../helpers/velocityIndexTooltip";
import AudienceResultLoading from "./AudienceResultLoading/AudienceResultLoading";
import BrandSafetyItem from "../BrandSafety/BrandSafetyItem";
import PendingUsersHandler from "./PendingUsersHandler/PendingUsersHandler";
import Consts from "../../helpers/consts";
import AudiencesResultsCheckboxWrapper from "./components/AudiencesResultsCheckboxWrapper";
import AudiencesResultsCheckbox from "./components/AudiencesResultsCheckbox";

function AudiencesResults(props) {
  const {
    sortBy,
    page,
    searchData,
    getListData,
    activeTab,
    userList,
    infoData,
    similarProfiles,
    selectedInterest,
    searchAudienceFilters,
    selectedInfluencersOnResults,
    selectedInfluencersOnList,
    pendingUsers,
    influencersInListInfo,
    hasAccessToInfluencerInsightsToolVelocityIndex,
    audienceLoading,
    awaitingUsers,
    listSocialProvider,
    listId,
    brandSafetySettings,
    getMoreResults,
    listLoading,
    isAudienceDataError,
    toggleFilters,
  } = props;
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedAllSimilar, setSelectedAllSimilar] = useState(false);
  const [showVelocitySummary, setShowVelocitySummary] = useState(false);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [errorImages, setErrorImages] = useState([]);

  const totalCount = infoData?.totalResults || 0;
  const endOfList =
    userList.length >=
    (activeTab === "results"
      ? Math.min(totalCount, Consts.audienceSearchResultLimit)
      : totalCount);

  useEffect(() => {
    setSelectedInfluencers(
      activeTab === "results"
        ? selectedInfluencersOnResults
        : selectedInfluencersOnList,
    );
  }, [activeTab, selectedInfluencersOnResults, selectedInfluencersOnList]);

  useEffect(() => {
    setErrorImages([]);
  }, [activeTab]);

  useEffect(() => {
    setSelectedAll(false);
    setSelectedAllSimilar(false);

    if (activeTab === "selected") {
      const isAnyNotMatchItem = userList.some(
        (item) => !item.filterMatchSummary?.doesResultMatchAllFilters,
      );

      if (isAnyNotMatchItem) {
        showToast(
          "warning",
          <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />,
          <IDHFormattedMessage
            id="front_some_of_the_creators_dont_match"
            defaultMessage="Some of the Creators don’t match one or more of the specified search criteria."
          />,
        );
      }
    }
  }, [userList]);

  useEffect(() => {
    if (activeTab === "results") {
      if (
        selectedUsersOnPageCount() + preselectedUsersCount() ===
        userList.length
      ) {
        setSelectedAll(true);
      }
      if (
        similarProfiles.length &&
        selectedSimilarProfilesOnPageCount() === similarProfiles.length
      ) {
        setSelectedAllSimilar(true);
      }
    } else {
      if (selectedUsersOnPageCount() === userList.length) {
        setSelectedAll(true);
      }
    }
    if (!selectedUsersOnPageCount()) {
      setSelectedAll(false);
    }
  }, [selectedInfluencers, userList, similarProfiles]);

  const setImageError = (id) => {
    setErrorImages([...errorImages, id]);
  };

  const checkIfWhitelistHasImageWithError = (whitelistId) => {
    return (
      errorImages.filter((errorWhitelistId) => errorWhitelistId === whitelistId)
        .length > 0
    );
  };

  const sortCompareFunction = (a, b, sortByValue) => {
    if (!sortByValue) {
      return 0;
    }

    if (sortByValue === "velocityIndex") {
      if (a.velocityIndex > b.velocityIndex) {
        return -1;
      }
      if (a.velocityIndex < b.velocityIndex) {
        return 1;
      }
      return 0;
    }
    if (sortByValue === "followers") {
      if (parseFloat(a.followersCount) > parseFloat(b.followersCount)) {
        return -1;
      }
      if (parseFloat(a.followersCount) < parseFloat(b.followersCount)) {
        return 1;
      }
      return 0;
    }
    if (sortByValue === "avgEr") {
      if (parseFloat(a.avgErPercent) > parseFloat(b.avgErPercent)) {
        return -1;
      }
      if (parseFloat(a.avgErPercent) < parseFloat(b.avgErPercent)) {
        return 1;
      }
      return 0;
    }
    if (sortByValue === "recentlyAdded") {
      if (new Date(a.createdAt) > new Date(b.createdAt)) {
        return -1;
      }
      if (new Date(a.createdAt) < new Date(b.createdAt)) {
        return 1;
      }
      return 0;
    }
  };

  const onSelectAllClick = async (list, value, setValue) => {
    if (!value) {
      const userIds = [];
      const profileUrls = [];

      if (activeTab === "results") {
        list.forEach((influencer) => {
          if (!checkIfPreselected(influencer.userId)) {
            userIds.push(influencer.userId);
            profileUrls.push(influencer.profileUrl);
          }
        });
      } else {
        list.forEach((influencer) => {
          userIds.push(influencer.userId);
          profileUrls.push(influencer.profileUrl);
        });
      }

      props.selectMultipleInfluencer(userIds, profileUrls, activeTab);
    } else {
      const userIds = [];
      const profileUrls = [];

      if (activeTab === "results") {
        list.forEach((influencer) => {
          if (!checkIfPreselected(influencer.userId)) {
            userIds.push(influencer.userId);
            profileUrls.push(influencer.profileUrl);
          }
        });
      } else {
        list.forEach((influencer) => {
          userIds.push(influencer.userId);
          profileUrls.push(influencer.profileUrl);
        });
      }

      props.clearSelectedUsers(userIds, profileUrls, activeTab);
    }

    setValue(!value);
  };

  const selectUserClick = (userId, profileUrl) => {
    if (activeTab === "results") {
      if (
        selectedUsersOnPageCount() + preselectedUsersCount() ===
        userList.length
      ) {
        setSelectedAll(false);
      }
      // if (
      //   similarProfiles.length &&
      //   selectedSimilarProfilesOnPageCount() === similarProfiles.length
      // ) {
      //   setSelectedAllSimilar(false);
      // }
    } else {
      if (selectedUsersOnPageCount() === userList.length) {
        setSelectedAll(false);
      }
    }

    props.selectInfluencer(userId, profileUrl, activeTab);
  };

  const preselectedUsersCount = () => {
    return userList.filter((user) => {
      return checkIfPreselected(user.userId);
    }).length;
  };

  const selectedUsersOnPageCount = () => {
    return userList.filter((user) => {
      return selectedInfluencers?.indexOf(user.userId) > -1;
    }).length;
  };

  const selectedSimilarProfilesOnPageCount = () => {
    return similarProfiles?.filter((user) => {
      return selectedInfluencers?.indexOf(user.userId) > -1;
    }).length;
  };

  const checkIfPreselected = (userId) => {
    return influencersInListInfo?.userIds?.indexOf(userId) > -1;
  };

  const openVelocitySummary = (userId) => {
    props.getVelocitySummaryData(userId, searchData);
    setShowVelocitySummary(true);
  };

  const closeVelocitySummary = () => {
    setShowVelocitySummary(false);
  };

  const getColSpanNumber = () => {
    if (
      searchData?.influencerSocialProvider.value === "instagram" &&
      brandSafetySettings.keywords?.length > 0 &&
      activeTab === "selected"
    ) {
      return 6;
    }
    if (
      searchData?.influencerSocialProvider.value === "instagram" ||
      (searchData?.influencerSocialProvider.value === "tik_tok" &&
        brandSafetySettings.keywords?.length > 0 &&
        activeTab === "selected")
    ) {
      return 5;
    }
    return 4;
  };

  const getColumns = (list, checked, setChecked) => [
    list.length > 0
      ? {
          id: "front_select_page",
          defaultMessage: "Select page",
          empty: true,
          selectAll: {
            checked,
            onChange: () => onSelectAllClick(list, checked, setChecked),
            disabled:
              activeTab === "results" &&
              !list.filter((user) => {
                return !checkIfPreselected(user.userId);
              }).length,
          },
        }
      : { id: "front_select_page", empty: true },
    {
      id: "preview",
      empty: true,
      className: "cdt-table__image cdt-table__image--small",
    },
    {
      id: "front_username",
      defaultMessage: "Username",
      toLeft: true,
    },
    searchData?.influencerSocialProvider.value === "instagram"
      ? {
          id: "front_interests",
          defaultMessage: "Interests",
        }
      : null,
    {
      id: "front_recent_posts",
      defaultMessage: "Recent posts",
    },
    brandSafetySettings.keywords?.length > 0 &&
    influencersInListInfo?.totalResults <= Consts.audienceListPageSize &&
    activeTab === "selected"
      ? {
          id: "front_competitors_post_check",
          className: "competitors-post-check-column",
          defaultMessage: "Competitors Post Check",
        }
      : null,
    !Consts.socialProvidersWithDisabledAvgER.includes(
      searchData?.influencerSocialProvider?.value,
    )
      ? {
          id: "front_avg_er",
          defaultMessage: "avg. ER",
          tooltip: {
            id: "front_avg_er_tooltip",
            defaultMessage: "avg. ER tooltip",
          },
        }
      : null,
    !Consts.socialProvidersWithDisabledRelevance.includes(
      searchData?.influencerSocialProvider?.value,
    )
      ? {
          id: "front_relevance",
          defaultMessage: "relevance",
          tooltip: generateRelevanceTooltip(
            searchData?.influencerSocialProvider.value,
          ),
        }
      : null,
  ];

  const renderList = (list) => {
    return list
      .sort((a, b) =>
        sortCompareFunction(
          a,
          b,
          activeTab === "selected" ? sortBy?.value : null,
        ),
      )
      .map((influencer) => {
        const headerRef = createRef(null);
        const isPreselected =
          activeTab === "results" && checkIfPreselected(influencer.userId);

        return (
          <CDTableRow key={influencer.userId}>
            <CDTableColumn className="cdt-table__checkbox">
              <AudiencesResultsCheckboxWrapper isPreselected={isPreselected}>
                <AudiencesResultsCheckbox
                  influencer={influencer}
                  selectedInfluencers={selectedInfluencers}
                  selectUserClick={selectUserClick}
                  isPreselected={isPreselected}
                />
              </AudiencesResultsCheckboxWrapper>
            </CDTableColumn>
            <CDTableColumn>
              <LazyLoad offset={300}>
                <div className="influencer-photo">
                  <div
                    className={classNames(
                      "influencer-photo__img",
                      "cdt-table__image-content",
                      "cdt-table__image",
                      {
                        "cdt-table__image--error":
                          checkIfWhitelistHasImageWithError(influencer.userId),
                      },
                    )}
                    onClick={() => headerRef.current.click()}
                    style={{
                      backgroundImage: `url(${influencer.profilePictureUrl})`,
                    }}
                  />
                  <ImgChecker
                    src={influencer.profilePictureUrl}
                    onError={() => setImageError(influencer.userId)}
                  />
                  {influencer.country && (
                    <Flag countryCode={influencer.country} />
                  )}
                </div>
              </LazyLoad>
            </CDTableColumn>
            <CDTableColumn>
              <InfluencerInfo
                socialProvider={influencer.socialProvider}
                socialProviders={influencer.socialProviders}
                username={influencer.socialUsername}
                headerRef={headerRef}
                wsGlobalTaskUuid={influencer?.wsGlobalTaskUuid}
                influencerName={influencer?.name}
              />
            </CDTableColumn>
            {searchData?.influencerSocialProvider.value === "instagram" && (
              <CDTableColumn>
                <UserInterestList
                  interestList={influencer.audienceInterests}
                  selectedInterest={selectedInterest}
                  visibleLimit={3}
                />
              </CDTableColumn>
            )}
            <CDTableColumn>
              <LazyLoad>
                <div className="audience-results__recent-posts">
                  <PostImagesChecker
                    data={influencer.lastPosts || []}
                    socialProfileUrl={influencer.profileUrl}
                    socialProvider={influencer.socialProvider}
                    render={(item, index) => (
                      <PostPreview
                        key={index}
                        imageUrl={item.thumbnailSrc}
                        preview={[
                          {
                            mediaUrl: item.imgSrc,
                            type: "image",
                            thumbnailMediaUrl: item.thumbnailSrc,
                          },
                        ]}
                        postUrl={item.postUrl}
                        socialProvider={influencer.socialProvider}
                        isAudienceManager
                      />
                    )}
                  />
                </div>
              </LazyLoad>
            </CDTableColumn>
            {brandSafetySettings?.keywords?.length > 0 &&
            activeTab === "selected" ? (
              <CDTableColumn className="cdt-table__brand-safety">
                <BrandSafetyItem
                  status={influencer.competitorsCheckData.brandSafetyStatus}
                  competitorsPostDetected={
                    influencer.competitorsCheckData.brandSafetyStatus !==
                      "Pending" &&
                    Object.keys(influencer.competitorsCheckData.keywords || {})
                      .length > 0
                  }
                  keywords={influencer.competitorsCheckData.keywords}
                  username={influencer.socialUsername}
                  count={influencer.competitorsCheckData.count}
                />
              </CDTableColumn>
            ) : null}
            {!Consts.socialProvidersWithDisabledAvgER.includes(
              searchData?.influencerSocialProvider?.value,
            ) && (
              <CDTableColumn className="cdt-table__number cdt-table__number--medium">
                {Number(influencer.avgErPercent)
                  ? `${influencer.avgErPercent}%`
                  : "-"}
              </CDTableColumn>
            )}

            <CDTableColumn className="cdt-table__number cdt-table__number--medium cdt-table__number--light">
              {!Consts.socialProvidersWithDisabledRelevance.includes(
                searchData?.influencerSocialProvider?.value,
              ) && (
                <VelocityValue
                  value={influencer.velocityIndex}
                  onClick={
                    hasAccessToInfluencerInsightsToolVelocityIndex
                      ? () =>
                          influencer.velocityIndex !== null
                            ? openVelocitySummary(influencer.userId)
                            : null
                      : null
                  }
                  filterMatchSummary={influencer.filterMatchSummary}
                  isVelocitySummaryModalOpen={showVelocitySummary}
                />
              )}
            </CDTableColumn>
          </CDTableRow>
        );
      });
  };

  const renderSimilarProfiles = () => {
    if (similarProfiles.length && activeTab === "results") {
      return (
        <>
          <div className="audience-results-page__similar-profile-tabs">
            <div className="audience-results-page__tabs">
              <Tabs>
                <Tab
                  tabText={
                    <IDHFormattedMessage
                      id="front_similar_profiles"
                      defaultMessage="Similar profiles"
                    />
                  }
                  count={similarProfiles.length}
                  active
                  big
                  noClickable
                />
              </Tabs>
            </div>
          </div>

          <CDTableHead
            columns={getColumns(
              similarProfiles,
              selectedAllSimilar,
              setSelectedAllSimilar,
            )}
          />
          <CDTableBody>{renderList(similarProfiles)}</CDTableBody>
        </>
      );
    }

    return null;
  };

  const renderTable = () => {
    return (
      <CDTable
        columns={getColumns(userList, selectedAll, setSelectedAll)}
        autoLayout
        showNoResults={
          activeTab === "selected"
            ? userList.length + awaitingUsers.length === 0
            : userList.length === 0
        }
        customNoResults={
          activeTab === "selected" ? (
            <div className="empty-influencer-list">
              <div className="empty-influencer-list__left-side">
                <IDHFormattedMessage
                  id="front_creator_list_is_empty"
                  defaultMessage="Creator list is empty. <br>
                  Use Creator Search to find and add creators to the list."
                />

                <div>
                  <Button
                    variant="blue"
                    size="big"
                    textWithIcon
                    onClick={() => props.tabChange("results")}
                  >
                    <SearchIcon width={20} height={20} />
                    <IDHFormattedMessage
                      id="front_go_to_search_reasults"
                      defaultMessage="Go to search results"
                    />
                  </Button>
                </div>
              </div>
              <div className="empty-influencer-list__right-side">
                <img src={EmptyResultIcon} className="" />
              </div>
            </div>
          ) : isAudienceDataError ? (
            <div className="no-results-screen-wrapper">
              <NoResultsScreen
                title={
                  <IDHFormattedMessage
                    id="front_audience_results_data_error_title"
                    defaultMessage="Oops. Something went wrong..."
                  />
                }
                subtitle={
                  <IDHFormattedMessage
                    id="front_audience_results_data_error_description"
                    defaultMessage="An unexpected error occurred. Please try again."
                  />
                }
                bottomContent={
                  <Button size="large" variant="blue" onClick={toggleFilters}>
                    <IDHFormattedMessage
                      id="front_open_filters"
                      defaultMessage="Open filters"
                    />
                  </Button>
                }
                imgUrl={errorImg}
              />
            </div>
          ) : (
            <PendingUsersHandler
              influencerSocialUsername={
                searchAudienceFilters.influencerSocialUsername
              }
              getListData={getListData}
              pendingUsers={pendingUsers}
            />
          )
        }
      >
        <CDTableBody>
          {activeTab === "selected" &&
            awaitingUsers.map((user, index) => (
              <AudienceResultLoading
                key={user.socialUsername}
                username={user.socialUsername}
                showAsNonExistent={user.showAsNonExistent}
                status={user.status}
                id={user.id}
                listId={listId}
                listSocialProvider={listSocialProvider}
                competitorsCheckEnabled={
                  Object.keys(brandSafetySettings.keywords).length > 0 &&
                  activeTab === "selected"
                }
              />
            ))}
          {renderList(userList)}

          {userList.length > 0 && activeTab === "selected" && endOfList && (
            <CDTableRow>
              <CDTableColumn colSpan={getColSpanNumber()} className="to-left">
                <div className="audience-results__summary">
                  <div className="audience-results__summary-column">
                    <div>
                      {activeTab === "results" ? (
                        <IDHFormattedMessage
                          id="front_total_influencers_found"
                          defaultMessage="Total influencers found"
                        />
                      ) : (
                        <IDHFormattedMessage
                          id="front_total_influencers"
                          defaultMessage="Total influencers"
                        />
                      )}
                    </div>
                    <div>
                      <IDHFormattedMessage
                        id="front_total_potential_reach"
                        defaultMessage="Total potential reach"
                      />
                    </div>
                  </div>
                  <div className="audience-results__summary-column">
                    <div>
                      <img src={InfluencerIcon} />
                      {infoData.totalResults >= 10000
                        ? "9999+"
                        : METHODS.splitNumbers(infoData.totalResults)}
                    </div>
                    <div>
                      <img src={ReachIcon} />
                      {infoData.totalPotentialReach > 0
                        ? METHODS.splitNumbers(infoData.totalPotentialReach)
                        : "-"}
                    </div>
                  </div>
                </div>

                {activeTab === "results" && (
                  <div className="audience-results__legend">
                    <IDHFormattedMessage
                      id="front_audience_results_legend"
                      defaultMessage="* Due to technical and usability reasons, the number of simultaneously returned results is limited to 160"
                    />
                  </div>
                )}
              </CDTableColumn>
              {!Consts.socialProvidersWithDisabledAvgER.includes(
                searchData?.influencerSocialProvider?.value,
              ) && (
                <CDTableColumn className="cdt-table__number cdt-table__number--medium">
                  {Number(infoData.totalAvgErPercent)
                    ? `${infoData.totalAvgErPercent}%`
                    : "-"}
                </CDTableColumn>
              )}
              <CDTableColumn className="cdt-table__number cdt-table__number--medium cdt-table__number--light">
                {!Consts.socialProvidersWithDisabledRelevance.includes(
                  searchData?.influencerSocialProvider?.value,
                ) && <VelocityValue value={infoData.totalAvgVelocityIndex} />}
              </CDTableColumn>
            </CDTableRow>
          )}
        </CDTableBody>

        {renderSimilarProfiles()}
      </CDTable>
    );
  };

  const renderContent = () => {
    if (activeTab === "results") {
      return (
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={() => getMoreResults(endOfList)}
          className="audience-results__scroller"
          hasMore={!endOfList && getMoreResults}
          loader={<Loader />}
          useWindow={false}
          getScrollParent={() => cdtScrollRef.current}
        >
          {renderTable()}
        </InfiniteScroll>
      );
    }

    return renderTable();
  };

  if (audienceLoading && !listLoading) {
    return <Loader />;
  }
  return (
    <div className="audience-results">
      {renderContent()}
      {showVelocitySummary && (
        <VelocitySummaryModal onClose={closeVelocitySummary} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  activeWorkspaceUuid: state.mainReducer.activeWorkspaceUuid,
  searchAudienceFilters: state.audiencesManagerReducer.searchAudienceFilters,
  selectedInfluencersOnResults:
    state.audiencesManagerReducer.selectedInfluencersOnResults,
  selectedInfluencersOnList:
    state.audiencesManagerReducer.selectedInfluencersOnList,
  pendingUsers: state.audiencesManagerReducer.pendingUsers,
  influencersInListInfo: state.audiencesManagerReducer.influencersInListInfo,

  hasAccessToInfluencerInsightsToolVelocityIndex:
    state.audiencesManagerReducer
      .hasAccessToInfluencerInsightsToolVelocityIndex,
  audienceLoading: state.audiencesManagerReducer.audienceLoading,
  awaitingUsers: state.audiencesManagerReducer.awaitingUsers,
  listSocialProvider: state.audiencesManagerReducer.listSocialProvider,
  brandSafetySettings: state.audiencesManagerReducer.brandSafetySettings,
  isAudienceDataError: state.audiencesManagerReducer.isAudienceDataError,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchAudience: (searchData, page, sortBy) =>
      dispatch(searchAudience(searchData, page, sortBy)),
    getVelocitySummaryData: (userId, searchData) =>
      dispatch(getVelocitySummaryData(userId, searchData)),
    selectInfluencer: (userId, profileUrl, type) =>
      dispatch(selectInfluencer(userId, profileUrl, type)),
    selectMultipleInfluencer: (userIds, profileUrls, type) =>
      dispatch(selectMultipleInfluencer(userIds, profileUrls, type)),
    clearSelectedUsers: (userIds, profileUrls, type) =>
      dispatch(clearSelectedUsers(userIds, profileUrls, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AudiencesResults));
