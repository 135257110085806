import React, {
  ChangeEvent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import { TextBoxWrapper } from "src/app/components/TextBoxWrapper/TextBoxWrapper";
import { ModalColumn } from "src/app/components/Modal/Modal";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import { ExtensionTypeEnum } from "src/types";
import ExtensionsListItemContent from "../../Extensions/components/ExtensionsList/ExtensionsListItemContent";
import { Template } from "../types";
import {
  getUpdatedValuesArray,
  handleUpdateProjectTemplate,
  projectTemplateAutomations,
  projectTemplateExtensions,
} from "../utils";
import AutomationsEnum from "../../Automations/AutomationsEnum";
import CreateSingleAndSplitPaymentsSettings from "./CreateSingleAndSplitPaymentsSettings";

interface TemplateManagerGeneralTabProps {
  editedTemplate: Template;
  setEditedTemplate: Dispatch<SetStateAction<Template | null>>;
  syncLocalTemplateWithServer: (uuid: string) => Promise<void>;
}

function TemplateManagerGeneralTab({
  editedTemplate,
  setEditedTemplate,
  syncLocalTemplateWithServer,
}: TemplateManagerGeneralTabProps) {
  const [description, setDescription] = useState(editedTemplate.description);

  const dispatch = useDispatch();

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const handleUpdateDescription = (newDescription: string) => {
    const descriptionTrimmed = newDescription.trim();
    if (descriptionTrimmed !== editedTemplate.description) {
      setEditedTemplate((prev) => {
        if (!prev) return null;

        handleUpdateProjectTemplate({
          data: {
            ...prev,
            wsWorkspaceUuid: activeWorkspaceUuid,
            description: descriptionTrimmed,
          },
          catchCallback: () => syncLocalTemplateWithServer(editedTemplate.uuid),
          dispatch,
        });

        return { ...prev, description: descriptionTrimmed };
      });
    }
  };

  const handleSwitch = (
    e: ChangeEvent<HTMLInputElement>,
    key: "automations" | "extensions",
  ) => {
    const { checked, name } = e.target;
    const automationOrExtensionName = name as
      | AutomationsEnum
      | ExtensionTypeEnum;
    const templateBeforeUpdating = structuredClone(editedTemplate);

    setEditedTemplate((prev) => {
      if (!prev) return null;

      const updatedValues = getUpdatedValuesArray(
        checked,
        key,
        prev,
        automationOrExtensionName,
      );

      return {
        ...prev,
        [key]: updatedValues,
      };
    });

    handleUpdateProjectTemplate({
      data: {
        ...templateBeforeUpdating,
        wsWorkspaceUuid: activeWorkspaceUuid,
        [key]: getUpdatedValuesArray(
          checked,
          key,
          templateBeforeUpdating,
          automationOrExtensionName,
        ),
      },
      catchCallback: () => setEditedTemplate(templateBeforeUpdating),
      dispatch,
    });
  };

  useEffect(() => {
    setDescription(editedTemplate.description);
  }, [editedTemplate.description]);

  return (
    <div className="template-manager__content-wrapper">
      <ModalColumn>
        <CustomInputLabel>
          <IDHFormattedMessage
            id="ws_description"
            defaultMessage="Description"
          />
        </CustomInputLabel>

        <TextBoxWrapper
          className="ws-input template-manager__text-area"
          value={description}
          onChange={setDescription}
          onBlur={handleUpdateDescription}
        />
      </ModalColumn>

      <div className="template-manager__section-label">
        <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
      </div>

      <div className="template-manager__extensions">
        {projectTemplateExtensions.map((extension) => {
          const isExtensionChecked = Boolean(
            editedTemplate.extensions.find(
              (item) => item.name === extension.key,
            )?.enabled,
          );

          return (
            <ExtensionsListItemContent
              key={extension.key}
              name={extension.name}
              description={extension.description}
              controls={
                <CustomSwitch
                  name={extension.key}
                  onChange={(e) => handleSwitch(e, "extensions")}
                  checked={isExtensionChecked}
                />
              }
              extraContent={
                extension.key ===
                  ExtensionTypeEnum.CreateSingleAndSplitPayments &&
                isExtensionChecked ? (
                  <CreateSingleAndSplitPaymentsSettings
                    editedTemplate={editedTemplate}
                    setEditedTemplate={setEditedTemplate}
                    activeWorkspaceUuid={activeWorkspaceUuid}
                  />
                ) : null
              }
            />
          );
        })}
      </div>

      <div className="template-manager__section-label">
        <IDHFormattedMessage id="ws_automations" defaultMessage="Automations" />
      </div>

      <div className="template-manager__extensions">
        {projectTemplateAutomations.map((automation) => {
          const isAutomationEnabled = Boolean(
            editedTemplate.automations.find(
              (item) => item.name === automation.key,
            )?.enabled,
          );

          return (
            <ExtensionsListItemContent
              key={automation.key}
              name={automation.name}
              controls={
                <CustomSwitch
                  name={automation.key}
                  onChange={(e) => handleSwitch(e, "automations")}
                  checked={isAutomationEnabled}
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export default TemplateManagerGeneralTab;
