import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import IdhLogo from "../../../images/idh-logo-color.svg";
import AvatarIcon from "../../../images/trial/avatar.png";
import RatingIcon from "../../../images/trial/rating.png";
import Companies1Icon from "../../../images/trial/companies_1.png";
import Companies2Icon from "../../../images/trial/companies_2.png";

function TrialInfoContent({ showForm, planName }) {
  const renderHeader = () => {
    switch (planName) {
      case "lite":
        return (
          <>
            <img src={IdhLogo} className="trial-view-content-left__logo" />
            <div className="trial-view-content-left__header">
              <IDHFormattedMessage
                id="ws_get_started_for_free"
                defaultMessage="Get started for free!"
              />
            </div>
            <div className="trial-view-content-left__how-works-title">
              <IDHFormattedMessage
                id="ws_trial_how_it_works"
                defaultMessage="How it works?"
              />
            </div>
            <div className="trial-view-content-left__how-works-points">
              <IDHFormattedMessage
                id="ws_trial_how_it_works_points_free"
                defaultMessage="1. Fill the form.
  2. Schedule a meeting where I'll guide you through our platform.
  3. Receive a 7-day trial period."
              />
            </div>

            <div className="trial-view-content-left__author">
              <img
                src={AvatarIcon}
                className="trial-view-content-left__author-logo"
              />
              <div className="trial-view-content-left__author-text">
                <IDHFormattedMessage
                  id="ws_trial_how_it_works_author"
                  defaultMessage="Tomasz Zembrzycki"
                />
                <div className="trial-view-content-left__author-text-description">
                  <IDHFormattedMessage
                    id="ws_trial_how_it_works_author_description"
                    defaultMessage="Head of SaaS"
                  />
                </div>
              </div>
            </div>
          </>
        );

      case "launch":
        return (
          <>
            <div className="trial-view-content-left__header">
              <IDHFormattedMessage
                id="ws_get_started_with_indahash"
                defaultMessage="Get started with indaHash!"
              />
            </div>
            <div className="trial-view-content-left__how-works-title">
              <IDHFormattedMessage
                id="ws_trial_how_it_works"
                defaultMessage="How it works?"
              />
            </div>
            <div className="trial-view-content-left__how-works-points">
              <IDHFormattedMessage
                id="ws_trial_how_it_works_points_paid"
                defaultMessage="1. Fill the form.
  2. Schedule a meeting where I'll guide you through our platform.
  3. Receive a 7-day trial period."
              />
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return showForm ? (
    <div className="trial-view-content-left">
      {renderHeader()}

      {/* VER A

      <div className="trial-view-content-left__ratings">
        <img
          src={RatingIcon}
          className="trial-view-content-left__ratings-icon"
        />
        <div className="trial-view-content-left__ratings-text">
          <IDHFormattedMessage
            id="ws_trial_rating_comment"
            defaultMessage="“User friendly platform, with wealth of data and great account management”"
          />
        </div>
      </div>

    VER B
      <div className="trial-view-content-left__companies">
        <IDHFormattedMessage
          id="ws_trial_tusted_companies"
          defaultMessage="Trusted by 600+ companies"
        />
        <img
          src={Companies1Icon}
          className="trial-view-content-left__author-logo"
        />
        <img
          src={Companies2Icon}
          className="trial-view-content-left__author-logo"
        />
      </div>
      */}

      <div className="trial-view-content-left__ratings">
        <img
          src={RatingIcon}
          className="trial-view-content-left__ratings-icon"
        />
        <div className="trial-view-content-left__ratings-text">
          <IDHFormattedMessage
            id="ws_trial_rating_comment"
            defaultMessage="“User friendly platform, with wealth of data and great account management”"
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export { TrialInfoContent };
