import { combineReducers } from "redux";
import audiencesManagerReducer from "src/app/pages/CreatorDiscoveryTool/legacy/app/audiences-manager/audiencesManagerReducer";
import mainReducer, { MainReducerState } from "./main/mainReducer";
import projectReducer, { ProjectReducerState } from "./project/projectReducer";
import taskReducer, { TaskReducerState } from "./task/taskReducer";
import commentReducer, { CommentReducerState } from "./comment/commentReducer";
import { AttachmentReducerState } from "./attachment/types";
import taskFiltersReducer, {
  TaskFiltersReducerState,
} from "./taskFilters/taskFiltersReducer";
import projectFiltersReducer from "./projectFilters/projectFiltersReducer";
import showcaseReducer from "./creator-showcase/showcaseReducer";
import reportReducer from "./report-generator/reportReducer";
import automationReducer, {
  AutomationReducerState,
} from "./automation/automationReducer";
import activityReducer from "./activity/activityReducer";
import globalModalsReducer, {
  GlobalModalsReducerState,
} from "./global-modals/globalModalsReducer";
import settingsReducer, {
  SettingsReducerState,
} from "./settings/settingsReducer";
import extensionReducer, {
  ExtensionReducerState,
} from "./extension/extensionReducer";
import couponReducer from "./coupon/couponReducer";
import creatorDatabaseReducer, {
  CreatorDatabaseReducerState,
} from "./creator-database/creatorDatabaseReducer";
import dictionaryReducer, {
  DictionaryReducerState,
} from "./dictionary/dictionaryReducer";
import selectDataSetReducer, {
  SelectDataSetReducerState,
} from "./select-data-set/selectDataSetReducer";
import attachmentReducer from "./attachment/attachmentReducer";
import workspaceTaskFiltersReducer, {
  WorkspaceTaskFiltersReducerState,
} from "./workspaceTaskFilters/workspaceTaskFiltersReducer";
import subscriptionReducer, {
  SubscriptionReducerState,
} from "./subscription/subscriptionReducer";

export interface RootState {
  mainReducer: MainReducerState;
  projectReducer: ProjectReducerState;
  taskReducer: TaskReducerState;
  projectFiltersReducer: any;
  taskFiltersReducer: TaskFiltersReducerState;
  commentReducer: CommentReducerState;
  attachmentReducer: AttachmentReducerState;
  showcaseReducer: any;
  reportReducer: any;
  automationReducer: AutomationReducerState;
  activityReducer: any;
  globalModalsReducer: GlobalModalsReducerState;
  audiencesManagerReducer: any;
  settingsReducer: SettingsReducerState;
  extensionReducer: ExtensionReducerState;
  couponReducer: any;
  creatorDatabaseReducer: CreatorDatabaseReducerState;
  dictionaryReducer: DictionaryReducerState;
  selectDataSetReducer: SelectDataSetReducerState;
  workspaceTaskFiltersReducer: WorkspaceTaskFiltersReducerState;
  subscriptionReducer: SubscriptionReducerState;
}

export default combineReducers({
  mainReducer,
  projectReducer,
  taskReducer,
  projectFiltersReducer,
  taskFiltersReducer,
  commentReducer,
  attachmentReducer,
  showcaseReducer,
  reportReducer,
  automationReducer,
  activityReducer,
  globalModalsReducer,
  audiencesManagerReducer,
  settingsReducer,
  extensionReducer,
  couponReducer,
  creatorDatabaseReducer,
  dictionaryReducer,
  selectDataSetReducer,
  workspaceTaskFiltersReducer,
  subscriptionReducer,
});
