import React from "react";
import "./SubscriptionDropdown.scss";

import { useHistory } from "react-router";
import { Button } from "src/app/components/Button/Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { openSettingsModal } from "src/app/SettingsModal/methods";
import { AppLocation } from "src/redux/main/mainReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { ReactComponent as CardIcon } from "src/images/credit-card.svg";
import { ReactComponent as SpeedometerIcon } from "src/images/speedometer.svg";
import { ReactComponent as BuoyIcon } from "src/images/buoy.svg";
import {
  setSubscriptionContactSupportModalVisibility,
  setSubscriptionUsageModalMode,
} from "src/redux";
import { SubscriptionUsageModalMode } from "../../modals/SubscriptionUsageModal/SubscriptionUsageModal";

function SubscriptionDropdown() {
  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );
  const subscriptionPlan = useSelector(
    (state: RootState) => state.subscriptionReducer.subscriptionPlan,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const openSubscriptionOnSettings = () => {
    openSettingsModal(
      history,
      AppLocation.Navbar,
      SettingsContext.Workspace,
      SettingsTab.Subscription,
    );
  };

  const showSubscriptionButton = identity?.permissions?.workspace?.includes(
    "subscription_management",
  );

  return (
    <Dropdown toRight>
      {(isOpen, setIsOpen) => (
        <>
          <span className="subscription-dropdown__title">
            <IDHFormattedMessage id="ws_plan" defaultMessage="Plan" />:{" "}
            <Button
              className="subscription-dropdown__button"
              size="thin"
              variant="grey"
              onClick={() => setIsOpen(true)}
            >
              {subscriptionPlan?.displayName}
            </Button>
          </span>
          <DropdownMenu isOpen={isOpen}>
            {showSubscriptionButton && (
              <DropdownMenuItem onClick={openSubscriptionOnSettings}>
                <CardIcon />
                <IDHFormattedMessage
                  id="ws_subscription"
                  defaultMessage="Subscription"
                />
              </DropdownMenuItem>
            )}

            <DropdownMenuItem
              onClick={() =>
                dispatch(
                  setSubscriptionUsageModalMode(
                    SubscriptionUsageModalMode.Info,
                  ),
                )
              }
            >
              <SpeedometerIcon />
              <IDHFormattedMessage id="ws_usage" defaultMessage="Usage" />
            </DropdownMenuItem>

            <DropdownMenuItem
              onClick={() =>
                dispatch(setSubscriptionContactSupportModalVisibility(true))
              }
            >
              <BuoyIcon />
              <IDHFormattedMessage id="ws_support" defaultMessage="Support" />
            </DropdownMenuItem>
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
}

export default SubscriptionDropdown;
