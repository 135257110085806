import { Dispatch } from "redux";
import { openTrialExceededModal } from "../global-modals/globalModalsActions";
import { setSubscriptionEntitlementErrors } from "./mainActions";
import {
  SubscriptionEntitlementError,
  SubscriptionEntitlementErrorTypes,
} from "./mainReducer";
import { setPlanUpgradeNeededModalContext } from "../subscription/subscriptionActions";

export const handle402Errors = (
  dispatch: Dispatch,
  errors: SubscriptionEntitlementError[],
) => {
  const { field, type } = errors[0];

  if (type === SubscriptionEntitlementErrorTypes.EntitlementUsageExceeded) {
    dispatch(openTrialExceededModal());
  } else if (
    type === SubscriptionEntitlementErrorTypes.EntitlementAccessNotGranted
  ) {
    // @ts-ignore
    dispatch(setPlanUpgradeNeededModalContext(field));
  }

  dispatch(setSubscriptionEntitlementErrors(errors));
};
