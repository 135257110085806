import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import {
  OPEN_TRIAL_EXCEEDED_MODAL,
  CLOSE_TRIAL_EXCEEDED_MODAL,
  OPEN_TRIAL_LIMITATIONS_MODAL,
  CLOSE_TRIAL_LIMITATIONS_MODAL,
  OPEN_SUBSCRIPTION_UPGRADE_NEEDED,
  CLOSE_SUBSCRIPTION_UPGRADE_NEEDED,
} from "./globalModalsTypes";

export interface GlobalModalsReducerState {
  trialExceededModalVisible: boolean;
  trialLimitationsModalVisible: boolean;
  subscriptionUpgradeNeededModalVisible: boolean;
}

const initialState: GlobalModalsReducerState = {
  trialExceededModalVisible: false,
  trialLimitationsModalVisible: true,
  subscriptionUpgradeNeededModalVisible: false,
};

function globalModalsReducer(state = initialState, action: any) {
  switch (action.type) {
    case OPEN_TRIAL_EXCEEDED_MODAL:
      return {
        ...state,
        trialExceededModalVisible: true,
      };

    case CLOSE_TRIAL_EXCEEDED_MODAL:
      return {
        ...state,
        trialExceededModalVisible: false,
      };
    case OPEN_TRIAL_LIMITATIONS_MODAL:
      return {
        ...state,
        trialLimitationsModalVisible: true,
      };

    case CLOSE_TRIAL_LIMITATIONS_MODAL:
      return {
        ...state,
        trialLimitationsModalVisible: false,
      };

    case OPEN_SUBSCRIPTION_UPGRADE_NEEDED:
      return {
        ...state,
        subscriptionUpgradeNeededModalVisible: true,
      };

    case CLOSE_SUBSCRIPTION_UPGRADE_NEEDED:
      return {
        ...state,
        subscriptionUpgradeNeededModalVisible: false,
      };

    default:
      return state;
  }
}

export default globalModalsReducer;
