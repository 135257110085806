import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  setManageSubscriptionModalVisibility,
  setUnlockAddonsModalVisibility,
} from "src/redux";
import { RootState } from "src/redux/reducers";
import { SubscriptionEntitlements } from "../../subscriptionUtils";

interface ButtonProps {
  text?: ReactNode | string;
}

interface Props {
  feature: SubscriptionEntitlements | null;
  unlockAddon?: ButtonProps;
  upgrade?: ButtonProps;
}

const UnlockAddonFeatures = [
  SubscriptionEntitlements.WsXtrmExtension,
  SubscriptionEntitlements.CouponsIntegration,
  SubscriptionEntitlements.ClientMode,
  SubscriptionEntitlements.ContactDataVisibility,
  SubscriptionEntitlements.WsMessengerExtension,
  SubscriptionEntitlements.DictionaryManager,
];

const UpgradePlanFeatures = [
  SubscriptionEntitlements.PublicationSentiments,
  SubscriptionEntitlements.Forms,
];

const SubscriptionManagementButton = (props: Props) => {
  const { feature, unlockAddon, upgrade } = props;

  const { identity } = useSelector((state: RootState) => state.mainReducer);
  const { subscriptionPlan } = useSelector(
    (state: RootState) => state.subscriptionReducer,
  );

  const seatsQuantity =
    subscriptionPlan?.features["feature-seats"]?.unitQuantity;

  const dispatch = useDispatch();

  function UnlockAddonButton() {
    return (
      <Button
        size="large"
        variant="blue"
        data-qa="unlock-addon-button"
        onClick={() => dispatch(setUnlockAddonsModalVisibility(true))}
      >
        {unlockAddon?.text || (
          <IDHFormattedMessage
            id="ws_unlock_addon"
            defaultMessage="Unlock addon"
          />
        )}
      </Button>
    );
  }

  function UpgradePlanButton(props: { disabled?: boolean }) {
    const { disabled } = props;
    return (
      <Button
        onClick={() => dispatch(setManageSubscriptionModalVisibility(true))}
        size="large"
        variant="blue"
        data-qa="upgrade-plan-button"
        disabled={disabled}
      >
        {upgrade?.text || (
          <IDHFormattedMessage
            id="ws_upgrade_plan"
            defaultMessage="Upgrade plan"
          />
        )}
      </Button>
    );
  }

  const renderButton = () => {
    if (
      !feature ||
      !identity?.permissions?.workspace?.includes("subscription_management")
    ) {
      return <UpgradePlanButton disabled />;
    }

    if (UnlockAddonFeatures.includes(feature) && seatsQuantity) {
      return <UnlockAddonButton />;
    }

    return <UpgradePlanButton />;
  };

  return renderButton();
};

export default SubscriptionManagementButton;
