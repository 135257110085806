import React from "react";

import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";

export default function AudiencesResultsCheckbox({
  influencer,
  selectedInfluencers,
  selectUserClick,
  isPreselected,
}) {
  return (
    <CustomCheckbox
      id={influencer.userId}
      name={`user-${influencer.userId}`}
      checked={
        selectedInfluencers.indexOf(influencer.userId) > -1 || isPreselected
      }
      onChange={() => selectUserClick(influencer.userId, influencer.profileUrl)}
      disabled={isPreselected}
    />
  );
}
