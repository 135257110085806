"use no memo";

import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { injectIntl } from "react-intl";
import _ from "lodash";
import {
  clearWebSocket,
  getWorkspacesList,
  identify,
  setActiveWorkspaceUuid,
} from "src/redux/main/mainActions";
import pako from "pako";
import {
  clearSelectedCreatorData,
  clearShowcaseData,
  getShowcaseData,
  setGenerating,
  setMultipleLayouts,
  setNewGridElements,
  setNewHiddenGridElements,
  setNewLayout,
  setNewSavedTemplate,
  setPrinting,
  setSelectedCreatorData,
  setSelectedCreators,
  setSelectedTemplate,
  setShowcaseElements,
} from "../../redux";
import { SocialProvider } from "../../types";
import { API_URLS } from "../../utils/API_URLS";
import {
  arrayBufferToBase64,
  convertFileName,
  layoutsAreEqual,
  showErrorToast,
} from "../../utils/methods";
import { loadAllTemplateImages } from "../generator-data/functions/loadAllTemplateImages";
import { showToast } from "../methods/showToast";
import ShowcaseTemplate from "./components/ShowcaseTemplate/ShowcaseTemplate";
import { ShowcaseToolbar } from "./components/ShowcaseToolbar/ShowcaseToolbar";
import { ShowcaseTopBar } from "./components/ShowcaseTopBar/ShowcaseTopBar";
import "./CreatorShowcase.scss";
import { formatOptions } from "./data/sizingData";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { TASK_FIELDS_BLACKLIST } from "../Task/components/TaskMetaFields/TaskMetaFields";
import { MetaFieldType } from "../methods/getMetaFieldTypeOptions";
import {
  CreatorShowcaseContext,
  getShowcaseSettings,
  getShowcaseSettingByKey,
  showAnotherCreator,
  LEVEL_GZIP_REPORT,
  MIN_CREATORS_TO_ASYNC_GENERATING_REPORTS,
} from "./utils";
import { AppConfig } from "../../config/AppConfig";

export const SUPPORTED_SHOWCASE_SOCIAL_MEDIA = [
  SocialProvider.Instagram,
  SocialProvider.TikTok,
  SocialProvider.Youtube,
  SocialProvider.Snapchat,
  SocialProvider.Twitter,
  SocialProvider.Facebook,
];

const META_TYPES_WHITELIST = [
  MetaFieldType.Text,
  MetaFieldType.Number,
  MetaFieldType.Currency,
  MetaFieldType.Percent,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
  MetaFieldType.Rating,
];

const NO_PICTURE_PROVIDERS = [SocialProvider.Twitter];

function CreatorShowcase({ onClose, intl }) {
  const [filteredMetadata, setFilteredMetadata] = useState([]);
  const [metaValues, setMetaValues] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [format, setFormat] = useState(formatOptions[0]);
  const [pages, setPages] = useState([]);
  const [layoutsToGenerate, setLayoutsToGenerate] = useState([]);
  const [templatesToGenerate, setTemplatesToGenerate] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [templateApplied, setTemplateApplied] = useState(true);
  const [showcasesToPrint, setShowcasesToPrint] = useState([]);
  const [layoutUpdated, setLayoutUpdated] = useState(false);
  const [recentlyPublishedReady, setRecentlyPublishedReady] = useState([]);

  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );
  const selectedCreatorData = useSelector(
    (state) => state.showcaseReducer.selectedCreatorData,
  );
  const notifications = useSelector((state) => state.mainReducer.notifications);
  const layout = useSelector((state) => state.showcaseReducer.layout);
  const gridElements = useSelector(
    (state) => state.showcaseReducer.gridElements,
  );
  const generating = useSelector((state) => state.showcaseReducer.generating);
  const printing = useSelector((state) => state.showcaseReducer.printing);
  const multipleLayouts = useSelector(
    (state) => state.showcaseReducer.multipleLayouts,
  );
  const workspacesList = useSelector(
    (state) => state.mainReducer.workspacesList,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const showcaseRef = useRef();

  const { creatorId, context, workspaceId, search } = getShowcaseSettings();
  const { wsProjectUuid, listUuid } = showcaseData?.contextData || {};
  const { elements } = showcaseData;

  const getSelectedShowcaseData = () => {
    if (creatorId === selectedCreatorData?.id) return;

    const newCreatorData = showcaseData?.elements?.find(
      (item) => item.id === creatorId,
    );

    if (!newCreatorData) return;

    const metadata =
      showcaseData.contextAttributes.map((item) => {
        const isVisible = Boolean(
          showcaseData.contextAttributes.find(
            (attr) => newCreatorData.contextAttributeValues[attr.uuid],
          ),
        );

        return {
          ...item,
          value: newCreatorData.contextAttributeValues[item.uuid] || null,
          key: item.name,
          isVisible,
        };
      }) || [];

    const { contextAttributeValues, ...data } = newCreatorData;

    dispatch(
      setSelectedCreatorData({
        ...data,
        metadata,
        key: data.name,
      }),
    );
  };

  const clearSelectedShowcaseData = () => {
    dispatch(setNewGridElements([]));
    dispatch(setNewLayout([]));
    dispatch(setNewHiddenGridElements([]));
    dispatch(clearSelectedCreatorData());
    setMetricsData([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setSelectedTemplate({ label: "Default", value: {} }));
    dispatch(clearWebSocket());
    dispatch(setActiveWorkspaceUuid(null));
    if (!workspacesList.length) {
      dispatch(getWorkspacesList());
    }
    window.localStorage.setItem("templateSelections", JSON.stringify([]));

    return () => {
      dispatch(setGenerating(true));
      dispatch(setPrinting(false));

      // clearing redux saved template to avoid auto applying on another creators showcase
      dispatch(setNewSavedTemplate([]));
      dispatch(setMultipleLayouts([]));
      dispatch(setSelectedCreators([]));
      dispatch(setShowcaseElements([]));
      dispatch(clearShowcaseData());
      window.localStorage.setItem("showcaseSavedBoxes", JSON.stringify([]));
      clearSelectedShowcaseData();
    };
  }, []);

  const loadAllImages = () => {
    loadAllTemplateImages(".showcase-template", setImagesLoaded);
  };

  useEffect(() => {
    if (
      multipleLayouts.length &&
      templatesToGenerate.length === multipleLayouts.length
    ) {
      generateMultipleTemplates();
    }
  }, [templatesToGenerate]);

  const printMultipleShowcases = () => {
    if (!showcaseData?.elements?.length) return;
    clearSelectedShowcaseData();
    setShowcasesToPrint(showcaseData?.elements?.map((item) => item.id));

    showAnotherCreator(history, "cleaning");
    setTimeout(() => {
      showAnotherCreator(history, showcaseData?.elements[0]?.id);
    }, 50);
  };

  useEffect(() => {
    if (printing) {
      printMultipleShowcases();
      const showcase = document.querySelector(".creator-showcase");
      showcase.scroll({
        top: 0,
        left: 0,
      });
    }
  }, [printing]);

  useEffect(() => {
    dispatch(getShowcaseData());
  }, []);

  useEffect(() => {
    getSelectedShowcaseData();
  }, [showcaseData]);

  useEffect(() => {
    if (!creatorId || creatorId === "cleaning") return;

    if (!printing) {
      dispatch(setGenerating(true));
    }
    clearSelectedShowcaseData();
    setImagesLoaded(false);
    setRecentlyPublishedReady([]);

    setTimeout(() => {
      getSelectedShowcaseData();
    }, 250);
  }, [creatorId, format]);

  useEffect(() => {
    setLayoutsToGenerate(showcaseData?.elements?.map((item) => item.id) || []);
  }, [showcaseData?.elements]);

  useEffect(() => {
    if (!selectedCreatorData?.socialProfilesData) return;

    setMetricsData(
      Object.entries(selectedCreatorData.socialProfilesData).map((e) => {
        return {
          label: e[0],
          data: Object.entries(e[1]).map((f) => {
            return {
              label: f[0],
              data: f[1],
              visible: true,
            };
          }),
        };
      }),
    );
  }, [selectedCreatorData?.socialProfilesData]);

  // resize event is invoked to make react grid layout adjust its width
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [format]);

  useEffect(() => {
    if (
      printing ||
      !generating ||
      !layout.find((item) => item.i.includes("separator"))
    )
      return;

    const layoutLength = layout.filter(
      (item) =>
        gridElements?.find((box) => item.i === box.label) &&
        !item.i.includes("separator"),
    )?.length;

    const correctLength = gridElements.length === layoutLength;

    const notEnoughData =
      selectedCreatorData?.metadata?.length &&
      !metaValues?.length &&
      !_.isEqual(selectedCreatorData?.socialProfilesData, {}) &&
      layoutLength === 0;

    if (correctLength || notEnoughData) {
      const creatorLayout = multipleLayouts.find(
        (item) => item?.creatorId === creatorId,
      );

      if (!imagesLoaded) {
        loadAllImages();
        return;
      }

      if (!creatorLayout && layoutsToGenerate.includes(creatorId)) {
        getMultipleLayouts();
      } else {
        if (creatorLayout?.layout) {
          dispatch(setNewSavedTemplate(creatorLayout?.layout));
        }

        setTimeout(() => {
          dispatch(setGenerating(false));
        }, 1000);
      }
    }
  }, [layout, imagesLoaded]);

  useEffect(() => {
    if (
      !templateApplied ||
      !printing ||
      selectedCreatorData.id !== creatorId ||
      !layout.find((item) => item.i.includes("separator")) ||
      templatesToGenerate.length === multipleLayouts.length
    )
      return;

    const layoutLength =
      layout.filter((item) => !item.i.includes("separator")).length - 1;

    if (gridElements.length !== layoutLength && layoutLength > 1) {
      return;
    }

    const creatorLayout = multipleLayouts
      .find((item) => item.creatorId === creatorId)
      ?.layout?.sort((a, b) => a.y - b.y);

    if (!creatorLayout) return;

    const filteredCreatorLayout = creatorLayout.filter(
      (item) => !item.i.includes("separator"),
    );

    let sortedCreatorLayout = creatorLayout
      .filter((item) => !item.i.includes("separator"))
      .sort((a, b) => a.i.localeCompare(b.i));

    const sortedLayout = layout
      .filter((item) => !item.i.includes("separator"))
      .sort((a, b) => a.i.localeCompare(b.i));

    SUPPORTED_SHOWCASE_SOCIAL_MEDIA.forEach((social) => {
      if (metricsData.find((item) => item.label === social)) return;

      sortedCreatorLayout = sortedCreatorLayout.filter((item) => {
        const prefix = item.i.slice(0, social.length);

        if (prefix !== social) {
          return item;
        }
      });
    });

    if (!layoutsAreEqual(sortedCreatorLayout, sortedLayout) && !layoutUpdated) {
      dispatch(setNewSavedTemplate(filteredCreatorLayout));
      setLayoutUpdated(true);
      return;
    }
    setLayoutUpdated(false);

    if (!imagesLoaded) {
      loadAllImages();
      return;
    }

    let recentlyPublishedRendered = true;

    const posts = layout.filter((item) =>
      item.i.includes("recently-published"),
    );

    posts.forEach((item) => {
      const label = item.i.replace("-recently-published", "");
      const postsLength =
        selectedCreatorData.socialProfilesData[label].lastPostsData.length;

      if (
        !NO_PICTURE_PROVIDERS.includes(label) &&
        !recentlyPublishedReady.includes(label) &&
        postsLength
      ) {
        recentlyPublishedRendered = false;
      }
    });

    if (!recentlyPublishedRendered) return;

    printAllLayouts();
  }, [layout, imagesLoaded, templateApplied, recentlyPublishedReady]);

  useEffect(() => {
    if (!selectedCreatorData?.metadata) return;

    setFilteredMetadata(
      selectedCreatorData.metadata.filter(
        (field) =>
          META_TYPES_WHITELIST.includes(field.type) &&
          !TASK_FIELDS_BLACKLIST.includes(field.key),
      ),
    );
  }, [selectedCreatorData?.metadata]);

  useEffect(() => {
    if (!filteredMetadata.length) return;

    setMetaValues(
      filteredMetadata.filter(
        (field) =>
          field.isVisible && field.value && !_.isEqual(field.value, []),
      ),
    );
  }, [filteredMetadata]);

  const printAllLayouts = () => {
    const filteredLayouts = showcasesToPrint.filter(
      (item) => item !== creatorId,
    );

    setShowcasesToPrint(filteredLayouts);

    const showcaseTemplate = document.querySelector(".showcase-template");

    setTemplatesToGenerate((templates) => [
      ...templates,
      {
        template: showcaseTemplate.cloneNode(true),
        height: showcaseTemplate.clientHeight,
      },
    ]);

    if (filteredLayouts.length) {
      clearSelectedShowcaseData();
      showAnotherCreator(history, filteredLayouts[0]);
    } else {
      showAnotherCreator(history, showcaseData?.elements[0]?.id);
    }
  };

  const getMultipleLayouts = async () => {
    dispatch(setMultipleLayouts([...multipleLayouts, { creatorId, layout }]));
    const filteredLayouts = layoutsToGenerate.filter(
      (item) => item !== creatorId,
    );

    setLayoutsToGenerate(filteredLayouts);
    if (filteredLayouts.length) {
      clearSelectedShowcaseData();
      showAnotherCreator(history, filteredLayouts[0]);
    } else {
      if (multipleLayouts.length) {
        showAnotherCreator(history, multipleLayouts[0].creatorId);
      }
      setTimeout(() => {
        dispatch(setGenerating(false));
      }, 1000);
    }
  };

  const closeShowcase = () => {
    dispatch(setActiveWorkspaceUuid(workspaceId));
    dispatch(identify(workspaceId));

    switch (context) {
      case CreatorShowcaseContext.CreatorDatabase:
        history.push({
          pathname: `/workspace/${workspaceId}/creator-database`,
          search:
            elements?.length === 1
              ? `?displayGlobalTask=${elements[0]?.id}`
              : "",
        });
        break;

      case CreatorShowcaseContext.CreatorList:
        history.push({
          pathname: `/workspace/${workspaceId}/projects/${wsProjectUuid}${
            elements?.length === 1 ? `/${elements[0]?.id}` : ""
          }`,
          search: "taskType=creator",
        });
        break;

      case CreatorShowcaseContext.CreatorDiscoveryList: {
        const profile = elements?.length
          ? elements[0]?.socialProfiles[0]
          : null;
        history.push({
          pathname: `/workspace/${workspaceId}/creator-discovery-tool/list/${listUuid}${
            elements?.length === 1 && profile
              ? `/profile/${profile.socialProvider}/${profile.socialUsername}`
              : ""
          }`,
          search: search || "sortBy=velocityIndex&activeTab=selected",
        });
        history.go(0);
        break;
      }

      case CreatorShowcaseContext.CreatorDiscoverySearch: {
        const profile = selectedCreatorData?.socialProfiles
          ? selectedCreatorData?.socialProfiles[0]
          : null;

        history.push({
          pathname: `/workspace/${workspaceId}/creator-discovery-tool/search-results${profile ? `/profile/${profile.socialProvider}/${profile.socialUsername}` : ""}`,
          search: search || "sortBy=velocityIndex&activeTab=results",
        });
        history.go(0);
        break;
      }
    }
  };

  const generateMultipleTemplates = async () => {
    try {
      const head = document.querySelector("head");
      const headHtml = head.outerHTML.replace(
        "<head>",
        `<head><base href="${AppConfig.getApiHost()}" />`,
      );

      const html = `<!DOCTYPE html><html lang="en">${headHtml}<body>${templatesToGenerate
        .map((item, idx) => {
          const showcaseTemplate = item.template;
          const pageHeight = item.height;

          const images = showcaseTemplate.getElementsByTagName("img");

          Array.from(images).forEach((img) => {
            const image = img;
            image.src = img.src.replace(":3003", "");
            showcaseTemplate.outerHTML.replace(img, image);
          });

          const loader = showcaseTemplate.querySelector(
            ".showcase-printing-loader",
          );

          showcaseTemplate.outerHTML.replace(
            loader,
            (loader.style.display = "none"),
          );

          const layout = showcaseTemplate.querySelector(".react-grid-layout");

          layout.style.transform = "translateY(0px)";
          layout.style.height = `${Number(layout.style.height.replace("px", "")) - 70}px`;

          showcaseTemplate.style.overflow = `hidden`;

          let { outerHTML } = showcaseTemplate;

          // Remove lazy loading attribute to force loading images on PDF:
          outerHTML = outerHTML.replaceAll('loading="lazy"', "");
          // Restore img srces:
          outerHTML = outerHTML.replaceAll("data-img-src", "src");

          return outerHTML;
        })
        .join("")}</body>`;

      const gzippedHtmlBinary = pako.gzip(html, {
        level: LEVEL_GZIP_REPORT,
      });
      const gzippedHtml = arrayBufferToBase64(gzippedHtmlBinary);

      const wsWorkspaceUuid = getShowcaseSettingByKey("workspaceId") ?? "";
      const fileName = convertFileName(
        multipleLayouts.length > 1
          ? showcaseData.contextName
          : selectedCreatorData.name,
      );
      const asyncGeneratingReport =
        multipleLayouts.length >= MIN_CREATORS_TO_ASYNC_GENERATING_REPORTS;

      const response = await axios.post(
        API_URLS.printPdfFromHtml,
        {
          fileName: `${fileName}.pdf`,
          width: format.value.pageWidth,
          height: format.value.pageHeight,
          gzippedHtml,
          wsWorkspaceUuid,
          async: asyncGeneratingReport,
        },
        { responseType: "blob" },
      );

      if (asyncGeneratingReport) {
        showToast(
          "info",
          <IDHFormattedMessage id="ws_info" defaultMessage="Info" />,
          <IDHFormattedMessage
            id="ws_report_has_been_queued"
            defaultMessage="Generating this report may take a while. You will receive an email once it's ready. This may take up to 30 minutes."
          />,
        );

        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error.response);
      showErrorToast();
    } finally {
      dispatch(setPrinting(false));
      setTemplatesToGenerate([]);
      setLayoutsToGenerate([]);

      const newLayout = multipleLayouts.find(
        (item) => item.creatorId === showcaseData?.elements[0]?.id,
      );
      dispatch(setNewSavedTemplate(newLayout.layout));
    }
  };

  return (
    <div
      ref={showcaseRef}
      className="creator-showcase template-generator"
      style={{
        marginTop: notifications.length * 44,
      }}
    >
      <ShowcaseTopBar
        showcaseRef={showcaseRef}
        onClose={onClose}
        data={selectedCreatorData}
        format={format.value}
        clearShowcaseData={clearSelectedShowcaseData}
        closeShowcase={closeShowcase}
      />
      <ShowcaseToolbar
        showcaseRef={showcaseRef}
        data={selectedCreatorData}
        format={format}
        setFormat={setFormat}
        metricsData={metricsData}
        setMetricsData={setMetricsData}
      />

      <div className="creator-showcase__template-wrapper">
        <ShowcaseTemplate
          filteredMetadata={filteredMetadata}
          format={format.value}
          pages={pages}
          setPages={setPages}
          metricsData={metricsData}
          setMetricsData={setMetricsData}
          progressValue={templatesToGenerate.length}
          progressTarget={multipleLayouts.length}
          templateApplied={templateApplied}
          setTemplateApplied={setTemplateApplied}
          recentlyPublishedReady={recentlyPublishedReady}
          setRecentlyPublishedReady={setRecentlyPublishedReady}
        />
      </div>
    </div>
  );
}

export default injectIntl(CreatorShowcase);
