export const subscriptionTypes = {
  SET_STIGG_CONTEXT_VALUE: "SET_STIGG_CONTEXT_VALUE",
  SET_MANAGE_SUBSCRIPTION_MODAL_VISIBILITY:
    "SET_MANAGE_SUBSCRIPTION_MODAL_VISIBILITY",
  SET_SELECTED_SUBSCRIPTION_PLAN_DATA: "SET_SELECTED_SUBSCRIPTION_PLAN_DATA",
  SET_PLAN_UPGRADE_NEEDED_MODAL_CONTEXT:
    "SET_PLAN_UPGRADE_NEEDED_MODAL_CONTEXT",
  SET_SUBSCRIPTION_PLAN: "SET_SUBSCRIPTION_PLAN",
  SET_SUBSCRIPTION_MODAL_MODE: "SET_SUBSCRIPTION_MODAL_MODE",
  SET_SUBSCRIPTION_CONTACT_SUPPORT_MODAL_VISIBILITY:
    "SET_SUBSCRIPTION_CONTACT_SUPPORT_MODAL_VISIBILITY",
  SET_UNLOCK_ADDONS_MODAL_VISIBILITY: "SET_UNLOCK_ADDONS_MODAL_VISIBILITY",
};
