import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import {API_URLS} from "../../utils/API_URLS";

function ConfirmPaidSubscriptionPage() {
  const { search } = useLocation();

  return  (
    <div className="trial-view">
        we are creating your workspace... please wait
    </div>
  );
}

export { ConfirmPaidSubscriptionPage };
