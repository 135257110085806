import {
  OPEN_TRIAL_EXCEEDED_MODAL,
  CLOSE_TRIAL_EXCEEDED_MODAL,
  OPEN_TRIAL_LIMITATIONS_MODAL,
  CLOSE_TRIAL_LIMITATIONS_MODAL,
  OPEN_SUBSCRIPTION_UPGRADE_NEEDED,
  CLOSE_SUBSCRIPTION_UPGRADE_NEEDED,
} from "./globalModalsTypes";

export function openTrialExceededModal() {
  return {
    type: OPEN_TRIAL_EXCEEDED_MODAL,
  };
}

export function closeTrialExceededModal() {
  return {
    type: CLOSE_TRIAL_EXCEEDED_MODAL,
  };
}

export function openTrialLimitationsModal() {
  return {
    type: OPEN_TRIAL_LIMITATIONS_MODAL,
  };
}

export function closeTrialLimitationsModal() {
  return {
    type: CLOSE_TRIAL_LIMITATIONS_MODAL,
  };
}

export function openSubscriptionUpgradeNeededModal() {
  return {
    type: OPEN_SUBSCRIPTION_UPGRADE_NEEDED,
  };
}

export function closeSubscriptionUpgradeNeededModal() {
  return {
    type: CLOSE_SUBSCRIPTION_UPGRADE_NEEDED,
  };
}
