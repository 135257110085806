import React from "react";
import { Checkout } from "@stigg/react-sdk";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

function NoActiveSubscriptionPage() {
  const {
    mainReducer: { identity },
  } = useSelector((state: RootState) => state);

  if (!identity?.subscriptionData?.initialWsSubscriptionPlanId) return null;

  return (
    <div className="onboarding-page">
      NoActiveSubscriptionPage <br />
      <Checkout
        planId={identity.subscriptionData.initialWsSubscriptionPlanId}
        // @ts-ignore
        onCheckoutCompleted={({ success, error }) => {
          if (success) {
            window.location.reload();
          }
        }}
      />
    </div>
  );
}

export default NoActiveSubscriptionPage;
