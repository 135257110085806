import { handle402Errors } from "src/redux/main/functions";

const subscriptionErrorsMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith("FAIL")) {
    if (action?.error?.response?.data?.statusCode == 402) {
      const errors = action?.error?.response?.data?.errors;
      handle402Errors(store.dispatch, errors);
    }
  }
  return next(action);
};

export default subscriptionErrorsMiddleware;
