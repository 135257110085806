import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import axios from "axios";

import { API_URLS } from "src/utils/API_URLS";
import { AccessLinkPageContent } from "./components/AccessLinkPageContent/AccessLinkPageContent";
import "./AccessLinkPage.scss";
import { getSelectDataSetForColumns } from "src/utils/methods";
import { useDispatch } from "react-redux";
import { handle402Errors } from "src/redux/main/functions";
import responseCodes from "src/utils/responseCodes";

export function AccessLinkPage() {
  const { taskUuid, type, metafieldUuid, token } = useParams();
  const [response, setResponse] = useState(null);
  const [anyFieldRequired, setAnyFieldRequired] = useState(false);
  const [pageHandler, setPageHandler] = useState();

  const dispatch = useDispatch();

  const getTaskLinkData = async () => {
    try {
      const url = API_URLS.getTaskLinkData;
      const {
        data: { content },
      } = await axios.get(url, {
        params: {
          wsMetaFieldUuid: metafieldUuid,
          token,
          metaFieldType: type,
          wsTaskUuid: taskUuid,
        },
      });
      setResponse(content);

      if (!content) setPageHandler("expired"); // if no response page is expired

      setAnyFieldRequired(
        content?.metaFields.filter((m) => m.metaFieldIsRequired).length > 0,
      );
    } catch (error) {
      if (
        error?.response.data.statusCode == responseCodes["402_PAYMENT_REQUIRED"]
      ) {
        handle402Errors(dispatch, error?.response?.data?.errors);
      } else {
        setPageHandler("expired");
      }
    }
  };

  useEffect(() => {
    if (document.body.classList.contains("external-page")) getTaskLinkData();
    document.body.classList.add("external-page");
  }, []);

  useEffect(() => {
    if (response?.metaFields?.length) {
      getSelectDataSetForColumns(
        dispatch,
        response.metaFields.map((field) => ({
          ...field,
          options: field.data,
        })),
      );
    }
  }, [response?.metaFields]);

  return (
    <AccessLinkPageContent
      pageHandler={pageHandler}
      setPageHandler={setPageHandler}
      response={response}
      anyFieldRequired={anyFieldRequired}
    />
  );
}
