"use no memo";

import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import "./ShowcaseTemplate.scss";

import CustomImage from "src/app/components/CustomImage/CustomImage";
import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { StackedAvatars } from "src/app/components/StackedAvatars/StackedAvatars";
import {
  setMultipleLayouts,
  setNewGridElements,
  setNewHiddenGridElements,
  setNewLayout,
  setNewSavedTemplate,
  setTemplateBase,
} from "../../../../redux";
import { addMetricsToLayout } from "./addMetricsToLayout";
import { usePrevious } from "../../../methods/usePrevious";
import { applySystemTemplate } from "./applySystemTemplate";
import { addMetaFieldToLayout } from "../../../generator-data/functions/addMetaFieldToLayout";
import { getGeneratorGridElements } from "../../../generator-data/functions/getGeneratorGridElements";
import { addSeparatorsToLayout } from "../../../generator-data/functions/addSeparatorsToLayout";
import { METRIC_FIELDS } from "../../../generator-data/utils/variables";
import { getShowcaseSettingByKey } from "../../utils";
import { CoverSummary } from "./tabs/ShowcaseCoverTab/components/CoverSummary/CoverSummary";
import { CoverFooter } from "./tabs/ShowcaseCoverTab/components/CoverFooter/CoverFooter";
import { CoverTop } from "./tabs/ShowcaseCoverTab/components/CoverTop/CoverTop";
import { ShowcaseCoverTab } from "./tabs/ShowcaseCoverTab/ShowcaseCoverTab";
import { ShowcaseCreatorsTab } from "./tabs/ShowcaseCreatorsTab/ShowcaseCreatorsTab";

export const STATIC_ELEMENTS = ["basic-info", "separator"];

function ShowcaseTemplate({
  filteredMetadata,
  format,
  pages,
  setPages,
  metricsData,
  progressValue,
  progressTarget,
  templateApplied,
  setTemplateApplied,
  recentlyPublishedReady,
  setRecentlyPublishedReady,
}) {
  const templateRef = useRef();

  const initialLayout = [
    { i: "basic-info", x: 0, y: 0, w: 6, h: 1, static: true },
  ];

  const [separators, setSeparators] = useState([]);
  const [shouldRerenderLayout, setShouldRerenderLayout] = useState(true);
  const [hiddenFields, setHiddenFields] = useState(
    METRIC_FIELDS.filter((item) => item.initialHidden) || [],
  );

  const dispatch = useDispatch();

  const layout = useSelector((state) => state.showcaseReducer.layout);
  const savedTemplate = useSelector(
    (state) => state.showcaseReducer.savedTemplate,
  );
  const gridElements = useSelector(
    (state) => state.showcaseReducer.gridElements,
  );
  const hiddenGridElements = useSelector(
    (state) => state.showcaseReducer.hiddenGridElements,
  );
  const printing = useSelector((state) => state.showcaseReducer.printing);
  const multipleLayouts = useSelector(
    (state) => state.showcaseReducer.multipleLayouts,
  );
  const selectedTemplate = useSelector(
    (state) => state.showcaseReducer.selectedTemplate,
  );
  const showcaseTransition = useSelector(
    (state) => state.showcaseReducer.showcaseTransition,
  );
  const summaryPlacement = useSelector(
    (state) => state.showcaseReducer.summaryPlacement,
  );
  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );
  const { elements } = showcaseData;

  const workspacesList = useSelector(
    (state) => state.mainReducer.workspacesList,
  );
  const workspaceData = workspacesList?.find(
    (workspace) => workspace.uuid === getShowcaseSettingByKey("workspaceId"),
  );
  const data = useSelector(
    (state) => state.showcaseReducer.selectedCreatorData,
  );
  const identity = useSelector((state) => state.mainReducer.identity);
  const primaryColor = identity.customPrimaryColor || "#5D78FF";

  const setLayout = (newLayout) => {
    dispatch(setNewLayout(newLayout));
  };

  const setGridElements = (newGridElements) => {
    dispatch(setNewGridElements(newGridElements));
  };

  const setHiddenGridElements = (newHiddenGridElements) => {
    dispatch(setNewHiddenGridElements(newHiddenGridElements));
  };

  const creatorId = getShowcaseSettingByKey("creatorId");
  const tab = getShowcaseSettingByKey("tab");
  const previousCreatorId = usePrevious(creatorId);

  // saving layout for active creators
  useEffect(() => {
    if (!layout.length) return;

    const newMultipleLayouts = multipleLayouts.map((item) => {
      if (item.creatorId === previousCreatorId) {
        return {
          ...item,
          layout,
        };
      }

      return item;
    });

    dispatch(setMultipleLayouts(newMultipleLayouts));
    setHiddenFields(METRIC_FIELDS.filter((item) => item.initialHidden) || []);
  }, [creatorId]);

  useEffect(() => {
    if (savedTemplate.length) {
      setTemplateApplied(false);
    }
  }, [savedTemplate]);

  const getGridElements = () => {
    if (data?.id !== creatorId) return;

    const functionProps = {
      data,
      filteredMetadata,
      hiddenFields,
      metricsData,
      savedTemplate,
      selectedTemplate,
      templateApplied,
      //
      setGridElements,
      setHiddenFields,
      setHiddenGridElements,
      setShouldRerenderLayout,
    };

    getGeneratorGridElements(functionProps);
  };

  useEffect(() => {
    if (!templateApplied) {
      getGridElements();
    }
  }, [templateApplied]);

  useEffect(() => {
    getGridElements();
  }, [filteredMetadata, metricsData, format]);

  useEffect(() => {
    if (selectedTemplate.value.type === "default" && !printing) {
      getGridElements();
    }
  }, [selectedTemplate]);

  // Rendering

  const applySavedLayout = () => {
    const { autogenerateMetadata } = selectedTemplate?.value || {};

    const upperLayout = initialLayout;
    let lowerLayout = [...savedTemplate];
    const newSeparators = [];

    if (autogenerateMetadata) {
      gridElements
        .filter((item) => item.gridBoxType === "metadata")
        .forEach((box) => {
          addMetaFieldToLayout(box, upperLayout, newSeparators, format);
        });

      const lastEl = upperLayout[upperLayout.length - 1];
      const lastElEndY = lastEl.y + lastEl.h;
      const difference = Math.ceil(lastElEndY / format.rows);

      lowerLayout = lowerLayout
        .filter(
          (item) => !item.i.includes("metadata-") && item.i !== "basic-data",
        )
        .map((item) => ({
          ...item,
          y: item.y + format.rows * difference,
        }));
    }

    const newLayout = [...upperLayout, ...lowerLayout];

    setLayout(newLayout);
    dispatch(setTemplateBase(newLayout));
    dispatch(setNewSavedTemplate([]));
    setTemplateApplied(true);
  };

  const applyChosenTemplate = () => {
    const newLayout = initialLayout;
    const newSeparators = [];

    const systemTemplate = selectedTemplate?.value?.layout?.filter(
      (item) => !item.i.includes("metadata-") && item.i !== "basic-data",
    );

    gridElements
      .filter((item) => item.gridBoxType === "metadata")
      .forEach((box) => {
        addMetaFieldToLayout(box, newLayout, newSeparators, format);
      });

    applySystemTemplate(newLayout, format, systemTemplate, gridElements);

    setLayout(newLayout);
    setSeparators(newSeparators);

    const allElements = [...gridElements, ...hiddenGridElements];

    const newGridElements = allElements.filter((item) => {
      return newLayout.find((i) => i.i === item.label);
    });

    const newHiddenGridElements = allElements.filter((item) => {
      return !newLayout.find((i) => i.i === item.label);
    });

    if (gridElements.length !== newGridElements.length) {
      setGridElements(newGridElements);
      setHiddenGridElements(newHiddenGridElements);
    }
  };

  const applyDefaultLayout = () => {
    const newLayout = initialLayout;
    const newSeparators = [];

    gridElements
      .filter((item) => item.gridBoxType === "metadata")
      .forEach((box) => {
        addMetaFieldToLayout(box, newLayout, newSeparators, format);
      });

    metricsData.forEach((metric) => {
      addMetricsToLayout(metric, newLayout, format);
    });

    setLayout(newLayout);
    setSeparators(newSeparators);
  };

  useEffect(() => {
    if (!shouldRerenderLayout) return;
    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      applySavedLayout();
    }
    // applying default template
    else if (selectedTemplate?.value?.type === "default") {
      applyChosenTemplate();
    } else {
      applyDefaultLayout();
    }
    setShouldRerenderLayout(false);
  }, [gridElements, format.pageWidth]);

  useEffect(() => {
    if (!layout?.length) return;

    const functionProps = {
      format,
      gridElements,
      layout,
      pages,
      separators,
      //
      setPages,
      setLayout,
      setSeparators,
    };

    addSeparatorsToLayout(functionProps);
  }, [layout]);

  const renderCreators = () => {
    if (!elements) return null;

    if (elements?.length > 1) {
      return (
        <div className="showcase-cover-tab__creators">
          <span className="showcase-cover-tab__creators-header">
            <IDHFormattedMessage id="ws_creators" defaultMessage="Creators" />
          </span>

          <StackedAvatars
            avatars={elements.map((item) => ({
              url: item?.cover,
            }))}
            maxAvatars={3}
            small
          />
        </div>
      );
    }

    return (
      <div className="showcase-cover-tab__creators">
        <CustomImage
          className="showcase-cover-tab__creators-cover"
          src={elements[0]?.cover}
          fallbackImageSrc={CreatorPlaceholder}
          alt="avatar"
          loading="lazy"
        />
        <span className="showcase-cover-tab__creators-header">
          {elements[0]?.name}
        </span>
      </div>
    );
  };

  const renderCoverTab = () => {
    return <ShowcaseCoverTab renderCreators={renderCreators} />;
  };

  const renderCreatorsTab = () => {
    return (
      <div className="showcase-template-content">
        {printing && progressValue === 0 && (
          <>
            <CoverTop
              workspaceData={workspaceData}
              renderCreators={renderCreators}
            />
            {summaryPlacement.value === "top" && (
              <CoverSummary workspaceData={workspaceData} printing={printing} />
            )}
          </>
        )}

        <ShowcaseCreatorsTab
          data={data}
          setLayout={setLayout}
          workspaceData={workspaceData}
          recentlyPublishedReady={recentlyPublishedReady}
          setRecentlyPublishedReady={setRecentlyPublishedReady}
          format={format}
          progressValue={progressValue}
          progressTarget={progressTarget}
          separators={separators}
          primaryColor={primaryColor}
        />

        {printing && progressValue === progressTarget - 1 && (
          <>
            {summaryPlacement.value === "bottom" && (
              <CoverSummary workspaceData={workspaceData} printing={printing} />
            )}
            <CoverFooter
              workspaceData={workspaceData}
              renderCreators={renderCreators}
            />
          </>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (tab === "cover") return renderCoverTab();
    if (tab === "creators") return renderCreatorsTab();
    return null;
  };

  return (
    <div
      ref={templateRef}
      className={classNames("showcase-template", {
        "showcase-template--hidden": showcaseTransition,
      })}
      style={{
        width: `${format.pageWidth}px`,
      }}
    >
      {renderContent()}
    </div>
  );
}

export default ShowcaseTemplate;
