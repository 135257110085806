import React, { useEffect, useState } from "react";
import "./TrialPage.scss";
import "./TrialViewPage.scss";
import { useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import { useLocation } from "react-router";
import axios from "axios";
import classNames from "classnames";
import { LeftSide } from "./components/LeftSide";
import { RightSide } from "./components/RightSide";
import { ReactComponent as AngleLeftGrey } from "../../images/angle-left-grey.svg";
import { TrialFormContent } from "./components/TrialFormContent";
import { TrialInfoContent } from "./components/TrialInfoContent";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";

function TrialPage() {
  const { identity, identifyError } = useSelector(
    (state: RootState) => state.mainReducer,
  );
  const { search } = useLocation();
  const [fccId, setFccId] = useState<string | null>(null);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [planName, setPlanName] = useState<string | null>(null);

  useEffect(() => {
    if (Object.keys(identity).length > 0) {
      window.location.pathname = `/workspace`;
    }
  }, [identity, window.location.pathname]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const paramFccId = params.get("fccId") ?? null;

    setFccId(paramFccId);
    setPlanName(params.get("plan") ?? null);
  }, [search]);

  return identifyError ? (
    <div className="trial-view">
      <div className="trial-view-content-background" />
      <div className="trial-view-content">
        <TrialInfoContent showForm={showForm} planName={planName} />
        <TrialFormContent
          showForm={showForm}
          setShowForm={setShowForm}
          planName={planName}
        />
      </div>
      <div
        className={classNames("trial-view-footer", {
          "trial-view-footer-success": !showForm,
        })}
      >
        {fccId ? (
          <a
            href={`/workspace/credibility-tool/${fccId}`}
            className="trial-page-right__back-to-homepage"
          >
            <AngleLeftGrey className="trial-page-right__back-to-homepage-icon" />
            <IDHFormattedMessage
              id="ws_back_to_credibility_tool"
              defaultMessage="Back to Free Credibility Tool"
            />
          </a>
        ) : (
          <a href="/" className="trial-page-right__back-to-homepage">
            <AngleLeftGrey className="trial-page-right__back-to-homepage-icon" />
            <IDHFormattedMessage
              id="ws_back_to_homepage"
              defaultMessage="Back to homepage"
            />
          </a>
        )}
        <div className="trial-view-footer__copyrights">
          <IDHFormattedMessage
            id="ws_trial_copyrights"
            defaultMessage="IDH Media Limited. All rights reserved © 2025"
          />
        </div>
      </div>
    </div>
  ) : null;
}

export { TrialPage };
