import React, { useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { WsExtension } from "src/types";
import { ReactComponent as OpenNewWindowIcon } from "src/images/opn-new-card-grey.svg";
import {
  closeTrialExceededModal,
  closeTrialLimitationsModal,
  getMembersList,
} from "../../redux";
import { uploadAvatarBlob } from "../../utils/uploadFunctions";
import { SkeletonCircle } from "../components/Skeleton/Skeleton";
import CustomImage from "../components/CustomImage/CustomImage";
import TopSearch from "./TopSearch/TopSearch";
import ImageCropModal from "../components/CroppedImageUploader/ImageCropModal/ImageCropModal";
import { TrialLimitationsModal } from "../modals/TrialLimitationsModal/TrialLimitationsModal";
import { TrialExceededModal } from "../modals/TrialExceededModal/TrialExceededModal";
import { RolesInfoModal } from "../modals/ShareModal/RolesInfoModal/RolesInfoModal";
import { WorkspaceRolesInfoModal } from "../modals/ShareModal/WorkspaceRolesInfoModal/WorkspaceRolesInfoModal";
import { SettingsModal } from "../SettingsModal/SettingsModal";
import { SettingsContext, SettingsTab } from "../SettingsModal/enums";
import SettingsTrialDropdown from "../dropdowns/SettingsTrialDropdown/SettingsTrialDropdown";
import {
  closeSettingsModal,
  openSettingsModal,
} from "../SettingsModal/methods";
import SubscriptionUsageModal, {
  SubscriptionUsageModalMode,
} from "../subscription/modals/SubscriptionUsageModal/SubscriptionUsageModal";
import SubscriptionDropdown from "../subscription/dropdowns/SubscriptionDropdown/SubscriptionDropdown";
import SubscriptionModals from "../subscription/components/SubscriptionModals/SubscriptionModals";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { DEMO_MODE_INFO_BAR_PADDING } from "../components/DemoModeInfoBar/DemoModeInfoBar";

interface WorkspacePermissions {
  workspace: string[];
}
interface WorkspaceDictionaryListItem {
  wsDictionaryUuid: string;
  wsDictionaryName: string;
  wsDictionaryElementName: null | string;
  wsDictionaryUrl: null | string;
  wsDictionaryIcon: null | string;
}
export interface IIdentity {
  id: string;
  name: string;
  email: string;
  errors: string[];
  avatar: any;
  avatarUrl: string;
  websocketChallenge: string;
  permissions: WorkspacePermissions;
  trialLimits: TrialLimits;
  subscriptionData: any; // TODO: needed props
  intercomId: string;
  lastWorkspaceUuid: string;
  sidebarItems: [];
  workspaceDictionaryList: WorkspaceDictionaryListItem[];
  wsExtensionXtrmPaymentsUuid: string | null;
  wsWorkspaceExtensions: WsExtension[];
}

export interface TrialLimits {
  campaignsLimit: number;
  daysLimit: number;
  listsLimit: number;
  searchRequestsLimit: number;
  tasksLimit: number;
}

const shouldShowTopSearch = (pathName: string) => {
  const blacklist = ["creator-discovery-tool"];
  return !blacklist.some((item) => pathName.includes(item));
};

function Navbar() {
  const [loading, setLoading] = useState(false);
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [showRolesInfoModal, setShowRolesInfoModal] = useState(false);
  const [showWorkspaceRolesInfoModal, setShowWorkspaceRolesInfoModal] =
    useState(false);

  const {
    globalModalsReducer: {
      trialExceededModalVisible,
      trialLimitationsModalVisible,
    },
    mainReducer: {
      activeWorkspaceUuid,
      settingsModalData,
      identity,
      notifications,
    },
    projectReducer: { membersList, membersListLoading },
    audiencesManagerReducer: { trialExceededCDTModalVisible },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const history = useHistory();

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeAvatar = async (file: any) => {
    if (identity?.id) {
      setLoading(true);
      setAvatarUrl("");
      await uploadAvatarBlob(file, dispatch, identity?.id, activeWorkspaceUuid);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      activeWorkspaceUuid &&
      identity?.id &&
      !membersList?.length &&
      !membersListLoading
    ) {
      dispatch(getMembersList(activeWorkspaceUuid));
    }
  }, [activeWorkspaceUuid]);

  // avatar always visible fallback
  useEffect(() => {
    if (identity?.avatar) {
      setAvatarUrl(
        identity?.avatar?.thumbnails.tiny || identity?.avatar?.publicUrl,
      );
    }
  }, [identity?.avatar]);

  const handleOpenSettingsModal = (
    context: SettingsContext,
    tab: SettingsTab,
  ) => {
    openSettingsModal(history, AppLocation.Navbar, context, tab);
  };

  const showOnboarding = identity?.errors?.includes("onboarding");

  const trialLimitationConditions =
    trialLimitationsModalVisible &&
    trialExceededModalVisible === false &&
    trialExceededCDTModalVisible === false;

  const renderTrialLimitationsModal =
    trialLimitationConditions && identity?.trialLimits;

  const renderSubscriptionUsageModal =
    trialLimitationConditions && identity?.subscriptionData && !showOnboarding;

  const calculatePaddingToOffsetNotifications = () => {
    return notifications.length * 44;
  };

  const getPaddingToOffsetDemoModeInfoBar = () => {
    return identity?.isDemoWorkspace ? DEMO_MODE_INFO_BAR_PADDING : 0;
  };

  return (
    <header
      className="navbar"
      style={{
        marginTop:
          calculatePaddingToOffsetNotifications() +
          getPaddingToOffsetDemoModeInfoBar(),
      }}
    >
      <div className="navbar__left" onClick={() => inputRef.current?.focus()}>
        {shouldShowTopSearch(window.location.pathname) && <TopSearch />}
      </div>
      <div className="navbar__right">
        {identity?.demoWsWorkspaceUrl && (
          <a
            href={identity.demoWsWorkspaceUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button-style-reset navbar__demo-mode-btn">
              <IDHFormattedMessage
                id="ws_demo_mode"
                defaultMessage="Demo mode"
              />
              <OpenNewWindowIcon />
            </button>
          </a>
        )}

        {loading ? (
          <SkeletonCircle size={32} marginRight={12} />
        ) : (
          <div className="navbar__right-avatar">
            <CustomImage
              src={`${avatarUrl}`}
              alt="avatar"
              onClick={() => setShowImageCropModal(true)}
            />
          </div>
        )}
        <div className="navbar__right-user-data">
          <div className="navbar__right-name">{identity?.name}</div>
          <div className="navbar__right-workspace-name">
            {identity?.wsWorkspaceName}
          </div>
        </div>

        {identity?.subscriptionData && <SubscriptionDropdown />}

        <SettingsTrialDropdown />
      </div>

      {settingsModalData?.location === AppLocation.Navbar && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareModal: () =>
              openSettingsModal(
                history,
                AppLocation.Navbar,
                SettingsContext.Project,
                SettingsTab.Members,
              ),
            openRolesInfoModal: () => setShowRolesInfoModal(true),
            openWorkspaceRolesInfoModal: () =>
              setShowWorkspaceRolesInfoModal(true),
          }}
          contextTargetUuid={activeWorkspaceUuid || ""}
          disableTransition
        />
      )}

      {showRolesInfoModal && (
        <RolesInfoModal
          onClose={() => setShowRolesInfoModal(false)}
          openShareModal={() =>
            handleOpenSettingsModal(
              SettingsContext.Workspace,
              SettingsTab.Members,
            )
          }
        />
      )}

      {showWorkspaceRolesInfoModal && (
        <WorkspaceRolesInfoModal
          onClose={() => setShowWorkspaceRolesInfoModal(false)}
        />
      )}

      {showImageCropModal && (
        <ImageCropModal
          uploadFunction={onChangeAvatar}
          onClose={() => setShowImageCropModal(false)}
          circle
        />
      )}
      {trialExceededModalVisible && (
        <TrialExceededModal
          onClose={() => dispatch(closeTrialExceededModal())}
        />
      )}
      {renderTrialLimitationsModal && (
        <TrialLimitationsModal
          onClose={() => dispatch(closeTrialLimitationsModal())}
        />
      )}
      {renderSubscriptionUsageModal && (
        <SubscriptionUsageModal
          mode={SubscriptionUsageModalMode.Info}
          onClose={() => dispatch(closeTrialLimitationsModal())}
        />
      )}

      <SubscriptionModals />
    </header>
  );
}

export default Navbar;
