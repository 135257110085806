import React, { useContext, useEffect, useState } from "react";
import "./ClientModeSwitch.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  getProject,
  getProjectDetails,
  getProjectsList,
  getTasksList,
  setPlanUpgradeNeededModalContext,
} from "src/redux";
import { AppLocation } from "src/redux/main/mainReducer";
import { handle402Errors } from "src/redux/main/functions";
import axios from "axios";
import responseCodes from "src/utils/responseCodes";
import { showErrorToast } from "src/utils/methods";
import { useCheckFeatureAccess } from "src/app/subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { API_URLS } from "../../../utils/API_URLS";
import Tooltip from "../Tooltip/Tooltip";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "../CustomSwitch/CustomSwitch";

function ClientModeSwitch(props) {
  const { members, projectId, permissions, clientMode, getData } = props;
  const [isClientModeOn, setIsClientModeOn] = useState(clientMode);

  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
    projectReducer: { taskType },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const hasFeatureAccess = useCheckFeatureAccess(
    SubscriptionEntitlements.ClientMode,
  );

  useEffect(() => {
    setIsClientModeOn(clientMode);
  }, [clientMode]);

  const toggleClientMode = async () => {
    const url = API_URLS.toggleClientMode.replace(":wsProjectUuid:", projectId);
    setIsClientModeOn((prev) => !prev);

    try {
      await axios.patch(url, {
        enabled: !isClientModeOn,
      });

      getData();

      if (settingsModalData.location === AppLocation.ProjectList) {
        dispatch(getProjectsList(activeWorkspaceUuid));
      } else {
        dispatch(getProjectDetails(projectId));
        dispatch(getProject(projectId));
        dispatch(getTasksList(projectId, taskType));
      }
    } catch (err) {
      if (
        err?.response.data.statusCode == responseCodes["402_PAYMENT_REQUIRED"]
      ) {
        handle402Errors(dispatch, err?.response?.data?.errors);
      }
      setIsClientModeOn((prev) => !prev);
      showErrorToast(
        <IDHFormattedMessage
          id="ws_error_occurred_during_switching_client_mode"
          defaultMessage="Error occurred during switching client mode"
        />,
      );
    }
  };

  const handleSwitchChange = () => {
    if (!hasFeatureAccess) {
      dispatch(
        setPlanUpgradeNeededModalContext(SubscriptionEntitlements.ClientMode),
      );
      return;
    }

    toggleClientMode();
  };

  const hasOneClient = () => {
    return members.find((user) => user.isClient);
  };

  return (
    <div className="client-mode-switch">
      <Tooltip
        center
        content={
          <IDHFormattedMessage
            id="ws_tooltip_cannot_disable_client_mode"
            defaultMessage="You cannot disable client mode as long as you have invited users to the campaign with client permissions."
          />
        }
        contentHidden={!hasOneClient()}
      >
        <CustomSwitch
          checked={isClientModeOn}
          onChange={handleSwitchChange}
          disabled={
            !permissions?.project.includes("share_project_resources") ||
            hasOneClient()
          }
        />
      </Tooltip>
      <IDHFormattedMessage
        id="ws_enable_client_mode"
        defaultMessage="Enable “Client mode”"
      />
    </div>
  );
}

export { ClientModeSwitch };
