import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import { setIntercomUnreadMessages } from "src/redux/main/mainActions";

interface Props {
  children: ReactNode;
}

const IDHIntercomProvider = (props: Props) => {
  const { children } = props;

  const dispatch = useDispatch();

  /**
   * Fix for problem with white screen after reload app: https://indahash.slack.com/archives/C02BGLBHLBE/p1727164555848889
   */
  try {
    return (
      <IntercomProvider
        appId="neilngrl"
        onUnreadCountChange={(amount: number) =>
          dispatch(setIntercomUnreadMessages(amount))
        }
      >
        {children}
      </IntercomProvider>
    );
  } catch (e) {
    console.error("[WS] Failed to render intercom component");
    return <>{children}</>;
  }
};

export default IDHIntercomProvider;
