// IMAGES
import commentSentimentAIImage from "src/images/subscription/ecommerce-comment-sentiment-ai.png";
import couponsIntegrationImage from "src/images/subscription/ecommerce-coupons-integration.png";
import formsImage from "src/images/subscription/ecommerce-forms.png";
import influencerPaymentsImage from "src/images/subscription/ecommerce-influencer-payments.png";
import clientAccessToCampaignsImage from "src/images/subscription/ecommerce-client-access-to-campaigns.png";
import creatorContactsDatabaseImage from "src/images/subscription/ecommerce-creator-contacts-database.png";
import massMailingImage from "src/images/subscription/ecommerce-mass-mailing.png";
import dictionaryManagerImage from "src/images/subscription/ecommerce-dictionary-manager.png";

// ICONS
import commentSentimentAIIcon from "src/images/subscription/icons/comment-sentiment-ai.svg";
import couponsIntegrationIcon from "src/images/subscription/icons/coupons-integration.svg";
import formsIcon from "src/images/subscription/icons/forms.svg";
import influencerPaymentsIcon from "src/images/subscription/icons/influencer-payments.svg";
import clientAccessToCampaignsIcon from "src/images/subscription/icons/client-access-to-campaigns.svg";
import creatorContactsDatabaseIcon from "src/images/subscription/icons/creator-contacts-database.svg";
import massMailingIcon from "src/images/subscription/icons/mass-mailing.svg";
import dictionaryManagerIcon from "src/images/subscription/icons/dictionary-manager.svg";

import { SubscriptionEntitlements } from "../../subscriptionUtils";

export const getSubscriptionEntitlementSettings = (
  context: SubscriptionEntitlements,
) => {
  switch (context) {
    case SubscriptionEntitlements.PublicationSentiments:
      return {
        translation: {
          id: "ws_comment_sentiment_ai",
          defaultMessage: "Comment Sentiment AI",
        },
        image: commentSentimentAIImage,
        icon: commentSentimentAIIcon,
        text: {
          id: "ws_elevate_your_social_media_insights_and_influencer_marketing_strategies",
          defaultMessage:
            "Elevate your social media insights and influencer marketing strategies with comment sentiments AI.",
        },
        learnMoreUrl:
          "https://indahash.com/blog/sentiment-analysis-the-next-big-thing-in-influencer-marketing/",
      };

    case SubscriptionEntitlements.Forms:
      return {
        translation: {
          id: "ws_feature-forms",
          defaultMessage: "Intelligent Forms",
        },
        image: formsImage,
        icon: formsIcon,
        learnMoreUrl:
          "https://help.indahash.com/software/en/collections/9162446-smart-forms",
      };

    case SubscriptionEntitlements.CouponsIntegration:
      return {
        translation: {
          id: "ws_feature-e-commerce-coupons-integration",
          defaultMessage: "eCommerce Coupons",
        },
        image: couponsIntegrationImage,
        icon: couponsIntegrationIcon,
        learnMoreUrl:
          "https://help.indahash.com/software/en/collections/9162083-ecommerce-integrations",
      };

    case SubscriptionEntitlements.WsXtrmExtension:
      return {
        translation: {
          id: "ws_feature-influencer-payments",
          defaultMessage: "Influencer Payments",
        },
        image: influencerPaymentsImage,
        icon: influencerPaymentsIcon,
        learnMoreUrl:
          "https://indahash.com/blog/influencer-payments-made-easy-indahashs-new-partnership-with-global-payment-providers/",
      };

    case SubscriptionEntitlements.ClientMode:
      return {
        translation: {
          id: "ws_client_access_to_campaigns",
          defaultMessage: "Client Access to Campaigns",
        },
        icon: clientAccessToCampaignsIcon,
        image: clientAccessToCampaignsImage,
      };

    case SubscriptionEntitlements.ContactDataVisibility:
      return {
        translation: {
          id: "ws_creator_contacts_database",
          defaultMessage: "Creator Contacts Database",
        },
        icon: creatorContactsDatabaseIcon,
        image: creatorContactsDatabaseImage,
      };

    case SubscriptionEntitlements.WsMessengerExtension:
      return {
        translation: {
          id: "ws_mass_mailing",
          defaultMessage: "Mass mailing",
        },
        icon: massMailingIcon,
        image: massMailingImage,
        learnMoreUrl:
          "https://indahash.com/blog/email-multiple-creators-at-once-and-launch-campaigns-faster/",
      };

    case SubscriptionEntitlements.DictionaryManager:
      return {
        translation: {
          id: "ws_flex_menu_builder",
          defaultMessage: "Flex Menu Builder",
        },
        icon: dictionaryManagerIcon,
        image: dictionaryManagerImage,
      };
  }

  return {};
};
