import React, { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { customEqual } from "src/utils/methods";

interface Props {
  children: ReactNode;
}

function TranslationsProvider(props: Props) {
  const { children } = props;

  const translations = useSelector(
    (state: RootState) => state.mainReducer.translations,
    customEqual,
  );
  const locale = useSelector((state: RootState) => state.mainReducer.locale);

  return (
    <IntlProvider
      locale={locale.slice(0, 2)}
      key={locale}
      messages={translations}
    >
      {children}
    </IntlProvider>
  );
}

export default TranslationsProvider;
