import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import "./WorkspaceSetupTab.scss";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "src/app/components/CustomInput/CustomInput";
import { Button } from "src/app/components/Button/Button";
import { API_URLS } from "src/utils/API_URLS";
import axios from "axios";
import { showToast } from "src/app/methods/showToast";
import { identify } from "src/redux/main/mainActions";
import { RootState } from "src/redux/reducers";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { useCheckFeatureAccess } from "src/app/subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { setPlanUpgradeNeededModalContext } from "src/redux";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";
import { ReactComponent as CloseIcon } from "../../../../images/close.svg";
import { SettingsRowElement, SettingsSeparator } from "../../components";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";

interface Props {
  onClose: () => void;
}

export const WorkspaceSetupTab: React.FC<Props> = (props) => {
  const { onClose } = props;

  const {
    mainReducer: { activeWorkspaceUuid, identity },
  } = useSelector((state: RootState) => state);

  const { allowedEmailDomains } = identity;
  const dispatch = useDispatch();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [allowDomains, setAllowDomains] = useState<string[]>(
    allowedEmailDomains ?? [],
  );
  const [contactDataVisibilityEnabled, setContactDataVisibilityEnabled] =
    useState(identity?.contactDataVisibilityEnabled);
  const [
    contactDataVisibilityAcceptTerms,
    setContactDataVisibilityAcceptTerms,
  ] = useState(identity?.contactDataVisibilityEnabled);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const hasFeatureAccess = useCheckFeatureAccess(
    SubscriptionEntitlements.ContactDataVisibility,
  );

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };
  const handleInputKeyPress = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);

    const regex = /^[A-Za-z0-9\-.]+$/;

    if (!regex.test(keyValue)) {
      e.preventDefault();
    }

    if (
      (e.key === "Enter" || e.key === " " || e.keyCode === 32) &&
      inputValue.trim() !== ""
    ) {
      const regex = /^([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}$/;

      if (!regex.test(inputValue)) {
        showToast(
          "warning",
          <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />,
          <IDHFormattedMessage
            id="ws_allowed_email_domain_wrong_format"
            defaultMessage="Invalid format"
          />,
        );
        e.preventDefault();

        return;
      }

      setAllowDomains([...allowDomains, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleTokenRemove = (indexToRemove: any) => {
    const updatedAllowDomains = allowDomains.filter(
      (_, index) => index !== indexToRemove,
    );
    setAllowDomains(updatedAllowDomains);
  };

  const handleInputClick = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  const handleConfirm = () => {
    setLoading(true);

    axios
      .put(
        API_URLS.setWorkspaceSetting.replace(
          ":workspaceUuid:",
          activeWorkspaceUuid,
        ),
        {
          allowedEmailDomains: allowDomains,
          contactDataVisibilityEnabled,
        },
      )
      .then(() => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_general_settings_updated"
            defaultMessage="Workspace settings updated!"
          />,
        );
        dispatch(identify(activeWorkspaceUuid));
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong."
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (hasFeatureAccess) {
      setContactDataVisibilityAcceptTerms(e.target.checked);
    } else {
      dispatch(
        setPlanUpgradeNeededModalContext(
          SubscriptionEntitlements.ContactDataVisibility,
        ),
      );
    }
  };

  const handleSwitchChange = () => {
    if (hasFeatureAccess) {
      setContactDataVisibilityEnabled((v: boolean) => !v);
    } else {
      dispatch(
        setPlanUpgradeNeededModalContext(
          SubscriptionEntitlements.ContactDataVisibility,
        ),
      );
    }
  };

  return (
    <>
      <AnimatedDiv className="workspace-setup-tab">
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_workspace_setup_header"
            defaultMessage="Workspace Setup"
          />
        </ModalTitle>

        <div className="workspace-setup-tab__instructions">
          <div className="settings-row-element__left-title">
            <IDHFormattedMessage
              id="ws_allowed_email_domain_label"
              defaultMessage="Allowed e-mail domain"
            />
          </div>
          <CustomInput
            className="country-selector__input"
            placeholder={intl.formatMessage({
              id: "ws_allowed_email_domain_placeholder",
              defaultMessage: "Enter domain",
            })}
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            onClick={handleInputClick}
          />
          <div className="allow-domains-list">
            {allowDomains.map((token, index) => (
              <span key={index} className="allow-domains-item">
                {token}
                <button onClick={() => handleTokenRemove(index)}>
                  <CloseIcon />
                </button>
              </span>
            ))}
          </div>
        </div>

        <SettingsSeparator />

        <div className="settings-row-element">
          <div className="settings-row-element__left">
            <span className="settings-row-element__left-title">
              <IDHFormattedMessage
                id="ws_contact_data_visibility"
                defaultMessage="Contact data visiblity"
              />
            </span>

            <span className="settings-row-element__left-description">
              <CustomCheckbox
                id="checkBoxContactDataVisibilityAcceptTerms"
                name="checkBoxContactDataVisibilityAcceptTerms"
                disabled={contactDataVisibilityEnabled}
                checked={contactDataVisibilityAcceptTerms}
                onChange={handleCheckboxChange}
              />
              <IDHFormattedMessage
                id="ws_contact_data_visiblity_terms"
                defaultMessage="Terms & Conditions signed by the Client"
              />
            </span>
          </div>

          <div>
            <CustomSwitch
              checked={contactDataVisibilityEnabled}
              disabled={!contactDataVisibilityAcceptTerms}
              onChange={handleSwitchChange}
            />
          </div>
        </div>
      </AnimatedDiv>

      <SettingsButtons onClose={onClose}>
        <Button
          size="large"
          variant="blue"
          onClick={handleConfirm}
          disabled={loading}
        >
          <IDHFormattedMessage id="ws_update" defaultMessage="Update" />
        </Button>
      </SettingsButtons>
    </>
  );
};
