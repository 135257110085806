import React, { useState, useMemo, useEffect, useRef } from "react";

import axios from "axios";
import classNames from "classnames";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import OuterLink from "src/app/components/OuterLink/OuterLink";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { ReactComponent as ChevronUp } from "src/images/chevron-up-grey.svg";
import { ReactComponent as ChevronDown } from "src/images/chevron-down.svg";
import Loader from "src/app/components/Loader/Loader";
import { toLower } from "lodash";
import { ReactComponent as WarningIcon } from "../../../images/warning-circle.svg";
import { ReactComponent as IdhLogoBlue } from "../../../images/idh-logo-color.svg";
import { Button } from "../../components/Button/Button";
import CustomInput, {
  CustomInputLabel,
} from "../../components/CustomInput/CustomInput";
import { API_URLS } from "../../../utils/API_URLS";
import { EventHelper } from "../../../helpers/EventHelper";
import {
  getCountryOptions,
  getPhoneNumberPrefixBaseOnCountryCode,
  formatPhoneNumber,
} from "./utils";
import { showToast } from "../../methods/showToast";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import CredibilityToolExploringModal from "./CredibilityToolExploringModal";

const LOGIN_VIEW = "login";
const REGISTER_VIEW = "register";
const CONFIRM_VIEW = "confirm";

const PHONE_NUMBER_MIN_LENGTH = 4;

function RightSide() {
  const location = useLocation();
  const history = useHistory();
  const [person, setPerson] = useState({
    name: "",
    contactNumber: "",
    companyWebsite: "",
    email: "",
    yourPosition: "",
    phoneNumberPrefix: "",
  });
  const [showForm, setShowForm] = useState(true);
  const [existUser, setSetExistUser] = useState(false);
  const [currentRenderView, setCurrentRenderView] = useState(LOGIN_VIEW);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [showDataProcessingInfo, setShowDataProcessingInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [waitingForActivationError, setWaitingForActivationError] =
    useState(false);
  const [privatePolicyCheckVal, setPrivatePolicyCheckVal] = useState(false);
  const [marketingCheckVal, setMarketingCheckVal] = useState(false);
  const [showRuleWarning, setShowRuleWarning] = useState(false);
  const [countryCode, setCountryCode] = useState({
    label: "",
    value: "",
    code: "",
  });

  const intl = useIntl();
  const phoneNumberRef = useRef(null);

  const countryOptions = useMemo(() => getCountryOptions(), []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setShowRuleWarning(!checkRulesAccept());

    if (!checkRulesAccept()) {
      return;
    }

    const formData = new FormData();
    formData.append("email", toLower(person.email));
    formData.append("name", person.name);
    formData.append("website", person.companyWebsite);
    formData.append("country", countryCode.code);
    formData.append(
      "phone",
      `${person.phoneNumberPrefix} ${person.contactNumber}`,
    );
    formData.append("position", person.yourPosition);

    setEmailErrorMessage("");
    setEmailExistsError(false);
    setIsLoading(true);
    await axios
      .post(API_URLS.credibilityRegisterUser, formData)
      .then(function (response) {
        if (response.status === 200) {
          setShowForm(false);
          setCurrentRenderView(CONFIRM_VIEW);
          setWaitingForActivationError(false);
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong!"
            />,
          );
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status_code === 400 &&
          error.response.data?.content?.error
        ) {
          setShowForm(true);
          setEmailErrorMessage(error.response?.data?.content.error);

          if (
            error.response.data?.content?.error === "Email already registered"
          ) {
            setEmailExistsError(true);
          }

          setCurrentRenderView(REGISTER_VIEW);
          setWaitingForActivationError(false);
        } else {
          setShowForm(false);
          setCurrentRenderView(CONFIRM_VIEW);
          setWaitingForActivationError(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    window.scrollTo({ top: 0, behavior: "smooth" });

    EventHelper.emitEvent("ws:submitted.trial-registration-form");
  };

  const checkEmailAction = async (email) => {
    const formData = new FormData();

    formData.append("email", email);

    if (!email) {
      return;
    }

    setIsLoading(true);
    await axios
      .post(API_URLS.credibilityCheckEmail, formData)
      .then(function (response) {
        const existsValue = response.data.content.exists ?? false;

        setSetExistUser(existsValue);
        if (existsValue) {
          setShowForm(false);
          setCurrentRenderView(CONFIRM_VIEW);
        } else {
          setShowForm(true);
          setCurrentRenderView(REGISTER_VIEW);
        }
      })
      .catch((error) => {
        if (
          error.response?.data?.statusCode === 404 ||
          !error.response?.data?.exists
        ) {
          setCurrentRenderView(REGISTER_VIEW);
          setShowForm(true);
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong!"
            />,
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    setWaitingForActivationError(false);
    window.scrollTo({ top: 0, behavior: "smooth" });

    EventHelper.emitEvent("ws:submitted.trial-registration-form");
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    checkEmailAction(person.email);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryEmail = params.get("email");
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]{2,}$/i;

    if (queryEmail) {
      setPerson({
        ...person,
        email: queryEmail,
      });

      if (regex.test(queryEmail)) {
        checkEmailAction(queryEmail);
      }
    }
  }, [location.search, history]);

  const handleChange = (e) => {
    let newValue = e.target.value;

    if (e.target.name === "contactNumber") {
      if (person.phoneNumberPrefix) {
        newValue = newValue.slice(person.phoneNumberPrefix.length).trim();
      }
      newValue = formatPhoneNumber(newValue);
    }

    setPerson((prev) => ({
      ...prev,
      [e.target.name]: newValue,
    }));
  };

  const checkRulesAccept = () => {
    if (privatePolicyCheckVal && marketingCheckVal) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!countryCode.code) return;
    setPerson((prev) => ({
      ...prev,
      phoneNumberPrefix: getPhoneNumberPrefixBaseOnCountryCode(
        countryCode.code,
      ),
    }));
  }, [countryCode.code]);

  useEffect(() => {
    if (!phoneNumberRef.current) return;
    if (person.phoneNumberPrefix) {
      if (
        person.contactNumber.split(" ").join("").length <
        PHONE_NUMBER_MIN_LENGTH
      ) {
        phoneNumberRef.current.setCustomValidity(
          translateMessage({
            intl,
            id: "ws_phone_number_validation",
            defaultMessage: `Phone number must have at least ${PHONE_NUMBER_MIN_LENGTH} characters.`,
          }),
        );
      } else {
        phoneNumberRef.current.setCustomValidity("");
      }
    }
  }, [person.contactNumber, person.phoneNumberPrefix]);

  const renderView = () => {
    if (currentRenderView === LOGIN_VIEW) {
      return (
        <>
          <div className="credibility-tool-page-right__header">
            <IDHFormattedMessage
              id="ws_credibility_tool_register_header"
              defaultMessage="Try Credibility Checker Tool for 20 accounts for free!"
            />
          </div>
          <div className="credibility-tool-page-right__description">
            <IDHFormattedMessage
              id="ws_credibility_tool_register_header_description"
              defaultMessage="Work with creators who have a credible audience and show no signs of follower fraud."
            />
          </div>
          <form onSubmit={handleLoginSubmit}>
            <CustomInputLabel htmlFor="email" aboveInput>
              <IDHFormattedMessage
                id="ws_business_email"
                defaultMessage="Business Email"
              />
            </CustomInputLabel>
            <CustomInput
              name="email"
              id="email"
              value={person.email}
              onChange={handleChange}
              required
              type="email"
              placeholder="jane.doe@company.com"
              fullWidth
            />
            <div className="credibility-tool-page-right__submit-wrapper">
              {isLoading ? (
                <Loader />
              ) : (
                <Button
                  size="large"
                  variant="blue"
                  className="credibility-tool-page-right__btn"
                >
                  <IDHFormattedMessage
                    id="ws_continue"
                    defaultMessage="Continue"
                  />
                </Button>
              )}
            </div>
          </form>
        </>
      );
    }
    return (
      <>
        {showForm ? (
          <>
            <div className="credibility-tool-page-right__header">
              <IDHFormattedMessage
                id="ws_credibility_tool_register_header"
                defaultMessage="Try Credibility Checker Tool for 20 accounts for free!"
              />
            </div>
            <div className="credibility-tool-page-right__description">
              <IDHFormattedMessage
                id="ws_credibility_tool_register_header_description"
                defaultMessage="Work with creators who have a credible audience and show no signs of follower fraud."
              />
            </div>
            <form onSubmit={handleSubmit}>
              <CustomInputLabel htmlFor="email" aboveInput>
                <IDHFormattedMessage
                  id="ws_business_email"
                  defaultMessage="Business Email"
                />
              </CustomInputLabel>
              <CustomInput
                name="email"
                id="email"
                value={person.email}
                onChange={handleChange}
                required
                type="email"
                placeholder="jane.doe@company.com"
                fullWidth
              />
              <CustomInputLabel aboveInput htmlFor="name">
                <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
              </CustomInputLabel>
              <CustomInput
                name="name"
                id="name"
                value={person.name}
                onChange={handleChange}
                required
                type="text"
                placeholder="Jane Doe"
                fullWidth
              />
              <CustomInputLabel aboveInput htmlFor="companyWebsite">
                <IDHFormattedMessage
                  id="ws_company_website"
                  defaultMessage="Company website"
                />
              </CustomInputLabel>
              <CustomInput
                name="companyWebsite"
                id="companyWebsite"
                value={person.companyWebsite}
                onChange={handleChange}
                required
                placeholder="www.company.com"
                pattern="[^ ]+\.[A-Za-z0-9]+"
                size="30"
                type="text"
                fullWidth
              />
              <div className="credibility-tool-page-right__row">
                <div className="credibility-tool-page-right__column">
                  <CustomInputLabel aboveInput>
                    <IDHFormattedMessage
                      id="ws_country_code"
                      defaultMessage="Country code"
                    />
                  </CustomInputLabel>
                  <CustomSelect
                    id="ws-country-code"
                    value={countryCode}
                    options={countryOptions}
                    placeholder={`${translateMessage({
                      intl,
                      id: "ws_select_country",
                      defaultMessage: "Select country",
                    })}...`}
                    onChange={setCountryCode}
                    isSearchable
                    required
                  />
                </div>
                <div className="credibility-tool-page-right__column">
                  <CustomInputLabel aboveInput htmlFor="contactNumber">
                    <IDHFormattedMessage
                      id="ws_contact_number"
                      defaultMessage="Contact number"
                    />
                  </CustomInputLabel>
                  <CustomInput
                    name="contactNumber"
                    id="contactNumber"
                    value={
                      person.phoneNumberPrefix
                        ? `${person.phoneNumberPrefix} ${person.contactNumber}`
                        : person.contactNumber
                    }
                    onChange={handleChange}
                    ref={phoneNumberRef}
                    required
                    type="tel"
                    maxLength={22}
                    fullWidth
                    placeholder="e.g. 999-999-9999"
                  />
                </div>
              </div>
              <CustomInputLabel htmlFor="yourPosition" aboveInput>
                <IDHFormattedMessage
                  id="ws_your_position"
                  defaultMessage="Your position"
                />
              </CustomInputLabel>
              <CustomInput
                name="yourPosition"
                id="yourPosition"
                value={person.yourPosition}
                onChange={handleChange}
                required
                type="text"
                fullWidth
                placeholder="CMO"
              />
              {emailErrorMessage && (
                <>
                  <div className="credibility-tool-page-right__invalid-email-error">
                    <WarningIcon className="login-page-right__invalid-email-error-icon" />
                    <span
                      dangerouslySetInnerHTML={{ __html: emailErrorMessage }}
                    />
                    &nbsp;
                  </div>
                  {emailExistsError && (
                    <div className="credibility-tool-page-right__sign-in">
                      <span>
                        <Link onClick={() => setCurrentRenderView(LOGIN_VIEW)}>
                          <IDHFormattedMessage
                            id="ws_sign_in"
                            defaultMessage="Sign in"
                          />
                        </Link>
                        &nbsp;
                        <IDHFormattedMessage
                          id="ws_instead"
                          defaultMessage="instead"
                        />
                        .
                      </span>
                    </div>
                  )}
                </>
              )}

              <div className="credibility-tool-page-right__disclaimer">
                <CustomCheckbox
                  id="legal-rule-1-checkbox"
                  name="legal-rule-1-checkbox"
                  label={translateMessage({
                    intl,
                    id: "ws_credibility_tool_legal_rule_1",
                    defaultMessage:
                      "I hereby consent to the processing of my personal data by the Co-controllers: IDH Media Limited with its registered office at 22 Northumberland Road, Ballsbridge, Dublin 4, Ireland and IDH Media S.A. in with its registered office at 19 Piękna Street, 00-549 Warsaw, Poland for the purposes of granting the access to a test version of the Free Credibility Check Tool.",
                  })}
                  onChange={() => setPrivatePolicyCheckVal((prev) => !prev)}
                  checked={privatePolicyCheckVal}
                />
                <CustomCheckbox
                  id="legal-rule-2-checkbox"
                  name="legal-rule-2-checkbox"
                  label={translateMessage({
                    intl,
                    id: "ws_credibility_tool_legal_rule_2",
                    defaultMessage:
                      "I hereby consent to the processing of my personal data for marketing purposes by the co-controllers: IDH Media Limited with its registered office at 22 Northumberland Road, Ballsbridge, Dublin 4, Ireland and IDH Media S.A. in with its registered office at 19 Piękna Street, 00-549 Warsaw, Poland and to receive commercial information by electronic means in accordance with the Act of 18th of July 2002 on the provision of services by electronic means (i.e. Dz.U. z 2020 r., poz. 344).",
                  })}
                  onChange={() => setMarketingCheckVal((prev) => !prev)}
                  checked={marketingCheckVal}
                />
                {showRuleWarning && (
                  <div className="credibility-tool-page-right__invalid-email-error">
                    <WarningIcon className="login-page-right__invalid-email-error-icon" />
                    <IDHFormattedMessage
                      id="ws_creadibility_tool_required_rules"
                      defaultMessage="Before submitting, please ensure that you have accept our rules"
                    />
                    &nbsp;
                  </div>
                )}
                <div className="credibility-tool-page-right__data-processing-info">
                  <div
                    className="credibility-tool-page-right__data-processing-info__header"
                    onClick={() =>
                      setShowDataProcessingInfo(!showDataProcessingInfo)
                    }
                  >
                    <IDHFormattedMessage
                      id="ws_creadibility_tool_learn_more_about_data_processing"
                      defaultMessage="Learn more about data processing"
                    />
                    {showDataProcessingInfo ? <ChevronUp /> : <ChevronDown />}
                  </div>
                  {showDataProcessingInfo && (
                    <div className="credibility-tool-page-right__data-processing-info__content">
                      <IDHFormattedMessage
                        id="ws_creadibility_tool_learn_more_about_data_processing_info_1"
                        defaultMessage="The Co-controllers of your personal data are: IDH Media Limited with its registered office at 22 Northumberland Road, Ballsbridge, Dublin 4, Ireland and IDH Media S.A. in with its registered office at 19 Piękna Street, 00-549 Warsaw, Poland (hereunder referred to as “we”, “us”, “Co-controllers”)."
                      />
                      <br />
                      <br />
                      <IDHFormattedMessage
                        id="ws_creadibility_tool_learn_more_about_data_processing_info_2"
                        defaultMessage="With regards to the data processing matters, you may contact the Data Protection Officer: via email: "
                      />
                      &nbsp;
                      <a href="mailto:dpo@indahash.com">dpo@indahash.com</a>
                      &nbsp;
                      <IDHFormattedMessage
                        id="ws_creadibility_tool_learn_more_about_data_processing_info_3"
                        defaultMessage=";   or via letter: indaHash, 19 Piękna Street, 00-549 Warsaw, Poland – with the annotation “Data Protection Officer”."
                      />
                      &nbsp;
                      <br />
                      <br />
                      <IDHFormattedMessage
                        id="ws_creadibility_tool_learn_more_about_data_processing_info_4"
                        defaultMessage="To familiarise yourself with your rights and obligations in relation to the processing of your personal data by Co-controllers, please read: "
                      />
                      <OuterLink href="https://docs.indahash.com/doc/?n=privacy-policy">
                        &nbsp; https://docs.indahash.com/doc/?n=privacy-policy
                      </OuterLink>
                    </div>
                  )}
                </div>
              </div>
              <div className="credibility-tool-page-right__submit-wrapper">
                {isLoading ? (
                  <Loader />
                ) : (
                  <Button
                    size="large"
                    variant="blue"
                    className="credibility-tool-page-right__btn"
                  >
                    <IDHFormattedMessage
                      id="ws_continue"
                      defaultMessage="Continue"
                    />
                  </Button>
                )}
              </div>
            </form>
          </>
        ) : (
          <div className="credibility-tool-page-right__confirm-wrapper">
            <div className="credibility-tool-page-right__header credibility-tool-page-right__header--confirm">
              <IDHFormattedMessage
                id="ws_confirm_your_email"
                defaultMessage="Confirm your email"
              />
            </div>
            <div className="credibility-tool-page-right__box">
              <div className="credibility-tool-page-right__box-right">
                <div className="credibility-tool-page-right__box-header-text">
                  <IDHFormattedMessage
                    id="ws_trial_right_registration_header"
                    defaultMessage="Registration"
                  />
                </div>
                <div>
                  <div className="credibility-tool-page-right__text">
                    <IDHFormattedMessage
                      id="ws_we_have_sent_a_confirmation_to_the_provided_address"
                      defaultMessage="We have sent a confirmation to the provided address:"
                    />
                  </div>
                  <div className="credibility-tool-page-right__email-address">
                    {person?.email && person?.email != ""
                      ? person?.email
                      : "---"}
                  </div>
                  <div className="credibility-tool-page-right__try-again">
                    <IDHFormattedMessage
                      id="ws_if_you_havent_received_an_email_after_a_minute"
                      defaultMessage="If you haven’t received an email after a minute, "
                    />

                    <span
                      onClick={() => {
                        if (existUser) {
                          setCurrentRenderView(LOGIN_VIEW);
                        } else {
                          setShowForm(true);
                          setCurrentRenderView(REGISTER_VIEW);
                        }
                      }}
                      className="credibility-tool-page-right__try-again-link"
                    >
                      <IDHFormattedMessage
                        id="ws_click_here_to_try_again"
                        defaultMessage="Click here to try again"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="credibility-tool-page-right">
      {/* currentRenderView === LOGIN_VIEW && <CredibilityToolExploringModal /> */}
      <div className="credibility-tool-page-right__wrapper">
        <div
          className={classNames("credibility-tool-page-right__container", {
            "credibility-tool-page-right__container--confirm":
              !showForm && currentRenderView === CONFIRM_VIEW,
          })}
        >
          <div className="credibility-tool-page-right__container-logo">
            <IdhLogoBlue />
          </div>
          {renderView()}
        </div>
      </div>
    </div>
  );
}

export { RightSide };
