import React from "react";
import "./SubscriptionStylingProvider.scss";
import classNames from "classnames";

type IndaHashMode = "default" | "indahash";
type UsageMode = "default" | "vertical-list";

interface Props {
  children: React.ReactNode;
  sectionStylingModes?: {
    subscriptionsOverview?: IndaHashMode;
    usage?: UsageMode;
    addons?: string;
    promotionalEntitlements?: string;
    billingInformation?: string;
    paymentDetails?: IndaHashMode;
    invoices?: string;

    paywall?: IndaHashMode;
    checkout?: IndaHashMode;
  };
}

function SubscriptionStylingProvider(props: Props) {
  const { children, sectionStylingModes } = props;

  const getStylesForSections = () => {
    if (!sectionStylingModes) return [];

    const classes: string[] = [];

    Object.entries(sectionStylingModes).forEach((item) => {
      const [key, values] = item;
      classes.push(`ws-subscription-${key}--${values}`);
    });

    return classes;
  };

  return (
    <div className={classNames(...getStylesForSections())}>{children}</div>
  );
}

export default SubscriptionStylingProvider;
