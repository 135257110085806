"use no memo";

import React, { useEffect, useState } from "react";
import "./ShowcaseTopBar.scss";

import { useDispatch, useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "src/app/components/Tabs/Tab";
import { useHistory } from "react-router";

import { ReactComponent as DownloadIcon } from "../../../../images/download-alt.svg";
import { ReactComponent as CrossIcon } from "../../../../images/cross.svg";
import { Button } from "../../../components/Button/Button";
import { setMultipleLayouts, setPrinting } from "../../../../redux";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { getShowcaseSettings, updateModeParam } from "../../utils";

export function ShowcaseTopBar({ data, closeShowcase }) {
  const [eventListenerFired, setEventListenerFired] = useState(false);

  const layout = useSelector((state) => state.showcaseReducer.layout);
  const generating = useSelector((state) => state.showcaseReducer.generating);
  const printing = useSelector((state) => state.showcaseReducer.printing);
  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );
  const multipleLayouts = useSelector(
    (state) => state.showcaseReducer.multipleLayouts,
  );

  const loading = generating || printing;

  const history = useHistory();
  const dispatch = useDispatch();

  const { creatorId, ids, tab } = getShowcaseSettings();
  const { wsProjectUuid, listUuid } = showcaseData?.contextData || {};
  const { elements } = showcaseData;

  const generateShowcase = () => {
    const newMultipleLayouts = multipleLayouts.map((item) => {
      if (item.creatorId === creatorId) {
        return {
          ...item,
          layout,
        };
      }

      return item;
    });

    dispatch(setMultipleLayouts(newMultipleLayouts));
    dispatch(setPrinting(true));
  };

  useEffect(() => {
    // Add a fake history event so that the back button does nothing if pressed once
    // window.history.pushState(
    //   "fake-route",
    //   document.title,
    //   window.location.href,
    // );

    return () => {
      removeEventListener("popstate", closeShowcase);
      // If we left without using the back button, aka by using a button on the page, we need to clear out that fake history event
    };
  }, []);

  useEffect(() => {
    if (elements?.length && !eventListenerFired) {
      addEventListener("popstate", closeShowcase);
      setEventListenerFired(true);
    }
  }, [elements, eventListenerFired, wsProjectUuid, listUuid]);

  const handleClickGenerateShowcase = () => {
    updateModeParam(history, "tab", "creators");
    generateShowcase();
  };

  return (
    <div className="showcase-topbar">
      <div className="showcase-topbar__left">
        {loading ? (
          <Skeleton width={310} height={42} borderRadius={8} marginRight={44} />
        ) : (
          <div className="showcase-topbar__left-tabs">
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_cover" defaultMessage="Cover" />
              }
              active={tab === "cover"}
              onClick={() => updateModeParam(history, "tab", "cover")}
              big
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_creators"
                  defaultMessage="Creators"
                />
              }
              active={tab === "creators"}
              onClick={() => updateModeParam(history, "tab", "creators")}
              count={ids?.length}
              big
            />
          </div>
        )}
      </div>

      <div className="showcase-topbar__right">
        <Button
          size="medium"
          variant="white"
          disabled={generating}
          onClick={closeShowcase}
        >
          <CrossIcon />
          <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
        </Button>

        <Button
          size="medium"
          variant="blue"
          disabled={loading}
          onClick={handleClickGenerateShowcase}
        >
          <DownloadIcon />
          <IDHFormattedMessage
            id="ws_download_showcase"
            defaultMessage="Download showcase"
          />
        </Button>
      </div>
    </div>
  );
}
