import React from "react";
import "./PleaseContactAdminPrompt.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";

function PleaseContactAdminPrompt() {
  const { identity } = useSelector((state: RootState) => state.mainReducer);

  const { subscriptionData } = identity || {};

  if (
    !subscriptionData?.bdWsMemberEmail ||
    identity.permissions.workspace.includes("subscription_management")
  ) {
    return null;
  }

  return (
    <div className="please-contact-admin-prompt">
      <IDHFormattedMessage
        id="ws_please_contact_your_workspace_admin_to_upgrade_plan_and_increase_your_limits"
        defaultMessage="Please contact your workspace admin to upgrade plan and increase your limits"
      />
      :
      <span className="contact-support-modal__email">
        &nbsp;
        <a href={`mailto:${subscriptionData?.bdWsMemberEmail}`}>
          {subscriptionData?.bdWsMemberEmail || "asd"}
        </a>
      </span>
    </div>
  );
}

export default PleaseContactAdminPrompt;
