"use no memo";

import React, { useMemo } from "react";
import "./ShowcaseCoverTab.scss";

import { useSelector } from "react-redux";
import { getShowcaseSettings } from "src/app/CreatorShowcase/utils";
import { CoverFooter } from "./components/CoverFooter/CoverFooter";
import { CoverTop } from "./components/CoverTop/CoverTop";
import CoverPlaceholder from "./components/CoverPlaceholder/CoverPlaceholder";
import { CoverSummary } from "./components/CoverSummary/CoverSummary";

export function ShowcaseCoverTab(props) {
  const { renderCreators } = props;

  const { workspaceId } = getShowcaseSettings();

  const summaryPlacement = useSelector(
    (state) => state.showcaseReducer.summaryPlacement,
  );
  const workspacesList = useSelector(
    (state) => state.mainReducer.workspacesList,
  );
  const workspaceData = useMemo(
    () => workspacesList?.find((workspace) => workspace.uuid === workspaceId),
    [workspacesList, workspaceId],
  );

  return (
    <div>
      <CoverTop renderCreators={renderCreators} workspaceData={workspaceData} />
      {summaryPlacement.value === "top" && (
        <CoverSummary workspaceData={workspaceData} />
      )}
      <CoverPlaceholder workspaceData={workspaceData} />
      {summaryPlacement.value === "bottom" && (
        <CoverSummary workspaceData={workspaceData} />
      )}
      <CoverFooter
        renderCreators={renderCreators}
        workspaceData={workspaceData}
      />
    </div>
  );
}
