import {
  CustomerPortalProvider,
  StiggProvider,
  useStiggContext,
} from "@stigg/react-sdk";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionPlanData } from "src/app/subscription/subscriptionFunctions";
import { setStiggContextValue } from "src/redux";
import { RootState } from "src/redux/reducers";

interface Props {
  children: ReactNode;
}

function SubscriptionProvider(props: Props) {
  const { children } = props;

  const { identity } = useSelector((state: RootState) => state.mainReducer);

  const { subscriptionData } = identity || {};

  if (!subscriptionData) return <>{children}</>;

  return (
    <StiggProvider
      apiKey={subscriptionData?.clientApiKey}
      customerId={subscriptionData?.customerId}
    >
      <PlanGetter>
        <CustomerPortalProvider>{children}</CustomerPortalProvider>
      </PlanGetter>
    </StiggProvider>
  );
}

export default SubscriptionProvider;

interface PlanGetterProps {
  children: ReactNode;
}

function PlanGetter(props: PlanGetterProps) {
  const { children } = props;

  const dispatch = useDispatch();
  const stiggContextValue = useStiggContext();

  const { stigg, updatedAt } = stiggContextValue;

  useEffect(() => {
    dispatch(setStiggContextValue(stiggContextValue));
  }, [stiggContextValue]);

  useEffect(() => {
    getSubscriptionPlanData(dispatch, stigg);
  }, [stigg, updatedAt]);

  return <>{children}</>;
}
