export enum SubscriptionEntitlements {
  // Type: Meter
  RegionsLimit = "feature-creator-search-regions-limit",
  CreatorDiscoveryToolSearcherLimit = "feature-creator-discovery-monthly-searches",
  ProjectsLimit = "feature-campaigns",
  TasksLimit = "feature-objects",
  MembersLimit = "feature-seats",

  // Type: Bool
  CouponsIntegration = "feature-e-commerce-coupons-integration",
  ContactDataVisibility = "feature-creator-contact-data-visibility",
  WsMessengerExtension = "feature-creator-messenger",
  PublicationSentiments = "feature-sentiments",
  Forms = "feature-forms",
  ClientMode = "feature-client-mode",
  WsXtrmExtension = "feature-influencer-payments",
  DictionaryManager = "feature-flex-fields",
}
