import {
  BillableFeature,
  BillingPeriod,
  Customer,
  Paywall,
  Plan,
  SubscribeIntentionType,
  Subscription,
} from "@stigg/react-sdk";
import React from "react";
import "./ManageSubscriptionModal.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, {
  ModalScroller,
  ModalTitle,
} from "src/app/components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import {
  setManageSubscriptionModalVisibility,
  setSelectedSubscriptionPlanData,
} from "src/redux/subscription/subscriptionActions";
import SubscriptionStylingProvider from "../../components/SubscriptionStylingProvider/SubscriptionStylingProvider";

export interface SubscriptionPlanChangeData {
  plan: Plan;
  customer: Customer | null;
  subscription: Subscription | null;
  intentionType: SubscribeIntentionType;
  selectedBillingPeriod: BillingPeriod;
  billableFeatures?: BillableFeature[];
}

interface Props {
  onClose: () => void;
}

function ManageSubscriptionModal(props: Props) {
  const { onClose } = props;

  const { identity } = useSelector((state: RootState) => state.mainReducer);

  const { subscriptionData } = identity;

  const dispatch = useDispatch();

  const openCheckoutModal = (planData: SubscriptionPlanChangeData) => {
    dispatch(setManageSubscriptionModalVisibility(true));
    dispatch(setSelectedSubscriptionPlanData(planData));
  };

  const onPlanSelected = (props: SubscriptionPlanChangeData) => {
    const { intentionType } = props;

    switch (intentionType) {
      // case SubscribeIntentionType.START_TRIAL:
      // TODO: provision trial subscription
      // break;

      case SubscribeIntentionType.REQUEST_CUSTOM_PLAN_ACCESS:
        if (subscriptionData) {
          window.location.href = `mailto:${subscriptionData?.bdWsMemberEmail}`;
        }
        break;

      case SubscribeIntentionType.UPGRADE_PLAN:
      case SubscribeIntentionType.DOWNGRADE_PLAN:
        openCheckoutModal(props);
        break;
    }
  };

  return (
    <Modal
      className="manage-subscription-modal"
      onClose={onClose}
      closeButtonText={
        <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
      }
      displayCancelButton
      closeButtonVariant="white-with-border"
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_plans" defaultMessage="Plans" />
      </ModalTitle>

      <ModalScroller maxHeight={647}>
        <SubscriptionStylingProvider
          sectionStylingModes={{ paywall: "indahash" }}
        >
          <Paywall onPlanSelected={onPlanSelected} />
        </SubscriptionStylingProvider>
      </ModalScroller>
    </Modal>
  );
}

export default ManageSubscriptionModal;
