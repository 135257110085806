import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./SetAsRoleDropdown.scss";

import classNames from "classnames";

import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { DropdownPortal } from "src/app/components/DropdownPortal/DropdownPortal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { DropdownMenuPortal } from "src/app/components/DropdownMenuPortal/DropdownMenuPortal";
import { showToast } from "src/app/methods/showToast";
import { useCheckFeatureAccess } from "src/app/subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { SettingsContext } from "../../SettingsModal/enums";
import { ReactComponent as TickBlueIcon } from "../../../images/tick-blue.svg";
import { ReactComponent as ChevronDownIcon } from "../../../images/chevron-down-alt.svg";
import { API_URLS } from "../../../utils/API_URLS";
import {
  getMembersList,
  setPlanUpgradeNeededModalContext,
} from "../../../redux";

function SetAsRoleDropdown(props) {
  const {
    wsMemberUuid,
    memberRole,
    userId,
    projectId,
    objectType,
    disabled,
    getData,
  } = props;

  const dispatch = useDispatch();
  const [role, setRole] = useState(
    memberRole === "role::project_operator"
      ? "member"
      : memberRole === "role::project_admin"
        ? "admin"
        : memberRole === "role::workspace_supervisor"
          ? "supervisor"
          : memberRole === "role::workspace_operator"
            ? "Member"
            : memberRole === "role::workspace_guest"
              ? "guest"
              : "client",
  );
  const [isOpen, setIsOpen] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid, identity },
  } = useSelector((state) => state);

  const hasFeatureAccess = useCheckFeatureAccess(
    SubscriptionEntitlements.ClientMode,
  );

  const setAsRoleForWorkspace = (oldRole, newRole) => {
    axios
      .post(API_URLS.setWorkspaceRole, {
        wsMemberUuid,
        wsWorkspaceUuid: activeWorkspaceUuid,
        role: newRole,
      })
      .then(() => {
        getData();
        dispatch(getMembersList(activeWorkspaceUuid));
      })
      .catch((error) => {
        console.error(error);
        setRole(oldRole);
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_update_role"
            defaultMessage="Could not update role!"
          />,
        );
      });
  };

  const setAsRole = (oldRole, newRole) => {
    const setAsRoleUrl =
      newRole === "admin"
        ? API_URLS.setAsAdmin
        : newRole === "client"
          ? API_URLS.setAsClient
          : API_URLS.setAsMember;
    axios
      .post(setAsRoleUrl, {
        wsMemberUuid,
        wsProjectUuid: projectId,
      })
      .then(() => {
        getData();
      })
      .catch((error) => {
        console.error(error);
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_update_role"
            defaultMessage="Could not update role!"
          />,
        );
        setRole(oldRole);
      });
  };

  const setAs = (oldRole, newRole) => {
    setRole(newRole);
    if (objectType === SettingsContext.Workspace) {
      setAsRoleForWorkspace(oldRole, newRole);
    } else {
      setAsRole(oldRole, newRole);
    }
  };

  const handleSetRole = (newRole) => {
    const featureLimitedRoles = ["client", "guest"];

    if (featureLimitedRoles.includes(newRole) && !hasFeatureAccess) {
      dispatch(
        setPlanUpgradeNeededModalContext(SubscriptionEntitlements.ClientMode),
      );
    } else {
      setAs(role, newRole);
    }
    setIsOpen(false);
  };

  const menu = isOpen ? (
    <DropdownMenuPortal
      scrollableContainerIdentifier=".share-modal__user-list"
      setIsOpen={setIsOpen}
      hideOnScroll
    >
      <DropdownMenu isOpen>
        {objectType !== SettingsContext.Workspace && (
          <>
            <DropdownMenuItem onClick={() => handleSetRole("admin")}>
              <IDHFormattedMessage id="ws_admin" defaultMessage="Admin" />
              {role === "admin" && (
                <TickBlueIcon className="dropdown-set-as-role-dropdown__tick-blue" />
              )}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleSetRole("member")}>
              <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
              {role === "member" && (
                <TickBlueIcon className="dropdown-set-as-role-dropdown__tick-blue" />
              )}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleSetRole("client")}>
              <IDHFormattedMessage id="ws_client" defaultMessage="Client" />

              {role === "client" && (
                <TickBlueIcon className="dropdown-set-as-role-dropdown__tick-blue" />
              )}
            </DropdownMenuItem>
          </>
        )}
        {objectType === SettingsContext.Workspace && (
          <>
            <DropdownMenuItem onClick={() => handleSetRole("supervisor")}>
              <IDHFormattedMessage
                id="ws_supervisor"
                defaultMessage="Supervisor"
              />
              {role === "supervisor" && (
                <TickBlueIcon className="dropdown-set-as-role-dropdown__tick-blue" />
              )}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleSetRole("member")}>
              <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
              {role === "member" && (
                <TickBlueIcon className="dropdown-set-as-role-dropdown__tick-blue" />
              )}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleSetRole("guest")}>
              <IDHFormattedMessage id="ws_guest" defaultMessage="Guest" />
              {role === "guest" && (
                <TickBlueIcon className="dropdown-set-as-role-dropdown__tick-blue" />
              )}
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenu>
    </DropdownMenuPortal>
  ) : null;

  const isDisabled = identity.id === wsMemberUuid || disabled;

  return (
    <DropdownPortal overlay={menu} disabled={isDisabled} visible={isOpen}>
      <div
        className={classNames("dropdown-set-as-role-dropdown__button", {
          "dropdown-set-as-role-dropdown__button--disabled": isDisabled,
        })}
        onClick={() => !isDisabled && setIsOpen(true)}
      >
        <IDHFormattedMessage
          id={`ws_${role.toLowerCase()}`}
          defaultMessage={role}
        />
        <ChevronDownIcon className="dropdown-set-as-role-dropdown__chevron-down" />
      </div>
    </DropdownPortal>
  );
}

export { SetAsRoleDropdown };
