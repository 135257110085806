import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import {API_URLS} from "../../utils/API_URLS";

function ConfirmRegistrationPage() {
  const { search } = useLocation();

  const [loading, setIsLoading] = useState<boolean>(true);
  const [isPaidSubscriptionPlan, setIsPaidSubscriptionPlan] = useState<boolean>(false);
  const [creatingWorkspaceInProgress, setCreatingWorkspaceInProgress] = useState<boolean>(false);
  const [workspaceUuidReady, setWorkspaceUuidReady] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const workspaceData = params.get("workspaceData") ?? null;

    try {
      setIsLoading(true);
      const data = {
        workspaceData: workspaceData
      };
      axios.post(API_URLS.registerAndConfirmEmail, data).then((res) => {
        //TODO
        if (res.data.content.isPaidSubscriptionPlan) {
          setIsPaidSubscriptionPlan(true);
          setCreatingWorkspaceInProgress(true);
        } else {
          setIsPaidSubscriptionPlan(false);
        }

      });
    } catch (err) {
      //TODO
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {


    if (isPaidSubscriptionPlan) {
      setInterval(() => {
        try {
          const params = new URLSearchParams(search);
          const workspaceData = params.get("workspaceData") ?? null;

          const data = {
            workspaceData: workspaceData
          };
          axios.post(API_URLS.isWorkspaceReady, data).then((res) => {


            if (res.data.content.ready) {
              setWorkspaceUuidReady(res.data.content.magicLink);
            }

            console.log('checking', res.data.content.ready);

          });
        } catch (err) {
          //TODO
        } finally {

        }
      }, 5000);
    }




  }, [isPaidSubscriptionPlan]);



  if (loading) {
    return (
      <div>Loading...</div>
    )
  }

  if (workspaceUuidReady) {
    return (
      <div className="trial-view">
        <div>super! klykaj: <a href={workspaceUuidReady}> klykaj</a>{workspaceUuidReady}</div>
      </div>
    )
  }

  if (isPaidSubscriptionPlan && creatingWorkspaceInProgress) {
    return (
      <div className="trial-view">
      <div>
        Małe bobry właśnie budują Twojego workspace! Czekaj az skończą! <br />

        <img src="https://tagme.test/build.gif" width="200" height="200" />
      </div>
      </div>
    )
  }


  return  (
    <div className="trial-view">
     <div>Your email address has been successfully confirmed. <br /><br />
       Please be patient. You’ll receive an activation email confirmation (within up to 2 hours), after which you can start using the platform.
       Please check your email. You should receive a link to schedule a demo call.</div>
    </div>
  );
}

export { ConfirmRegistrationPage };
