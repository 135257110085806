import { hot } from "react-hot-loader/root";
import React from "react";
import axios from "axios";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import smoothscroll from "smoothscroll-polyfill";
import "./styles/workspace.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getWorkspaceUuidFromCurrentUrl, urlIncludes } from "./utils/methods";
import App from "./App";
import rootReducer from "./redux/reducers";
import { getShowcaseSettingByKey } from "./app/CreatorShowcase/utils";
import { AppConfig } from "./config/AppConfig";
import subscriptionErrorsMiddleware from "./app/subscription/subscriptionMiddleware";

// smoothscroll was added to support scroll to element on safari
smoothscroll.polyfill();

const getWorkspaceUuid = () => {
  if (urlIncludes("/creator-showcase")) {
    return getShowcaseSettingByKey("workspaceId");
  }

  return getWorkspaceUuidFromCurrentUrl();
};

const wsWorkspaceUuid = getWorkspaceUuid();

const client = axios.create({
  baseURL: AppConfig.getApiHost(),
  responseType: "json",
  headers: {
    "Access-Control-Allow-Credential": true,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    "Requester-App": "workspace",
    "Workspace-Uuid": wsWorkspaceUuid,
  },
  withCredentials: true, // this is necessary for local development because app is operating under different origin than the API (different port), so we need to explicitly state that credentials (including cookies) are sent with the requests
});

axios.defaults.baseURL = AppConfig.getApiHost();
axios.defaults.responseType = "json";
axios.defaults.headers.common["Access-Control-Allow-Credential"] = true;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Requester-App"] = "workspace";
axios.defaults.headers.common["Workspace-Uuid"] = wsWorkspaceUuid;
axios.defaults.withCredentials = true;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// eslint-disable-next-line no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(axiosMiddleware(client), subscriptionErrorsMiddleware),
    ),
  );

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./redux/reducers", () =>
      store.replaceReducer(rootReducer),
    );
  }

  return store;
}

const store = configureStore();

class AppContainer extends React.Component {
  render() {
    return (
      <React.StrictMode>
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </Provider>
      </React.StrictMode>
    );
  }
}

export default hot(AppContainer);
