import React, { RefObject, useRef } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { IMember } from "src/app/project/ProjectTypes";
import { TaskType } from "src/types";
import { Comment, CommentType } from "src/redux/comment/types";
import RegularComment from "./components/RegularComment";
import ContentProposalElementModerationComment from "./components/ContentProposalElementModerationComment";
import ContentProposalElementComment from "./components/ContentProposalElementComment";
import { CommentDisplayType } from "../Task";
import EmptyContentProposalList from "./components/EmptyContentProposalList";

import "./Comments.scss";

interface CommentsProps {
  comments: Comment[];
  membersList: IMember[];
  taskType: TaskType;
  commentDisplayType: CommentDisplayType;
  setShowAddContentProposalField: () => void;
  hasAccessToCreateContentProposal: boolean;
  setShowAllComments: (visible: boolean) => void;
  showAllComments: boolean;
  taskContainerRef: RefObject<HTMLDivElement>;
  hasAccessToRemoveWsTaskComment: boolean;
}

const COMMENTS_TO_SHOW_AT_FIRST = 5;

export default function Comments({
  comments = [],
  membersList = [],
  taskType,
  commentDisplayType,
  setShowAddContentProposalField,
  hasAccessToCreateContentProposal,
  showAllComments,
  setShowAllComments,
  taskContainerRef,
  hasAccessToRemoveWsTaskComment,
}: CommentsProps) {
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const commentsLength = comments.length;

  const handleScrollToComment = (scrollToUuid: string | null) => {
    if (!scrollToUuid) return;
    setShowAllComments(true);
    setTimeout(() => {
      const foundComment = document.querySelector<HTMLElement>(
        `[data-comment-id="${scrollToUuid}"]`,
      );
      if (foundComment) {
        // eslint-disable-next-line react-compiler/react-compiler
        taskContainerRef.current!.scrollTop = foundComment.offsetTop - 78;
      }
    }, 100);
  };

  const getComponentBasedOnCommentType = (comment: Comment) => {
    const commentAuthor = membersList.find(
      (member) => member.id === comment.author,
    );
    switch (comment.type) {
      case CommentType.Comment:
        return (
          <RegularComment
            comment={comment}
            membersList={membersList}
            commentAuthor={commentAuthor}
            taskType={taskType}
            key={comment.commentId}
            handleScrollToComment={() =>
              handleScrollToComment(comment.replyWsTaskCommentUuid)
            }
            hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
          />
        );
      case CommentType.ContentProposal:
        return (
          <ContentProposalElementComment
            comment={comment}
            membersList={membersList}
            commentAuthor={commentAuthor}
            taskType={taskType}
            key={comment.uuid}
            hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
          />
        );
      case CommentType.ContentProposalElementModeration:
        return (
          <ContentProposalElementModerationComment
            comment={comment}
            commentAuthor={commentAuthor}
            membersList={membersList}
            key={comment.uuid}
            handleScrollToComment={() =>
              handleScrollToComment(comment.replyWsTaskCommentUuid)
            }
            hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="comments-container" ref={commentsContainerRef}>
      {commentsLength === 0 &&
        commentDisplayType === CommentDisplayType.ContentProposal && (
          <EmptyContentProposalList
            onButtonClick={setShowAddContentProposalField}
            hasAccessToCreateContentProposal={hasAccessToCreateContentProposal}
          />
        )}

      {commentsLength > COMMENTS_TO_SHOW_AT_FIRST &&
        (showAllComments ? (
          <div
            className="more-comments"
            onClick={() => setShowAllComments(false)}
          >
            <IDHFormattedMessage
              id="ws_hide_comments"
              defaultMessage="Hide comments"
            />
          </div>
        ) : (
          <div
            className="more-comments"
            onClick={() => setShowAllComments(true)}
          >
            {commentsLength - COMMENTS_TO_SHOW_AT_FIRST}
            &nbsp;
            <IDHFormattedMessage
              id="ws_more_comments"
              defaultMessage="more comments"
            />
          </div>
        ))}
      {membersList.length > 0 &&
        comments.map((comment, index) => {
          if (
            showAllComments ||
            index >= commentsLength - COMMENTS_TO_SHOW_AT_FIRST
          ) {
            return getComponentBasedOnCommentType(comment);
          }
          return null;
        })}
    </div>
  );
}
