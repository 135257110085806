import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setManageSubscriptionModalVisibility,
  setPlanUpgradeNeededModalContext,
  setSelectedSubscriptionPlanData,
  setSubscriptionContactSupportModalVisibility,
  setSubscriptionUsageModalMode,
  setUnlockAddonsModalVisibility,
} from "src/redux/subscription/subscriptionActions";
import { RootState } from "src/redux/reducers";
import SuccessModal from "src/app/modals/SuccessModal/SuccessModal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ManageSubscriptionModal from "../../modals/ManageSubscriptionModal/ManageSubscriptionModal";
import ChangePlanModal from "../../modals/ChangePlanModal/ChangePlanModal";
import PlanUpgradeNeededModal from "../../modals/PlanUpgradeNeededModal/PlanUpgradeNeededModal";
import SubscriptionUsageModal from "../../modals/SubscriptionUsageModal/SubscriptionUsageModal";
import { SubscriptionContactSupportModal } from "../../modals/SubscriptionContactSupportModal/SubscriptionContactSupportModal";
import UnlockAddonsModal from "../../modals/UnlockAddonsModal/UnlockAddonsModal";

function SubscriptionModals() {
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const {
    subscriptionContactSupportModalVisibility,
    subscriptionUsageModalMode,
    manageSubscriptionModalVisible,
    selectedSubscriptionPlanData,
    planUpgradeNeededModalContext,
    subscriptionPlan,
    unlockAddonsModalVisibility,
  } = useSelector((state: RootState) => state.subscriptionReducer);

  const dispatch = useDispatch();

  return (
    <>
      {subscriptionContactSupportModalVisibility && (
        <SubscriptionContactSupportModal
          onClose={() =>
            dispatch(setSubscriptionContactSupportModalVisibility(false))
          }
        />
      )}

      {subscriptionUsageModalMode && (
        <SubscriptionUsageModal
          onClose={() => dispatch(setSubscriptionUsageModalMode(null))}
          mode={subscriptionUsageModalMode}
        />
      )}

      {planUpgradeNeededModalContext && (
        <PlanUpgradeNeededModal
          onClose={() => dispatch(setPlanUpgradeNeededModalContext(null))}
        />
      )}

      {manageSubscriptionModalVisible && (
        <ManageSubscriptionModal
          onClose={() => dispatch(setManageSubscriptionModalVisibility(false))}
        />
      )}

      {selectedSubscriptionPlanData && (
        <ChangePlanModal
          onClose={() => dispatch(setSelectedSubscriptionPlanData(null))}
          planChangeData={selectedSubscriptionPlanData}
          openSuccessModal={() => setSuccessModalVisible(true)}
        />
      )}

      {unlockAddonsModalVisibility && (
        <UnlockAddonsModal
          onClose={() => dispatch(setUnlockAddonsModalVisibility(false))}
          openSuccessModal={() => setSuccessModalVisible(true)}
        />
      )}

      {successModalVisible && (
        <SuccessModal onClose={() => setSuccessModalVisible(false)}>
          <IDHFormattedMessage
            id="ws_you_have_successfully_changed_plan_to"
            defaultMessage="You've successfully changed plan to:"
          />{" "}
          {subscriptionPlan?.displayName}.
        </SuccessModal>
      )}
    </>
  );
}

export default SubscriptionModals;
