import { SubscriptionPlanChangeData } from "src/app/subscription/modals/ManageSubscriptionModal/ManageSubscriptionModal";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { SubscriptionUsageModalMode } from "src/app/subscription/modals/SubscriptionUsageModal/SubscriptionUsageModal";
import { BillingPeriod, StiggContextValue } from "@stigg/react-sdk";
import { subscriptionTypes } from "./subscriptionTypes";

export interface SubscriptionPlan {
  id: string;
  displayName: string;
  billingPeriod: BillingPeriod | undefined;
  features: {
    [key: string]: {
      featureId: string;
      units?: string | null | undefined;
      unitsPlural?: string | null | undefined;
      unitQuantity?: number | null;
      displayName: string;
    };
  };
}

export interface SubscriptionReducerState {
  stiggContextValue: StiggContextValue | null;
  subscriptionUsageModalMode: SubscriptionUsageModalMode | null;
  manageSubscriptionModalVisible: boolean;
  selectedSubscriptionPlanData: SubscriptionPlanChangeData | null;
  planUpgradeNeededModalContext: SubscriptionEntitlements | null;
  subscriptionContactSupportModalVisibility: boolean;
  subscriptionPlan: SubscriptionPlan | null;
  unlockAddonsModalVisibility: boolean;
}

const initialState: SubscriptionReducerState = {
  stiggContextValue: null,
  subscriptionUsageModalMode: null,
  manageSubscriptionModalVisible: false,
  selectedSubscriptionPlanData: null,
  planUpgradeNeededModalContext: null,
  subscriptionContactSupportModalVisibility: false,
  subscriptionPlan: null,
  unlockAddonsModalVisibility: false,
};

interface SubscriptionReducerAction {
  type: keyof typeof subscriptionTypes;
  payload: any;
}

function subscriptionReducer(
  state = initialState,
  action: SubscriptionReducerAction,
) {
  switch (action.type) {
    case "SET_STIGG_CONTEXT_VALUE":
      return {
        ...state,
        stiggContextValue: action.payload.stiggContextValue,
      };

    case "SET_SUBSCRIPTION_MODAL_MODE":
      return {
        ...state,
        subscriptionUsageModalMode: action.payload.subscriptionUsageModalMode,
      };

    case "SET_MANAGE_SUBSCRIPTION_MODAL_VISIBILITY":
      return {
        ...state,
        manageSubscriptionModalVisible:
          action.payload.manageSubscriptionModalVisible,
      };

    case "SET_SELECTED_SUBSCRIPTION_PLAN_DATA":
      return {
        ...state,
        selectedSubscriptionPlanData:
          action.payload.selectedSubscriptionPlanData,
      };

    case "SET_PLAN_UPGRADE_NEEDED_MODAL_CONTEXT":
      return {
        ...state,
        planUpgradeNeededModalContext:
          action.payload.planUpgradeNeededModalContext,
      };

    case "SET_SUBSCRIPTION_PLAN":
      return {
        ...state,
        subscriptionPlan: action.payload.subscriptionPlan,
      };

    case "SET_SUBSCRIPTION_CONTACT_SUPPORT_MODAL_VISIBILITY":
      return {
        ...state,
        subscriptionContactSupportModalVisibility:
          action.payload.subscriptionContactSupportModalVisibility,
      };

    case "SET_UNLOCK_ADDONS_MODAL_VISIBILITY":
      return {
        ...state,
        unlockAddonsModalVisibility: action.payload.unlockAddonsModalVisibility,
      };

    default:
      return state;
  }
}

export default subscriptionReducer;
