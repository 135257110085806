import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import "./CouponDataImportModal.scss";
import { useIntl } from "react-intl";
import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import { useDispatch, useSelector } from "react-redux";
import { setShouldCloseModal } from "src/redux/coupon/couponActions";
import { ReactComponent as ImportIcon } from "src/images/download-rounded.svg";
import { ReactComponent as WarningIcon } from "src/images/info-warning.svg";
import { ReactComponent as SuccessIcon } from "src/images/tick-circle-green.svg";
import axios from "axios";
import classNames from "classnames";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { SelectOption } from "../../../modals/ManageFieldModal/SocialMetrics";
import { CustomInputLabel } from "../../../components/CustomInput/CustomInput";
import { API_URLS } from "../../../../utils/API_URLS";
import { showToast } from "../../../methods/showToast";
import Loader from "../../../components/Loader/Loader";

interface Props {
  onClose: () => void;
}

export const CouponDataImportModal: React.FC<Props> = ({ onClose }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [importData, setImportData] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [currentProcessState, setCurrentProcessState] = useState<
    "loading" | "invalid_file" | "import_error" | "validation_success" | null
  >(null);
  const [importDataValid, setImportDataValid] = useState<boolean>(false); // used to determine whether we should show "Validate" or "Import" button
  const [importDataLogImportStrategyUuid, setImportDataLogImportStrategyUuid] =
    useState<SelectOption | null>(null);

  const {
    couponReducer: { shouldCloseModal, couponRedemptionLogImportStrategies },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleFileSelect = () => {
    fileInputRef?.current?.click();
  };

  const importStrategyOptions = couponRedemptionLogImportStrategies?.map(
    (item: { name: string; uuid: string }) => ({
      label: (
        <div className="language-selector__item">
          <span className="language-selector__item-original">{item.name}</span>
        </div>
      ),
      value: item.uuid,
    }),
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setCurrentProcessState("loading");
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(API_URLS.convertFileToString, formData)
        .then((response) => {
          setImportData(response.data.content.importData);
          setSelectedFile(file);
          setCurrentProcessState(null);
        })
        .catch((error) => {
          console.error(error);
          setCurrentProcessState("invalid_file");
          showErrorToast();
        });
    }
  };

  const handleFileInputClick = (event: any) => {
    setCurrentProcessState(null);
    setImportDataValid(false);
    event.target.value = null; // Allow to upload the exact same file again
  };

  const handleProcessDataImport = async () => {
    if (importDataValid) {
      const url = API_URLS.processCouponDataImport.replace(
        ":wsCouponRedemptionLogImportStrategyUuid:",
        importDataLogImportStrategyUuid?.value,
      );
      setCurrentProcessState("loading");
      axios
        .post(url, {
          importData,
          wsWorkspaceUuid: activeWorkspaceUuid,
        })
        .then((response) => {
          setCurrentProcessState(null);
          showToast(
            "success",
            <IDHFormattedMessage id="ws_success" defaultMessage="Success!" />,
            <>
              <IDHFormattedMessage
                id="ws_coupon_row_affected"
                defaultMessage="Rows affected:"
              />
              &nbsp;
              {response.data?.content?.rowAffected ?? 0}
              , &nbsp;
              <IDHFormattedMessage
                id="ws_coupon_row_skipped"
                defaultMessage="Rows skipped:"
              />
              &nbsp;
              {response.data?.content?.rowSkipped ?? 0}
            </>,
          );
          onClose();
        })
        .catch((error) => {
          console.error(error);
          setCurrentProcessState("import_error");
          showErrorToast();
        });
    } else {
      const url = API_URLS.validateCouponDataImportFile.replace(
        ":wsCouponRedemptionLogImportStrategyUuid:",
        importDataLogImportStrategyUuid?.value,
      );
      setCurrentProcessState("loading");
      axios
        .post(url, {
          importData,
          wsWorkspaceUuid: activeWorkspaceUuid,
        })
        .then(() => {
          setImportDataValid(true);
          setCurrentProcessState("validation_success");
        })
        .catch((error) => {
          console.error(error);
          setImportDataValid(false);
          setCurrentProcessState("invalid_file");
          showErrorToast();
        });
    }
  };

  useEffect(() => {
    if (shouldCloseModal) {
      dispatch(setShouldCloseModal(false));
      onClose();
    }
  }, [shouldCloseModal]);

  return (
    <Modal
      className="coupon-data-import-modal"
      onClose={onClose}
      onConfirmClick={handleProcessDataImport}
      confirmButtonText={
        importDataValid ? (
          <IDHFormattedMessage id="ws_import" defaultMessage="Import" />
        ) : (
          <IDHFormattedMessage id="ws_validate" defaultMessage="Validate" />
        )
      }
      displayCancelButton
      confirmButtonDisabled={
        importData?.length < 10 || importDataLogImportStrategyUuid === null
      }
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_coupon_data_import"
          defaultMessage="Data import"
        />
      </ModalTitle>

      <div className="coupon-data-import-modal__input-wrapper">
        <span className="coupon-data-import-modal__header-label">
          <IDHFormattedMessage
            id="ws_coupon_upload_coupon_label"
            defaultMessage="Upload the data file or paste the data manually."
          />
        </span>

        <div
          className="coupon-data-import-modal__upload-file-wrapper"
          onClick={handleFileSelect}
        >
          <ImportIcon className="file-icon" />
          <span className="file-label">
            <IDHFormattedMessage
              id="ws_coupon_upload_file"
              defaultMessage="Upload file"
            />
          </span>
          <span className="file-tooltip-label">
            {selectedFile ? (
              selectedFile?.name
            ) : (
              <IDHFormattedMessage
                id="ws_coupon_upload_file_formats"
                defaultMessage="Allowed formats: XLSX, XLS"
              />
            )}
          </span>
          <input
            type="file"
            ref={fileInputRef}
            accept=".xlsx,.xls"
            className="input-file"
            onChange={handleFileChange}
            onClick={handleFileInputClick}
          />
        </div>
        <div className="coupon-data-import-modal__textarea-wrapper">
          <textarea
            className={classNames("coupon-data-import-modal__textarea")}
            style={{
              // todo: remove inline CSS. I did it to avoid using CustomTextarea (in order to set word wrap properly)
              width: "100%",
              borderRadius: "8px",
              border: "1px solid #a7abc3",
              color: "#8e93ac",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 500,
              padding: "8px 10px",
              whiteSpace: "pre",
              overflowWrap: "normal",
              overflow: "auto",
            }}
            id="importData"
            placeholder={translateMessage({
              intl,
              id: "ws_coupon_enter_data_import",
              defaultMessage: "...or paste the spreadsheet content here",
            })}
            value={importData}
            onChange={(e) => {
              setCurrentProcessState(null);
              setImportDataValid(false);
              setImportData(e.target.value);
            }}
          />

          {currentProcessState === "invalid_file" ? (
            <span className="invalid-data-label">
              <WarningIcon />
              <IDHFormattedMessage
                id="ws_coupon_invalid_file"
                defaultMessage="Uploaded file is not valid. Please upload a spreadsheet with only one tab"
              />
            </span>
          ) : (
            <></>
          )}

          {currentProcessState === "import_error" ? (
            <span className="invalid-data-label">
              <WarningIcon />
              <IDHFormattedMessage
                id="ws_coupon_import_error"
                defaultMessage="Data could not be imported"
              />
            </span>
          ) : (
            <></>
          )}

          {currentProcessState === "loading" ? (
            <span className="import-data-loader">
              <Loader />
              <IDHFormattedMessage
                id="ws_coupon_loading"
                defaultMessage="Loading"
              />
            </span>
          ) : (
            <></>
          )}

          {currentProcessState === "validation_success" ? (
            <span className="success-data-label">
              <SuccessIcon />
              <IDHFormattedMessage
                id="ws_coupon_validation_success"
                defaultMessage="Validation success"
              />
            </span>
          ) : (
            <></>
          )}
        </div>
        <CustomInputLabel htmlFor="dataImport">
          <IDHFormattedMessage
            id="ws_coupon_redemptions_import_template"
            defaultMessage="Coupon redemptions import template"
            className="coupon-data-import-modal__templates-label"
          />
        </CustomInputLabel>
        <CustomSelect
          id="dataImport"
          value={importDataLogImportStrategyUuid}
          options={importStrategyOptions}
          placeholder={translateMessage({
            intl,
            id: "ws_coupon_import_redemptions_choose_strategy",
            defaultMessage: "Choose the template",
          })}
          onChange={(newValue: SelectOption) => {
            setImportDataValid(false);
            setCurrentProcessState(null);
            setImportDataLogImportStrategyUuid(newValue);
          }}
        />
      </div>
    </Modal>
  );
};
