import React from "react";
import "./Billing.scss";
import { CustomerPortal } from "@stigg/react-sdk";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ModalScroller } from "src/app/components/Modal/Modal";
import SubscriptionStylingProvider from "src/app/subscription/components/SubscriptionStylingProvider/SubscriptionStylingProvider";

function Billing() {
  return (
    <AnimatedDiv className="billing">
      <ModalScroller maxHeight="100%">
        <SubscriptionStylingProvider
          sectionStylingModes={{ paymentDetails: "indahash" }}
        >
          <CustomerPortal
            hiddenSections={["usage", "addons", "promotionalEntitlements"]}
          />
        </SubscriptionStylingProvider>
      </ModalScroller>
    </AnimatedDiv>
  );
}

export default Billing;
