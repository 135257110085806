import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./AccessLinkMetaField.scss";

import { ReactComponent as CopiedLinkIcon } from "src/images/copied-link.svg";
import { ReactComponent as CopyLinkIcon } from "src/images/copy-link.svg";
import { ReactComponent as DotsIcon } from "src/images/settings.svg";

import { copyToClipboard } from "src/app/methods/copyToClipboard";
import { API_URLS } from "src/utils/API_URLS";
import axios from "axios";

import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SkeletonText } from "src/app/components/Skeleton/Skeleton";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import DropdownPortal from "src/app/components/DropdownPortal";
import { useScrollLock } from "src/app/methods/useScrollLock";
import { tableDataType } from "src/app/components/Table/Table";
import responseCodes from "src/utils/responseCodes";
import { handle402Errors } from "src/redux/main/functions";
import { useDispatch } from "react-redux";
import { showErrorToast } from "src/utils/methods";
import { useCheckFeatureAccess } from "src/app/subscription/subscriptionFunctions";
import { SubscriptionEntitlements } from "src/app/subscription/subscriptionUtils";
import { setPlanUpgradeNeededModalContext } from "src/redux";
import { AccessLinkMetaFieldDropdownMenu } from "./components/AccessLinkMetaFieldDropdownMenu";

export function AccessLinkMetaField({
  accessLinkValue,
  uuid,
  objectId,
  dataType,
  readOnly = false,
}) {
  const [copied, setCopied] = useState(false);
  const [newAccessLinkValues, setNewAccessLinkValues] =
    useState(accessLinkValue);
  const [isOpen, setIsOpen] = useState(false);

  const { lockScroll, unlockScroll } = useScrollLock();
  const dispatch = useDispatch();

  const hasFeatureAccess = useCheckFeatureAccess(
    SubscriptionEntitlements.Forms,
  );

  const copyAccessLink = (link) => {
    if (hasFeatureAccess) {
      copyToClipboard(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } else {
      dispatch(
        setPlanUpgradeNeededModalContext(SubscriptionEntitlements.Forms),
      );
    }
  };

  const toggle = () => {
    const regenerateURL =
      dataType === tableDataType.Task
        ? API_URLS.updateTaskMetaFieldAccessLinkValue
        : API_URLS.updateProjectAccessLinkMetaValue;

    const params =
      dataType === tableDataType.Task
        ? {
            taskId: objectId,
            metaFieldId: uuid,
            isEnabled: !newAccessLinkValues?.isEnabled,
          }
        : {
            projectUuid: objectId,
            metaFieldUuid: uuid,
            isEnabled: !newAccessLinkValues?.isEnabled,
            refreshToken: false,
          };

    axios
      .put(regenerateURL, {
        ...params,
      })
      .then((res) => {
        setNewAccessLinkValues(res.data.content.value);
        setIsOpen((prev) => !prev);
      })
      .catch((err) => {
        if (
          err?.response.data.statusCode == responseCodes["402_PAYMENT_REQUIRED"]
        ) {
          handle402Errors(dispatch, err?.response?.data?.errors);
        }
        showErrorToast();
        setIsOpen((prev) => !prev);
      });
  };

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isOpen]);

  const menu = (
    <AccessLinkMetaFieldDropdownMenu
      uuid={uuid}
      objectId={objectId}
      newAccessLinkValues={newAccessLinkValues}
      setNewAccessLinkValues={setNewAccessLinkValues}
      toggle={toggle}
      dataType={dataType}
      hideMenu={() => setIsOpen(false)}
    />
  );

  useEffect(() => {
    if (accessLinkValue?.isEnabled !== newAccessLinkValues?.isEnabled)
      setNewAccessLinkValues(accessLinkValue);
  }, [accessLinkValue]);

  if (!newAccessLinkValues) {
    return <SkeletonText width={60} height={15} />;
  }

  if (!newAccessLinkValues?.isEnabled && readOnly) return null;

  return (
    <div className="access-link-metafield">
      {newAccessLinkValues?.isEnabled ? (
        <>
          <TooltipPortal
            content={
              copied ? (
                <IDHFormattedMessage
                  id="ws_link_copied"
                  defaultMessage="Link copied"
                />
              ) : (
                <IDHFormattedMessage
                  id="ws_copy_link"
                  defaultMessage="Copy link"
                />
              )
            }
          >
            <div
              className={classNames("access-link-metafield__copy", {
                "access-link-metafield__copy--done": copied,
              })}
              onClick={() => copyAccessLink(newAccessLinkValues.accessLink)}
            >
              {copied ? (
                <CopiedLinkIcon className="access-link-metafield__copy-icon" />
              ) : (
                <CopyLinkIcon className="access-link-metafield__copy-icon" />
              )}
            </div>
          </TooltipPortal>
          {!readOnly && (
            <DropdownPortal
              overlay={menu}
              visible={isOpen}
              onVisibleChange={(v) => {
                setIsOpen(v);
              }}
            >
              <div className="access-link-metafield__dropdown">
                <DotsIcon className="access-link-metafield__dropdown-icon" />
              </div>
            </DropdownPortal>
          )}
        </>
      ) : (
        <CustomSwitch
          checked={newAccessLinkValues?.isEnabled}
          onChange={toggle}
        />
      )}
    </div>
  );
}
