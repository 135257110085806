import React from "react";
import "./PlanUpgradeNeededModal.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal from "src/app/components/Modal/Modal";
import { RootState } from "src/redux/reducers";
import { Button } from "src/app/components/Button/Button";
import PlanUpgradeNeededBox from "../../components/PlanUpgradeNeededBox/PlanUpgradeNeededBox";

interface Props {
  onClose: () => void;
}

function PlanUpgradeNeededModal(props: Props) {
  const { onClose } = props;

  const { planUpgradeNeededModalContext } = useSelector(
    (state: RootState) => state.subscriptionReducer,
  );

  const closeModalButton = (
    <Button
      variant="white-with-black-border"
      size="large"
      onClick={onClose}
      data-qa="close-button"
    >
      <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
    </Button>
  );

  return (
    <Modal className="plan-upgrade-needed-modal" onClose={onClose}>
      <PlanUpgradeNeededBox
        feature={planUpgradeNeededModalContext}
        closeModalButton={closeModalButton}
      />
    </Modal>
  );
}

export default PlanUpgradeNeededModal;
